import { io } from "socket.io-client"
import auth from "../auth"
import markersMethods from "./markersMethods"
import parametersMethods from "./parametersMethods"

let socket = null

const methods = {
    "update_markers": (reporte, paramsMethod) => markersMethods.updateMarkers(reporte, paramsMethod),
    "update_parameters": (reporte, paramsMethod) => parametersMethods.updateParameters(reporte, paramsMethod)
}

const socketOptions = {
    reconnectionDelayMax: 10000,
}

const socketSettings = {
    room: null,

    connect(url) {
        if (!socket) {
            socket = io(url, socketOptions)
            socket.on("connect", () => {
                console.info("[connected]")
            })
        }
    },

    connectRoom(eventConection, eventUpdate, devices, functions) {
        const userInfo = auth.getUserInfo()

        if (socket && socket.connected && (eventConection != this.room)) {
            const data = {
                company: userInfo.empresa,
                devices: devices,
                user: { id: userInfo.id, type: userInfo.tipo, name: userInfo.nome },
            }

            socket.emit(eventConection, data)

            socket.on(eventConection, () => {
                console.log("[connected to the room]")
                this.room = eventConection
            })

            socket.on(eventUpdate, (_data) => {
                console.log("[update]")

                if (functions.length > 0) {
                    functions.forEach(item => {
                        if (methods[item.descriptionMethod]) {
                            methods[item.descriptionMethod](_data, item.parameters)
                        }
                    })
                }
            })
        } else {
            console.log("Não há conexão com o socket!")
        }
    },

    disconnectRoom(event, eventConection, eventUpdate) {
        if (socket && socket.connected) {
            console.log("[disconnectRoom]")
            
            socket.emit(event)
            socket.off(eventConection)
            socket.off(eventUpdate)
            this.room = null
        } else {
            console.log("Não há conexão com o socket!")
        }
    },

    disconnect() {
        if (socket && socket.connected) {
            console.log("[disconnected]")
            socket.disconnect()
            socket = null
            this.room = null
        } else {
            console.log("Não há conexão com o socket!")
        }
    }
}

export default socketSettings
