const parametersMethods = {

    updateParameters(_data, { parametros, sendParameters }) {
        const { reporte, validacao } = _data 
        const dispositivoId = validacao.id_dispositivo 

        let updateParameters = parametros.map((parametro) => {
            const usuario_mqtt = parametro.campo.equipamento.usuario_mqtt
            const identificacao = parametro.campo.identificacao
            
            if(usuario_mqtt === dispositivoId) {
                if(identificacao in reporte) {
                    parametro["valor_atual"] = reporte[identificacao]
                }
            }

            return parametro
        })

        sendParameters(updateParameters, _data?.reporte)
    }
}

export default parametersMethods