import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom"
import ConfirmDelete from '../../components/ConfirmDelete'
import ButtonsGrid from '../../components/ButtonsGrid'
import Table from '../../components/Table'
import { Badge } from 'reactstrap'
import helper from '../../utils/helper'
import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import '../../assets/style.css'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class Grid extends Component {

    state = {
        data: [],
        loading: true,
        redirectToEdit: false,
        table: null,
        cooldown: false,
        dataUSer: null,
        modalStatus: false
    }

    getData = (state) => {
        this.setState({ loading: true })
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/usuario/?limit=${state?.lineCount}` + state.filter


        if (state.url) {
            url = state.url
        }

        axios.get(url, config_me).then((res) => {
            this.setState({
                loading: false,
                data: res.data.results,
                count: res.data.count,
                next: res.data.next,
                previous: res.data.previous
            })
        })
    }

    deletar = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        this.state.table?.state.selected.map((value, index) =>
            requestArray.push(axios.delete(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/usuario/' + value + '/', config_me))
        )

        axios.all(requestArray).then(axios.spread((acct, perms) => {
            this.state.table?.fetch()
            this.state.table?.unselect()
        }))
    }

    editar = () => {
        this.setState({
            redirectToEdit: { pathname: "usuario/" + this.state.table?.state.selected[0] + "/editar" }
        })
        history.pushState({}, "", location.href)
        this.state.table?.unselect()
    }

    getTokenUser = async () => {
        this.setState({ cooldown: true })

        const { dataUSer } = this.state
        const config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        const userInfo = auth.getUserInfo()
        const url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/acessar/usuario/${dataUSer.id}/` 
        
        axios.get(url, config_me)
        .then((data) => {
            if(!auth.get("__hidroview__userOrigin")) {
                auth.setUserOrigin({ token: auth.getToken(), userName: userInfo.nome })
            }
            
            axios.get(cfg.base_api_url + cfg.api_v2 + '/me/',  { headers: { 'Authorization': 'Bearer ' + data.data.access_token }})
            .then((res) => {
                res.data['pai'] = res.data.pai
                auth.setUserInfo(res.data, true)
                auth.setToken(data.data.access_token, true, '__hidroview__access_token')
                this.setState({ cooldown: false })

                setTimeout(() => { window.location.reload() }, 1000)
            })
        })
        .catch((error) => {
            helper.dispatchEvent('showAviso', {
                message: error.response.data
            })
            this.setState({ cooldown: false })
        })
    }

    render() {
        const { redirectToEdit, table, cooldown, modalStatus } = this.state
        if (redirectToEdit) {
            return <Redirect to={redirectToEdit} />
        }

        const columns = [{
            Header: 'Nome',
            Accessor: 'nome'
        }, {
            Header: 'E-mail',
            Accessor: 'usuario__username'
        }, {
            Header: 'Tipo',
            Accessor: 'tipo',
            Cell: (original) => {
                let label = "" 
                let color = ""
    
                if(original.tipo === 'admin' || original.tipo === 'administrador') {
                    label = "Administrador"
                    color = "dark"
                } else if (original.tipo === 'cliente') {
                    label = "Cliente"
                    color = "warning"
                } else if(original.tipo === 'normal') {
                    label = "Comum"
                    color = "secondary"
                } else if(original.tipo === 'master') {
                    label = "Master"
                    color = "primary"
                }

                return <Badge href="#" color={color}>{label}</Badge>
            }
        }, {
            Header: 'Status',
            Accessor: 'status',
            Cell: (original) => {
                let status = original.status 
                let color = status === "ativo" ? "success" : status === "cancelado" ? "secondary" : "danger"
                
                return <Badge href="#" color={color}>{status}</Badge>
            }
        },
        {
            Header: 'Criado em',
            Accessor: 'data_criado',
            Cell: (original) => {
                return <span>{helper.convertDate(original.data_criado)}</span>
            }
        },
        {
            Header: 'Atualizado em',
            Accessor: 'data_atualizado',
            Cell: (original) => {
                return <span>{helper.convertDate(original.data_atualizado)}</span>
            }
        },
        {
            Header: "Acessar conta",
            title: "Acessar",
            Cell: (original) => {
                if(original.acessar) {
                    return (
                        <div
                            className='acessar-button'
                            onClick={() => this.setState({ modalStatus: true, dataUSer: original })}
                        >
                            <i className="las la-sign-in-alt la-2x"></i>
                        </div>
                    )
                }
            }
        }]

        return (
            <div>
                <Modal isOpen={modalStatus} toggle={() => this.setState({ modalStatus: false, dataUSer: null })}>
                    <ModalHeader>
                        Aviso
                    </ModalHeader>
                    <ModalBody>
                        Tem certeza que deseja acessar a conta do usuário selecionado ? 
                    </ModalBody>
                    <ModalFooter>
                        <div className='hdv-btn-group'>
                            <button disabled={cooldown} className="hdv-btn-forms hdv-btn-green" onClick={this.getTokenUser}>Acessar</button>
                            <button disabled={cooldown} className="hdv-btn-forms hdv-btn-yellow" onClick={() => this.setState({ modalStatus: false, dataUSer: null})}>Cancelar</button>
                        </div>
                    </ModalFooter>
                </Modal>

                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Cadastro de Usuários</h4>
                        <div className="gray-background">
                            <i className="fa fa-users fa-2x"></i>
                        </div>
                    </span>
                </div>

                <div className="hdv-btn-group">
                    <ButtonsGrid 
                        route="/usuario"
                        buttons={[
                            {
                                method: "post",
                                button: <Link to="/usuario/novo"><button className="hdv-btn-forms hdv-btn-green">Novo</button></Link>
                            },

                            {
                                method: "patch",
                                button: <button onClick={() => { if (table?.state.enableEditar) this.editar() }} className={"hdv-btn-forms hdv-btn-yellow " + (table?.state.enableEditar ? "" : "hdv-btn-disable")}>Editar</button>
                            },

                            {
                                method: "delete",
                                button: <ConfirmDelete enabled={table?.state.enableDeletar} action={this.deletar} />
                            }
                        ]}
                    />
                </div>

                <Table
                    checkbox
                    data={this.state.data}
                    loading={this.state.loading}
                    count={this.state.count}
                    next={this.state.next}
                    previous={this.state.previous}
                    columns={columns}
                    onFetchData={this.getData}
                    onUpdate={(table) => this.setState({ table })}
                />

            </div>
        )
    }
}

export default Grid
