import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ButtonsForm from '../../components/ButtonsForm'
import { Redirect, Link } from 'react-router-dom'
import Select from '../../components/Select'
import helper from '../../utils/helper'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'

class Form extends Component {
    state = {
        detalhes: { "nome": "" },
        formerrors: [],
        redirect: false,
        path: "",
        permissoes: [],
        open_group_block: {},
        selectedPermission: [],
        monitorados: [],
        cooldown: false,
        validacao: { data: true, agrupar: true, descricao: true },
        options: { monitorados: [], clientes: [] },
        addMonitorado: {
            modal: false,
            selecionados: [],
            filtros: { cliente: null, monitorado: null },
            isSelecionado: false,
        },
    }

    componentWillMount = () => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/permissoes/', config_me).then((res) => {
            let open_close_group_block = {}

            let permissoes = res.data?.results?.map(permissao => {
                return new Object({
                    id: permissao.id,
                    grupo: permissao.nome,
                    permissoes: permissao.permissoes
                })
            })

            permissoes?.forEach(grupo => {
                open_close_group_block[grupo.grupo.toLowerCase()] = true
            })

            this.setState({ "permissoes": permissoes, open_group_block: open_close_group_block })
        })
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getClientes = () => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/', config_me).then(res => {
            let { options } = this.state
            let clientes = []
            clientes.push({ label: 'Nenhum', value: null })

            if (res.data?.results?.length > 0) {
                res.data.results.forEach((c) => {
                    clientes.push({ "value": c.id, "label": c.nome_fantasia })
                })

                if (clientes.length == 2) {
                    this.handleChangeCliente(clientes[1])
                }
            }

            options["clientes"] = clientes

            this.setState({ options })
        })
    }

    getData = () => {
        let userInfo = auth.getUserInfo()
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/usuario/perfil/' + this.props.match.params.id + "/"
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(requestURL, config_me).then((res) => {
            let permissoes = res.data.permissoes
            let selectedPermission = []
            let monitorados = []

            delete res.data.id
            delete res.data.permissoes
            delete res.data.equipamentos
            permissoes.forEach((p) => selectedPermission.push(p.id))

            res.data?.monitorados?.map(monitorado => monitorados.push({ id: monitorado.id, label: monitorado.nome }))

            this.setState({ detalhes: res.data, selectedPermission, monitorados })
        })
    }

    handleChangeMonitorado = (selectedOption) => {
        let { addMonitorado } = this.state

        addMonitorado['filtros']['monitorado'] = selectedOption
        let monitoradoIsSelecionado = addMonitorado.selecionados?.filter(monitorado => monitorado.id === selectedOption.value)

        monitoradoIsSelecionado.length != 0 ? addMonitorado['isSelecionado'] = true : addMonitorado['isSelecionado'] = false

        this.setState({ addMonitorado })
    }

    handleChangeCliente = (selectedOption) => {
        let { addMonitorado } = this.state
        addMonitorado['filtros']['cliente'] = selectedOption
        addMonitorado['filtros']['monitorado'] = null

        if (selectedOption.value !== null) {
            let userInfo = auth.getUserInfo()
            const bodyFormData = new FormData()
            const filterData = new FormData()

            bodyFormData.append('cliente_id', selectedOption.value)
            filterData.append('item__monitorado__cliente_id', selectedOption.value)

            axios({
                method: 'POST',
                url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/filtro/',
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            }).then((res) => {
                let { options } = this.state
                let optionsMonitorado = []

                optionsMonitorado.push({"value": 0, "label": "Selecionar todos"})
                res.data.forEach((c) => optionsMonitorado.push({ "value": c.id, "label": c.nome }))

                options["monitorados"] = optionsMonitorado
                this.setState({ options })
            }).catch(console.error)

            this.handleChangeMonitorado(null)
        } else {
            let { addMonitorado } = this.state
            addMonitorado['filtrados'] = []
            this.setState({ addMonitorado })
        }
    }

    handleChange = (e) => {
        let obj = this.state.detalhes

        if (e.target.type === "checkbox") {
            obj[e.target.id] = (e.target.checked === true) ? true : false
        } else {
            obj[e.target.id] = e.target.value
        }

        document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
        this.setState({ "detalhes": obj })
    }

    saveAction = async (novo_cadastro) => {
        this.setState({ cooldown: true })
        let userInfo = auth.getUserInfo()
        let requestURL = ''
        let verb = ''

        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/usuario/perfil/' + this.props.match.params.id + '/'
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/usuario/perfil/'
            verb = 'post'
        }

        if (this.state.detalhes?.nome?.includes("- Administrador")) {
            document.querySelector(`#validacao-nome`).innerHTML = "Nome de perfil inválido!"
            window.scrollTo(0, 0)
        }

        let bodyFormData = new FormData()
        for (let key in this.state.detalhes) {
            bodyFormData.append(key, this.state.detalhes[key])
        }

        bodyFormData.append('permissoes', this.state.selectedPermission)
        bodyFormData.delete('equipamentos')

        if (this.state.monitorados.length > 0) {
            const monitorados = this.state.monitorados?.map(m => m.id)
            bodyFormData.set('monitorados', monitorados.join(","))
        } else {
            bodyFormData.delete('monitorados')
        }

        await axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => {
            if (novo_cadastro === true) {
                window.location.reload()
            } else {
                helper.dispatchEvent("showAviso", {
                    message: `Perfil ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`,
                    callback: () => this.setState({ redirect: true, path: "/perfil/usuario" }),
                    callbackClose: verb === 'post' ? () => {
                        window.location.href = `/perfil/usuario/${res.data.id}/editar`
                        setTimeout(() => window.location.reload(), 1000)
                    } : null
                })
            }
        }).catch((error) => {
            let erros = Object.entries(error.response.data)
            if (Array.isArray(erros)) {
                for (let i = 0; i < erros.length; i++) {
                    let span = document.querySelector(`#validacao-${erros[i][0]}`)
                    if (span) {
                        span.innerHTML = erros[i][1]
                        window.scrollTo({ top: 50, behavior: 'smooth' })
                    }
                }
            }
        })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    changePermissionOption = (id) => {
        let selectedPermission = this.state.selectedPermission
        if (selectedPermission.includes(id)) {
            selectedPermission = selectedPermission.filter(item => item !== id)
        } else {
            selectedPermission.push(id)
        }
        
        this.setState({ selectedPermission: selectedPermission })
    }

    changePermissionGroup = (event, group) => {
        let selectedPermission = this.state.selectedPermission
        if (event.target.checked) {
            this.state.permissoes[group].permissoes.forEach((permissao) => {
                selectedPermission.push(permissao.id)
            })
        } else {
            this.state.permissoes[group].permissoes.forEach((permissao) => {
                selectedPermission = selectedPermission.filter(item => item !== permissao.id)
            })
        }
        this.setState({ selectedPermission: selectedPermission })
    }

    toggleModal = () => {
        let { addMonitorado } = this.state
        addMonitorado['modal'] = !addMonitorado['modal']
        this.setState({ addMonitorado })
    }

    deletar = (index) => {
        let { addMonitorado } = this.state

        addMonitorado['selecionados'].splice(index, 1)
        this.setState({ addMonitorado })
    }

    removerTodos = () => {
        let {addMonitorado, monitorados} = this.state
        addMonitorado['selecionados'] = []
        monitorados = []
        this.setState({ addMonitorado, monitorados })
    }

    adicionar = () => {
        let { addMonitorado, monitorados } = this.state
        let filter = addMonitorado.selecionados?.filter(m => m.id === addMonitorado.filtros.monitorado?.value)
        let filtros = addMonitorado.filtros
        let monitoradosFiltradosDoSelect = this.state.options.monitorados.filter((item) => item.value !== 0)

        if (addMonitorado.filtros.monitorado == null) return

        if (addMonitorado.filtros.monitorado.value === 0) {
            monitoradosFiltradosDoSelect.forEach((item) => {
                let monitoradoExisteFiltro = addMonitorado.selecionados?.filter(monitorado => monitorado.id === item.value)

                if(monitoradoExisteFiltro.length === 0) {
                    addMonitorado['selecionados']?.push({
                        id: item.value,
                        label: item.label
                    })
                }
            })
            
            if (!auth.isClient()) {
                filtros['cliente'] = null
            }

            filtros['monitorado'] = null
            monitorados = addMonitorado['selecionados']
            addMonitorado['filtros'] = filtros

            this.setState({ addMonitorado, monitorados })
        } else if (filter?.length === 0) {
            addMonitorado['selecionados']?.push({
                id: addMonitorado.filtros.monitorado?.value,
                label: addMonitorado.filtros.monitorado?.label
            })

            if (!auth.isClient()) {
                filtros['cliente'] = null
            }

            filtros['monitorado'] = null
            monitorados = addMonitorado['selecionados']
            addMonitorado['filtros'] = filtros

            this.setState({ addMonitorado, monitorados })
        }
    }

    fechar = () => {
        let { addMonitorado, monitorados } = this.state

        monitorados = addMonitorado['selecionados']
        addMonitorado['selecionados'] = []
        addMonitorado['modal'] = false
        addMonitorado['filtros'] = { cliente: null, monitorado: null }

        this.setState({ addMonitorado, monitorados })
    }

    gerenciar = (e) => {
        e.preventDefault()
        
        let { addMonitorado, monitorados } = this.state
        const isClient = auth.isClient()
        const dataClient = auth.getUserInfo()

        addMonitorado['selecionados'] = monitorados

        this.setState({ addMonitorado })
        this.toggleModal()

        if(isClient) {
            this.handleChangeCliente({ label: dataClient.nome, value: dataClient.cliente })
        } else {
            this.getClientes()
        }
       
    }

    render() {
        const { addMonitorado } = this.state

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>
                <Modal isOpen={addMonitorado.modal} toggle={this.toggleModal} size="md">
                    <ModalHeader toggle={this.toggleModal}>
                        Gerenciar Monitorados
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-4">
                                <label>Cliente</label>
                                <Select
                                    value={addMonitorado.filtros.cliente}
                                    disabled={auth.isClient()}
                                    onChange={(e) => this.handleChangeCliente(e)}
                                    options={this.state.options.clientes}
                                />
                            </div>
                            <div className="col-md-4">
                                <label>Monitorado</label>
                                <Select
                                    value={addMonitorado.filtros.monitorado}
                                    onChange={(e) => this.handleChangeMonitorado(e)}
                                    options={this.state.options.monitorados}
                                />
                            </div>
                            <div className="col-md-4">
                                <label>&nbsp;</label><br />
                                <button className="hdv-btn-forms hdv-btn-blue w-100" onClick={this.adicionar} style={{ height: "42px" }} disabled={addMonitorado.isSelecionado}>
                                    {addMonitorado.isSelecionado ? 'Monitorado já selecionado' : 'Adicionar'}
                                </button>
                            </div>
                            <div className="col-md-12 mt-2"> 
                                <label>Selecionados</label>
                                <ul id="multi-item-list" style={{ height: '200px' }}>
                                    <button key="button" onClick={this.removerTodos}>Remover todos</button>
                                    {this.state.addMonitorado.selecionados.map((monitorado, index) => (
                                        <label className="d-flex" style={{ margin: '0' }}>
                                            <span>
                                                {monitorado.label}
                                            </span>
                                            <button onClick={() => this.deletar(index)} className="hdv-automacao-delete-button">
                                                <i className="fa fa-times fa-2x"></i>
                                            </button>
                                        </label>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={this.fechar}>Fechar</Button>
                    </ModalFooter>
                </Modal>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        {this.props.match.params.id ? <h4>Editar Perfil</h4> : <h4>Novo Perfil</h4>}
                        <div className="gray-background">
                            <i className="fa fa-id-card fa-2x"></i>
                        </div>
                    </span>
                </div>

                <form className="hdv-form-default">
                    <label htmlFor="nome">Nome do Perfil</label>
                    <input type="text" name="nome" id="nome" value={this.state.detalhes.nome} onChange={this.handleChange} />
                    <span id="validacao-nome"></span>
                    <label htmlFor="">Selecionar Recursos</label>
                </form>

                <div className="row">
                    <div className="col-md-7">
                        {this.state.permissoes.map((v, i) => {
                            let permissionsId = v.permissoes?.map(p => p.id)
                            let allSelected = permissionsId?.every(id => this.state.selectedPermission.includes(id))


                            return permissionsId?.length > 0 ?
                                <details key={i} className="custom-summary" style={{ marginBottom: '8px' }} open>
                                    <summary>
                                        <label style={{ width: "fit-content", margin: "0" }}>
                                            <input checked={allSelected} onChange={(e) => this.changePermissionGroup(e, i)} type="checkbox" className="mr-2" />
                                            {v.grupo}
                                        </label>
                                    </summary>
                                    <div style={{ padding: '4px 5px', backgroundColor: '#fff' }}>
                                        {v.permissoes.map((p, i) => {
                                            return (
                                                <div key={i} className={(this.state.open_group_block[v.grupo.toLowerCase()]) ? "hdv-permission-block" : "hdv-noshow-item"} >
                                                    <label style={{ margin: 0 }}>
                                                        <input checked={(this.state.selectedPermission.includes(p.id)) ? true : false} onChange={() => this.changePermissionOption(p.id)} type="checkbox" />
                                                        <span className="hdv-permission-description">{p.descricao}</span>
                                                    </label>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </details>
                                :
                                null
                        })
                        }
                    </div>
                    <div className="col-md-5">
                        <div className="hdv-multi-report-dispositivos" id="perfil-usuario">
                            <div className="d-flex align-items-center justify-content-between align-center no-arrow">
                                <strong style={{ fontSize: "17px" }}>Acesso à Monitorados</strong>
                                <button
                                    onClick={this.gerenciar}
                                    className="hdv-btn-forms hdv-btn-blue primary m-0" style={{ background: '#0062cc' }}>
                                    Gerenciar
                                </button>
                            </div>
                            <section className="mt-2">
                                {this.state.monitorados.length > 0
                                    ?
                                    this.state.monitorados?.map((monitorado, index) => (
                                        <div className="hdv-multi-report-item py-2" key={index}>
                                            <p>{monitorado?.label}</p>
                                        </div>
                                    ))
                                    :
                                    <p className="mt-5 text-center">Sem monitorados selecionados</p>
                                }
                            </section>
                        </div>

                    </div>
                </div>

                <div className="hdv-btn-group">
                    <ButtonsForm
                        route="/usuario/perfil"
                        edit={this.props.match.params.edit ? true : false}
                        cooldown={this.state.cooldown}
                        buttons={[
                            {
                                method: "post",
                                button: <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-green">Salvar</button>,
                            },

                            {
                                method: "post",
                                button: <button style={{ display: this.props.match.params.id ? "none" : "inline-block" }} onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-greenlight " disabled={this.state.cooldown}>Salvar e Cadastrar um Novo</button>
                            },

                            {
                                method: "patch",
                                button: <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-green">Salvar</button>,
                            },
                        ]}
                    />
                    <Link to="/perfil/usuario">
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>
            </div>
        )
    }
}

export default Form