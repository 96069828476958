import React, { Component, useMemo, useRef, useState } from 'react'
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet'
import MarkerClusterGroup from "react-leaflet-cluster"
import mapMarker from '../assets/svg/map-marker.svg'
import ReactDOMServer from 'react-dom/server'
import helper from '../utils/helper'
import { divIcon } from 'leaflet'

class Mapa extends Component {
    mapRef = React.createRef()
    mapId = helper.randomKey(12)

    static defaultProps = {
        allowDragging: true,
        allowZoom: true,
        layerControl: false,
    }

    state = {
        position: [-17.408282, -53.117169],
        layerUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        layerType: 1,
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.props.onLoad && this.props.onLoad(this.mapRef)

            if (this.props.layerControl) {
                const div = document.createElement('div')
                div.setAttribute('class', 'map-layer-ctrl')

                div.innerHTML = ReactDOMServer.renderToString(
                    <>
                        <button data-map-layer="1" className="selected">Mapa</button>
                        <button data-map-layer="2">Satélite</button>
                    </>
                )

                const map = document.querySelector(`div[data-map-id="${this.mapId}"] .leaflet-control-container`)
                map.appendChild(div)

                document.querySelectorAll(".leaflet-control-container button").forEach((btn) => {
                    btn?.addEventListener('click', (e) => this.handleLayer(e.target.dataset.mapLayer))
                })
            }
        }, 0)
    }

    handleLayer = (type) => {
        let layerUrl
        type = Number(type)

        if (type == 1) {
            layerUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        } else if (type == 2) {
            layerUrl = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        }

        const button = document.querySelector(`.leaflet-control-container button[data-map-layer="${type}"]`)
        const buttonRemove = document.querySelector(`.leaflet-control-container button[data-map-layer="${type == 1 ? 2 : 1}"]`)

        button.classList.add("selected")
        buttonRemove.classList.remove("selected")

        this.setState({ layerUrl, layerType: type })
    }

    render() {
        const { position, layerUrl } = this.state
        const { clusterEnabled } = this.props

        return (
            <div data-map-id={this.mapId}>
                <MapContainer
                    id="map"
                    center={position}
                    zoom={this.props.zoom ?? 4}
                    zoomControl={this.props.allowZoom}
                    dragging={this.props.allowDragging}
                    scrollWheelZoom={this.props.allowDragging}
                    ref={this.mapRef}
                    style={this.props.style ?? {}}>
                    <TileLayer
                        url={layerUrl}
                        ext="png"
                        attribution='&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                    />

                    {clusterEnabled ?
                        this.props.markers?.map((marker, index) => {
                            if (this.props.draggable) {
                                return (
                                    <DraggableMarker
                                        position={[marker.latitude, marker.longitude]}
                                        onSelect={(pos) => this.props?.onClick(pos)}
                                        markerElement={marker.iconElement}
                                    />
                                )
                            }

                            if (marker?.defaultMarker) {
                                return (
                                    <DefaultMarker
                                        key={index}
                                        position={[marker.latitude, marker.longitude]}
                                    />
                                )
                            }

                            return (
                                <Marker
                                    key={index}
                                    position={[marker.latitude, marker.longitude]}
                                    icon={marker?.iconElement}
                                    eventHandlers={{
                                        click: marker?.click
                                    }}>
                                </Marker>
                            )
                        })
                        :
                        <MarkerClusterGroup showCoverageOnHover={false} maxClusterRadius={30}>
                            {this.props.markers?.map((marker, index) => {
                                if (this.props.draggable) {
                                    return (
                                        <DraggableMarker
                                            position={[marker.latitude, marker.longitude]}
                                            onSelect={(pos) => this.props?.onClick(pos)}
                                            markerElement={marker.iconElement}
                                        />
                                    )
                                }

                                if (marker?.defaultMarker) {
                                    return (
                                        <DefaultMarker
                                            key={index}
                                            position={[marker.latitude, marker.longitude]}
                                        />
                                    )
                                }

                                return (
                                    <Marker
                                        key={index}
                                        position={[marker.latitude, marker.longitude]}
                                        icon={marker?.iconElement}
                                        eventHandlers={{
                                            click: marker?.click
                                        }}>
                                    </Marker>
                                )
                            })}
                        </MarkerClusterGroup>
                    }
                </MapContainer>
            </div>
        )
    }
}

export function DraggableMarker({ position, onSelect, markerElement }) {
    const [pos, setPos] = useState(position)
    const markerRef = useRef(null)

    useMapEvents({
        click(e) {
            setPos([e.latlng.lat, e.latlng.lng])
            onSelect([e.latlng.lat, e.latlng.lng])
        }
    })

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    let latLng = marker.getLatLng()
                    setPos(marker.getLatLng())
                    onSelect([latLng.lat, latLng.lng])
                }
            },
        }),
        [],
    )

    return (
        <Marker
            ref={markerRef}
            draggable={true}
            eventHandlers={eventHandlers}
            position={pos}
            icon={markerElement}>
        </Marker>
    )
}

export function DefaultMarker({ position }) {
    const markerRef = useRef(null)

    return (
        <Marker
            ref={markerRef}
            position={position}
            icon={divIcon({
                className: 'default-div-icon',
                iconSize: [32, 32],
                iconAnchor: [22, 40],
                html: ReactDOMServer.renderToString(
                    <div className="default-marker-container">
                        <img src={mapMarker} width={42} height={42} />
                        <div className="default-marker-gradient"></div>
                    </div>
                )
            })}>
        </Marker>
    )
}

export default Mapa