import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import PrivateRoute from '../PrivateRoute'
import Grid from './grid'
import Form from './form'

class ExportacaoDados extends Component {
   render() {
      return (
         <div className="hdv-render-div" style={{ marginBottom: '65px' }}>
            <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
               <Switch>
                  <PrivateRoute exact path='/exportacao-dados' component={Grid} />
                  <PrivateRoute path='/exportacao-dados/novo' component={Form} />
                  <PrivateRoute path='/exportacao-dados/:id/editar' component={Form} />
               </Switch>
            </div>
         </div>
      )
   }
}

export default ExportacaoDados
