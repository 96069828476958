import React from 'react'
import { InputGroup, InputGroupAddon, Button, Input, Modal, ModalHeader, ModalBody, Badge, ModalFooter } from 'reactstrap'
import { DateRangePicker } from 'react-date-range'
import * as rdrLocales from 'react-date-range/dist/locale'
import Select from '../../components/Select'
import { JSONTree } from 'react-json-tree'
import ReactLoading from 'react-loading'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'

export default class Logs extends React.Component {
    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        logs: [],
        carregando_dados: false,
        dataFormatada: '',
        datePickerShow: false,
        dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
        detalhes: { data: '' },
        checkClose: false,
        usuario: false,
        tela: false,
        carregado: false,
        modal: false,
        drop: false,
        log: { valor_novo: '', valor_atual: '' },
        semResultado: false,
        optionsUsuario: [],
        optionsTela: []
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        const base = `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}`

        axios.get(base + '/log-acessos-select/', this.configMe)
            .then((response) => {
                const { usuarios, telas } = response.data
                let optionsUsuario = [{ label: "Nenhum", value: false }]
                let optionsTela = []

                for (let i in usuarios) {
                    optionsUsuario.push({ label: usuarios[i], value: i })
                }

                for (let i in telas) {
                    optionsTela.push({ label: telas[i], value: i })
                }

                this.setState({ optionsUsuario, optionsTela })
            })
    }


    showDatePicker = () => {
        this.setState({ "datePickerShow": !this.state.datePickerShow })
    }

    checar_calendario_aberto = (e) => {
        if (this.state.checkClose === true) {
            let retArray = e.path.filter((path) => {
                if (path.className === "hdv-datepicker") {
                    return true
                }
                return false
            })

            if (retArray.length === 0) {
                this.setState({ "datePickerShow": false, checkClose: false })
            }
        } else
            this.setState({ checkClose: true })

        return true
    }

    handleInput = (name, e) => {
        this.setState({ [name]: e.value === "todas" ? false : e.value })
    }

    handleSelect = (ranges) => {
        let detalhes = this.state.detalhes
        detalhes.data = ranges.selection.startDate.toLocaleDateString() + "-" + ranges.selection.endDate.toLocaleDateString()
        this.setState({
            dateRange: {
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate,
                key: 'selection',
            },
            dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
            detalhes: detalhes
        })
    }

    handleSelect = (ranges) => {
        let data_inicio = ranges.selection.startDate
        let data_fim = ranges.selection.endDate
        if (ranges.selection.startDate.toLocaleDateString() === ranges.selection.endDate.toLocaleDateString()) {
            let data = new Date(ranges.selection.startDate.getTime())
            let dateArray = data.toLocaleDateString('pt-BR',
                {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                }
            ).split('/')

            data_inicio = new Date(dateArray[2], dateArray[1] - 1, dateArray[0], '00', '00', '00')
            data_fim = new Date(dateArray[2], dateArray[1] - 1, dateArray[0], '23', '59', '59')
        } else {
            let dataEnd = new Date(ranges.selection.endDate.getTime())
            let dateEndArray = dataEnd.toLocaleDateString('pt-BR',
                {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                }
            ).split('/')
            data_fim = new Date(dateEndArray[2], dateEndArray[1] - 1, dateEndArray[0], '23', '59', '59')
        }

        const detalhes = this.state

        this.setState({
            dateRange: { startDate: data_inicio, endDate: data_fim, key: 'selection' },
            dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
            detalhes: detalhes,
            carregado: true
        })
    }

    filtrar = () => {
        this.setState({ carregando_dados: true })
        let { dateRange, tela, usuario } = this.state

        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() } }
        let data = {
            data_inicio: (dateRange.startDate.getTime()) / 1000,
            data_fim: (dateRange.endDate.getTime()) / 1000,
            tela: tela,
            usuario: usuario
        }

        axios.post(cfg.base_api_url + cfg.api_v2 + `/${auth.getUserInfo().empresa}/log-acessos/`, JSON.stringify(data), config)
            .then(res => {
                if (!res.data)
                    this.setState({ semResultado: true, logs: res.data, carregando_dados: false })
                else {
                    let logs = res.data ?? []
                    logs.reverse()
                    this.setState({ logs, carregando_dados: false, semResultado: false })
                }
            })
            .catch(console.error)
    }

    convertToDate(stamp) {
        return new Date(stamp * 1000).toLocaleDateString("pt-BR", { hour: 'numeric', minute: 'numeric' })
    }

    handleDescricao = (descricao) => {
        let icon = null

        if (descricao.includes('Cadastrou'))
            icon = "fa fa-plus"
        else if (descricao.includes('Editou'))
            icon = "fa fa-pen"
        else if (descricao.includes('Deletou'))
            icon = "fa fa-trash"
        else if (descricao.includes('Desativou'))
            icon = "fa fa-ban"
        else if (descricao.includes('Enviou'))
            icon = "fa fa-long-arrow-alt-up"

        if (icon)
            return (<><i style={{ fontSize: '18px' }} className={icon}></i> {descricao}</>)
        else
            return descricao
    }

    orderData = () => {
        let { logs } = this.state
        this.setState({ logs: logs.reverse() })
    }

    render() {
        const { carregado, logs, log, semResultado, optionsTela, optionsUsuario } = this.state

        const theme = {
            scheme: 'monokai',
            author: 'wimer hazenberg (http://www.monokai.nl)',
            base00: '#F2F2F2',
            base01: '#212529',
            base02: '#212529',
            base03: '#212529',
            base04: '#212529',
            base05: '#212529',
            base06: '#212529',
            base07: '#212529',
            base08: '#212529',
            base09: '#212529',
            base0A: '#212529',
            base0B: '#212529',
            base0C: '#212529',
            base0D: '#212529',
            base0E: '#212529',
            base0F: '#212529',
        }

        return (
            <div className="hdv-render-div">
                {this.state.modal ?
                    <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: !this.state.modal })}>
                        <ModalHeader
                            toggle={() => this.setState({ modal: !this.state.modal })}>
                            Alteração
                        </ModalHeader>
                        <ModalBody>
                            <div className="log-json-tree">
                                {log.atual !== "{}" && !Array.isArray(log.atual)
                                    ?
                                    <>
                                        <strong>Anterior</strong>
                                        <JSONTree
                                            data={log.valor_atual}
                                            labelRenderer={([key]) => <strong>{key}: </strong>}
                                            valueRenderer={(valueAsString, value, ...keyPath) => valueAsString}
                                            hideRoot={true}
                                            theme={theme}
                                        />
                                    </>
                                    : null
                                }

                                {log.novo !== "{}" && !Array.isArray(log.novo)
                                    ?
                                    <>
                                        <strong>Novo</strong>
                                        <JSONTree
                                            data={this.state.log.valor_novo}
                                            labelRenderer={([key]) => <strong>{key}: </strong>}
                                            valueRenderer={(valueAsString, value, ...keyPath) => valueAsString}
                                            hideRoot={true}
                                            theme={theme}
                                        />
                                    </>
                                    : null
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="default"
                                onClick={() => this.setState({ modal: !this.state.modal })}>
                                Fechar
                            </Button>
                        </ModalFooter>
                    </Modal>
                    :
                    null
                }

                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Logs de Acesso</h4>
                        <div className="gray-background">
                            <i className="fa fa-file-alt fa-2x"></i>
                        </div>
                    </span>
                    <ReactLoading className={(this.state.carregando_dados) ? "hdv-report-loading" : "hdv-noshow-item"} type="cylon" color="#589bd4" width={50} />
                </div>

                <details open={true} className="custom-summary">
                    <summary>Filtros</summary>
                    <div style={{ padding: "20px" }} className="hdv-form-default pt-0">
                        <div className="row">
                            <div className="col-md-5">
                                <label className="required">Periodo</label>
                                <InputGroup className="hdv-force-hight pr-3">
                                    <Input className="hdv-force-hight" placeholder="Selecione o periodo" onChange={() => false} disabled value={this.state.dataFormatada} />
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                                            <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                            {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                                <div className={(this.state.datePickerShow) ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                                    <DateRangePicker
                                        locale={rdrLocales.pt}
                                        ranges={[this.state.dateRange]}
                                        onChange={this.handleSelect}
                                    />
                                </div>
                            </div>

                            <div className="row" style={{ position: "absolute", width: "100%", justifyContent: "end" }}>
                                <div className="col-md-3">
                                    <label className="required">Usuário</label>
                                    <Select
                                        onChange={(e) => this.handleInput('usuario', e)}
                                        options={optionsUsuario}
                                        defaultValue={{ label: "Nenhum", value: false }}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <label className="required">Ação</label>
                                    <Select
                                        onChange={(e) => this.handleInput('tela', e)}
                                        options={optionsTela}
                                        defaultValue={{ label: "Todas as Ações", value: false }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </details>

                <div className="hdv-btn-group">
                    <button disabled={!carregado} onClick={() => this.filtrar()} className="hdv-btn-forms hdv-btn-blue">Gerar Relatório</button>
                </div>

                {!semResultado ?
                    <table className="table afira-table">
                        {logs.length > 0 ?
                            <thead>
                                <tr>
                                    <th onClick={() => this.orderData()} style={{ cursor: 'pointer' }}>
                                        Data <i className="fa fa-sort"></i>
                                    </th>
                                    <th>Usuário</th>
                                    <th>Descrição</th>
                                    <th>Ação</th>
                                    <th></th>
                                </tr>
                            </thead>
                            : null}
                        <tbody>
                            {this.state.logs.map((log, index) => (
                                <tr key={index}>
                                    <td>{this.convertToDate(log.timestamp)}</td>
                                    <td>{log.usuario}</td>
                                    <td>{this.handleDescricao(log.descricao)}</td>
                                    <td>{log.tela}</td>
                                    {log.valor_atual || log.valor_novo !== "{}"
                                        ? <td>
                                            <Badge
                                                color="primary"
                                                style={{ padding: '6px 16px', cursor: 'pointer', fontSize: '12px' }}
                                                onClick={() => this.setState({ modal: true, log })}>
                                                Ver
                                            </Badge>
                                        </td>
                                        :
                                        <td>---</td>
                                    }
                                </tr>)
                            )}
                        </tbody>
                    </table>
                    :
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>Não houve resultados</p>
                }
            </div>
        )
    }
}