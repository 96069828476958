const ultimoReportePorEquipamento = {}

const markersMethods = {
    
    updateMarkers ( _data, { markers, sendMarkers, createDivIcon } ) {
        const { reporte, validacao } = _data 
        const dispositivoId = validacao.id_dispositivo 

        ultimoReportePorEquipamento[dispositivoId] = reporte

        const updatedMarkers = markers.map((marker) => {

            if (!marker.campos || marker.campos.length === 0) return marker

            const pertenceAoDispositivo = marker.campos.some((campo) => campo.campo__equipamento__usuario_mqtt === dispositivoId)

            if (!pertenceAoDispositivo) return marker 

            marker.campos.forEach((campo) => {
                if(campo.campo__equipamento__usuario_mqtt === dispositivoId) {
                    campo.ultimo_evento = reporte
                }
            })

            if(marker.condicoes.length > 0) {
                const novaCor = this.checkConditions(marker.condicoes) ?? marker.defaultColor
            
                marker['iconElement'] = createDivIcon(
                    { nome: marker.nome, tipo__cor: novaCor }, 
                    marker.status, 
                    marker.icon
                )
            }

            return marker
        })

        sendMarkers(updatedMarkers)
    },
  
    checkConditions (condicoes) {
        for (const condicao of condicoes) {
            let approved = true
            
            for (const regra of condicao.condicoes.regras) {
                
                const parametroIdentificacao = regra.parametro.identificacao
                const parametroUsuarioMqtt = regra.parametro.usuario_mqtt
                
                let operador = regra.condicao.symbol
                let valorRegra = regra.valor 
                let tipoDadoSaida = regra.parametro.tipo_dado_saida
                
                const valorReportado = ultimoReportePorEquipamento?.[parametroUsuarioMqtt]?.[parametroIdentificacao] ?? null
    
                if(valorReportado === null) {
                    break
                }

                const conversao = {
                    'decimal': parseFloat,
                    'inteiro': parseInt,
                    'string': String,
                    'booleano': Boolean
                }

                valorRegra = conversao[tipoDadoSaida](valorRegra)
                
                const logica = {
                    '>': valorReportado > valorRegra,
                    '<': valorReportado < valorRegra,
                    '=': valorReportado == valorRegra,
                    '!=': valorReportado != valorRegra,
                    '>=': valorReportado >= valorRegra,
                    '<=': valorReportado <= valorRegra
                }

                if(!logica[operador]) {
                    approved = false
                }
            }
            
            
            if(approved) {
                return condicao.condicoes.cor
            }
        }
        
        return null 
    }
}

export default markersMethods