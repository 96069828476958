import React, { Component } from 'react'
import Select from '../../components/Select'
import { Link, Redirect } from 'react-router-dom'
import ButtonsForm from '../../components/ButtonsForm'
import helper from '../../utils/helper'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'

class Form extends Component {
    state = {
        detalhes: {
            cliente: null, 
            monitorado: null, 
            equipamento: null, 
            descricao: null,
            tipo: { label: "Disponibilidade", value: "disponibilidade" }, 
            chave_operacao: null,
            rotulo: null, 
            habilita_nivel: false, 
            habilita_vazao: false,
            habilita_volume: false,
            campo_nivel: null,
            campo_vazao: null,
            campo_volume: null,
            horimetro: null
        },
        clientes: [],
        monitorados: [],
        cooldown: false,
        equipamentos: [],
        campos: [],
        isCliente: auth.getUserInfo()?.cliente
    }

    componentDidMount() {
        const { detalhes, isCliente } = this.state

        if (this.props.match.params.id) {
            this.getData()
        }

        if (isCliente) {
            this.getMonitorado()
            delete detalhes.cliente
            this.setState({ detalhes })
        } else {
            this.getClientes()
        }
    }

    getData = () => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + `/${userInfo.empresa}/mira/${this.props.match.params.id}`, config_me)
            .then(res => {
                let { detalhes, isCliente } = this.state
                let result = res.data

                detalhes["descricao"] = result?.descricao
                detalhes["tipo"] = {
                    'value': result?.tipo,
                    'label': result?.tipo.charAt(0).toUpperCase() + result?.tipo.slice(1)
                }
                detalhes["chave_operacao"] = result?.chave_operacao
                detalhes["rotulo"] = result?.rotulo
                detalhes["habilita_nivel"] = result?.habilita_nivel
                detalhes["habilita_vazao"] = result?.habilita_vazao
                detalhes["habilita_volume"] = result?.habilita_volume
                detalhes["monitorado"] = result?.monitorado
                detalhes["equipamento"] = result?.equipamento
                detalhes["horimetro"] = result?.horimetro


                result?.campo_vazao !== null ? detalhes["campo_vazao"] = result?.campo_vazao : null
                result?.campo_nivel !== null ? detalhes["campo_nivel"] = result?.campo_nivel : null
                result?.campo_volume !== null ? detalhes["campo_volume"] = result?.campo_volume : null

                if (!isCliente) {
                    detalhes['cliente'] = result.cliente
                    this.getClientes()
                }
                this.getMonitorado(result.cliente.value)
                this.getEquipamento(result.monitorado.value)
                this.getCamposEquipamento(result.equipamento.value)

                this.setState({ detalhes })
            })
    }

    getClientes = () => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/acesso/modulo/2/revenda/` + `?monitorado_restrito=${userInfo.monitorado_restrito}`, config_me)
            .then((res) => {
                let optionsCliente = []
                res.data.forEach((c) => {
                    optionsCliente.push({ "value": c.cliente__id, "label": c.cliente__nome_fantasia })
                })

                this.setState({ clientes: optionsCliente })
            })
    }

    getMonitorado = (id_cliente) => {
        let userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()

        bodyFormData.append('cliente_id', id_cliente ?? userInfo.id)

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/filtro/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((response) => {
            let optionsMonitorado = []
            response.data.forEach((c) => {
                optionsMonitorado.push({ "value": c.id, "label": c.nome })
            })
            this.setState({ monitorados: optionsMonitorado })
        }).catch(console.error)
    }

    getEquipamento = (id_monitorado) => {
        let userInfo = auth.getUserInfo()
        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-equipamento/' + id_monitorado + '/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((res) => {
                let optionsEquipamento = []
                let optionsCampos = []

                res.data.equipamentos.forEach((equipamento) => optionsEquipamento.push(equipamento))
                res.data.campos.forEach((campo) => optionsCampos.push(campo))

                this.setState({ equipamentos: optionsEquipamento, campos: optionsCampos })
            })
            .catch(console.error)
    }

    getCamposEquipamento = (id_equipamento) => {
        let userInfo = auth.getUserInfo()

        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/campo-customizado/?equipamento=${id_equipamento}`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((res) => {
                let fields = []

                res.data?.results.forEach((field) => fields.push({ label: field.descricao, value: field.id, identificacao: field.identificacao }))

                this.setState({ campos: fields })
            })
            .catch(error => console.log(error))
    }

    handleChange = (e) => {
        let detalhes = Object.assign({}, this.state.detalhes)
        detalhes[e.target.id] = e.target.type === "checkbox" ? e.target.checked : e.target.value

        if(e.target.type === "checkbox") {
            if(!e.target.checked) {
                let id = e.target.id.replace("habilita", "campo")
                document.querySelector(`#validacao-${id}`).innerHTML = ""
            }
        } else {
            document.querySelector(`#validacao-${e.target.id}`).innerHTML = ""
        }

        this.setState({ detalhes })
    }

    handleChangeSelect = (selectedOption, field) => {
        let obj = this.state.detalhes
        let canotClearFields = ["descricao", "chave_operacao", "rotulo", "tipo", "habilita_vazao", "habilita_nivel", "habilita_volume"]

        obj[field] = selectedOption
        document.querySelector(`#validacao-${field}`).innerHTML = ""

        if (field === "cliente") {
            this.getMonitorado(selectedOption.value)
            canotClearFields.push("cliente")
        } else if (field === 'monitorado') {
            this.getEquipamento(selectedOption.value)
            canotClearFields.push("cliente", "monitorado")
        } else if (field === 'equipamento') {
            this.getCamposEquipamento(selectedOption.value)
            canotClearFields.push("cliente", "monitorado", "equipamento")
        }

        if (field != "campo_vazao" && field != "campo_nivel" && field != "campo_volume" && field != "tipo" && field != "horimetro") {
            for (let i in obj) {
                !canotClearFields.includes(i) ? obj[i] = null : null
            }
        }

        this.setState({ detalhes: obj })
    }

    saveAction = (novoCadastro) => {
        const { detalhes } = this.state

        this.setState({ cooldown: true })

        let userInfo = auth.getUserInfo()
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_v2 + `/${userInfo.empresa}/mira/` + this.props.match.params.id + '/'
            verb = 'PATCH'
        } else {
            requestURL = cfg.base_api_url + cfg.api_v2 + `/${userInfo.empresa}/mira/`
            verb = 'POST'
        }

        let data = {
            monitorado_id: detalhes.monitorado?.value ?? null,
            descricao: detalhes.descricao,
            chave_operacao: detalhes.chave_operacao,
            rotulo: detalhes.rotulo,
            tipo: detalhes.tipo.value,
            habilita_vazao: detalhes.habilita_vazao,
            horimetro: detalhes.horimetro ?? null,
        }

        detalhes.habilita_vazao ? data['campo_vazao'] = detalhes?.campo_vazao?.value ?? null : null

        if (detalhes.tipo.value === 'disponibilidade') {
            data['habilita_nivel'] = detalhes.habilita_nivel
            detalhes.habilita_nivel ? data['campo_nivel'] = detalhes?.campo_nivel?.value ?? null : null
        } else {
            data['habilita_volume'] = detalhes.habilita_volume
            detalhes.habilita_volume ? data['campo_volume'] =  detalhes?.campo_volume?.value ?? null : null
        }
      
        axios({
            method: verb,
            url: requestURL,
            data: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((res) => {
            if (novoCadastro) {
                window.location.reload()
            } else {
                helper.dispatchEvent("showAviso", {
                    message: `${verb === "POST" ? 'Criado' : 'Editado'} com sucesso`,
                    callback: () => this.setState({ redirect: true, path: "/mira" }),
                    callbackClose: verb === "POST" ?
                        () => {
                            window.location.href = `/mira/${res.data.id}/editar?protocolo=true`
                            setTimeout(() => {
                                window.location.reload()
                            }, 1000)
                        }
                        : null
                })
            }
        })
        .catch((error) => {
            let erros = Object.entries(error.response.data)

            if (Array.isArray(erros)) {
                for (let i = 0; i < erros.length; i++) {
                    let span = document.querySelector(`#validacao-${erros[i][0]}`)
                    if (span) span.innerHTML = erros[i][1]
                }
            }

            if(Array.isArray(error.response.data?.cliente)) {
                helper.dispatchEvent("showAviso", {
                    message: error.response.data.cliente[0],
                })
            }
        })

        setTimeout(() => this.setState({ cooldown: false }), 2500)
    }

    render() {
        const { clientes, monitorados, equipamentos, campos, detalhes, isCliente } = this.state

        const monitoradoOptions = detalhes.monitorado
        const equipamentoOptions = detalhes.equipamento
        const tipoOptions = detalhes.tipo
        const clienteOptions = detalhes.cliente
        const vazaoOptions = detalhes?.campo_vazao
        const volumeOptions = detalhes?.campo_volume
        const nivelOptions = detalhes?.campo_nivel

        if (this.state.redirect === true) {
            return <Redirect to={{ pathname: '/redirect', state: { pathto: this.state.path } }} />
        }

        return (
            <>
                <div>

                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            {this.props.match.params.id ? <h4>Editar MIRA</h4> : <h4>Novo MIRA</h4>}
                            <div className="gray-background">
                                <i className="fa fa-network-wired fa-2x"></i>
                            </div>
                        </span>
                    </div>

                    <form className="hdv-form-default">
                        <div className="row">
                            <div style={{ display: isCliente ? "none" : "block" }} className="col-md-4">
                                <label className="required">Cliente</label>
                                <Select
                                    name="cliente"
                                    options={clientes}
                                    value={clienteOptions}
                                    onChange={(e) => { this.handleChangeSelect(e, "cliente") }}
                                />
                                <span id='validacao-cliente'></span>
                            </div>
                            <div className="col-md-4">
                                <label className="required">Monitorado</label>
                                <Select
                                    name="monitorado"
                                    options={monitorados}
                                    value={monitoradoOptions}
                                    onChange={(e) => this.handleChangeSelect(e, 'monitorado')}
                                />
                                <span id='validacao-monitorado'></span>
                            </div>
                            <div className="col-md-4">
                                <label className="required">Equipamento</label>
                                <Select
                                    name="equipamento"
                                    options={equipamentos}
                                    value={equipamentoOptions}
                                    onChange={(e) => this.handleChangeSelect(e, 'equipamento')}
                                />
                                <span id='validacao-equipamento'></span>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-12">
                                <label className="required">Descrição</label>
                                <input
                                    value={detalhes.descricao ?? ""}
                                    type='text'
                                    id='descricao'
                                    onChange={(e) => this.handleChange(e)}
                                />
                                <span id='validacao-descricao'></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <label className="required">Chave Operação</label>
                                <input
                                    value={detalhes.chave_operacao ?? ""}
                                    type='text'
                                    id='chave_operacao'
                                    onChange={(e) => this.handleChange(e)}
                                />
                                <span id='validacao-chave_operacao'></span>
                            </div>
                            <div className="col-md-6">
                                <label className="required">Rótulo</label>
                                <input
                                    value={detalhes.rotulo ?? ""}
                                    type='text'
                                    id='rotulo'
                                    onChange={(e) => this.handleChange(e)}
                                />
                                <span id='validacao-rotulo'></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label className="required">Tipo</label>
                                <Select
                                    name="tipo"
                                    value={tipoOptions}
                                    options={
                                        [
                                            { label: "Disponibilidade", value: "disponibilidade" },
                                            { label: "Demanda", value: "demanda" }
                                        ]
                                    }
                                    onChange={(e) => this.handleChangeSelect(e, 'tipo')}
                                />
                                <span id='validacao-tipo'></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <label className="required">
                                    Habilitar Vazão
                                    &nbsp;
                                    <input
                                        checked={detalhes.habilita_vazao}
                                        type='checkbox'
                                        id='habilita_vazao'
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </label>
                                <Select
                                    options={campos}
                                    onChange={(e) => this.handleChangeSelect(e, 'campo_vazao')}
                                    value={vazaoOptions}
                                    disabled={!detalhes.habilita_vazao}
                                />
                                <span id='validacao-campo_vazao'></span>
                            </div>

                            <div style={{ display: detalhes.tipo.value === 'demanda' ? "none" : 'block' }} className="col-md-4">
                                <label className="required">
                                    Habilitar Nivel
                                    &nbsp;
                                    <input
                                        checked={detalhes.habilita_nivel}
                                        type='checkbox'
                                        id='habilita_nivel'
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </label>
                                <Select
                                    options={campos}
                                    onChange={(e) => this.handleChangeSelect(e, 'campo_nivel')}
                                    value={nivelOptions}
                                    disabled={!detalhes.habilita_nivel}
                                />
                                <span id='validacao-campo_nivel'></span>
                            </div>

                            <div style={{ display: detalhes.tipo.value === 'disponibilidade' ? "none" : 'block' }} className="col-md-4">
                                <label className="required">
                                    Habilitar volume
                                    &nbsp;
                                    <input
                                        checked={detalhes.habilita_volume}
                                        type='checkbox'
                                        id='habilita_volume'
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </label>
                                <Select
                                    options={campos}
                                    onChange={(e) => this.handleChangeSelect(e, 'campo_volume')}
                                    value={volumeOptions}
                                    disabled={!detalhes.habilita_volume}
                                />
                                <span id='validacao-campo_volume'></span>
                            </div>

                            <div className='col-md-4'>
                                <label className="required">Horímetro</label>
                                <Select
                                    options={campos}
                                    onChange={(e) => this.handleChangeSelect(e, 'horimetro')}
                                    value={detalhes.horimetro}
                                />
                                <span id='validacao-horimetro'></span>           
                            </div>
                        </div>
                    </form>

                    <div className="hdv-btn-group">
                        <ButtonsForm
                            route="/mira"
                            edit={this.props.match.params.id ? true : false}
                            cooldown={this.state.cooldown}
                            buttons={[
                                {
                                    method: "post",
                                    button: <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                                },

                                {
                                    method: "post",
                                    button: <button style={{ display: this.props.match.params.id ? "none" : "inline-block" }} onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-greenlight">Salvar e Cadastrar um Novo</button>
                                },

                                {
                                    method: "patch",
                                    button: <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                                },
                            ]}
                        />

                        <Link to={"/mira"}>
                            <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                        </Link>
                    </div>
                </div>
            </>
        )
    }
}

export default Form