import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom"
import ConfirmDelete from '../../components/ConfirmDelete'
import ButtonsGrid from '../../components/ButtonsGrid'
import Table from '../../components/Table'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'
import helper from '../../utils/helper'

class Grid extends Component {

    state = {
        data: [],
        loading: true,
        enableDeletar: false,
        enableEditar: false,
        redirectToEdit: false
    }

    getData = (state) => {
        this.setState({ loading: true })
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/monitorado/?limit=${state?.lineCount}` + state.filter

        if (state.url) {
            url = state.url
        }

        axios.get(url, config_me).then((res) => {
            let { results, next, previous, count } = res.data
            this.setState({ loading: false, data: results, next, previous, count })
        })
    }

    deletar = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        this.setState({ loading: true, enableDeletar: false, enableEditar: false })

        this.state.table?.state?.selected?.map((value, index) =>
            requestArray.push(axios.delete(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/' + value + '/', config_me))
        )

        axios.all(requestArray).then(axios.spread((acct, perms) => {
            this.state?.table?.fetch()
            this.state?.table?.unselect()
        }))
    }

    editar = () => {
        let toGo = "monitorado/" + this.state.table?.state?.selected?.[0] + "/editar"
        let from = { pathname: toGo }
        this.setState({ redirectToEdit: from })
    }

    render() {
        const { redirectToEdit, table } = this.state

        if (redirectToEdit) {
            return <Redirect to={redirectToEdit} />
        }

        const columns = [{
            Header: 'Nome do Monitorado',
            Accessor: 'nome'
        }, {
            Header: 'Cliente',
            Accessor: 'cliente.nome_fantasia'
        }, {
            Header: 'Tipo',
            Accessor: 'tipo.nome',
            Cell: (original) => {
                return <span>{original.tipo.nome ?? "---"}</span>
            }
        },{
            Header: 'Criado em',
            Accessor: "data_criado",
            Cell: (original) => {
                return <span>{helper.convertDate(original.data_criado)}</span>
            }
        },{
            Header: 'Atualizado em',
            Accessor: "data_atualizado",
            Cell: (original) => {
                return <span>{helper.convertDate(original.data_atualizado)}</span>
            }
        }]

        return (
            <div>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Cadastro de Monitorados</h4>
                        <div className="gray-background">
                            <i className="fa fa-map-marked-alt fa-2x"></i>
                        </div>
                    </span>
                </div>

                <div className="hdv-btn-group">
                    <ButtonsGrid 
                        route="/monitorado"
                        buttons={[
                            {
                                method: "post",
                                button: <Link to="/monitorado/novo"><button className="hdv-btn-forms hdv-btn-green ">Novo</button></Link>
                            },

                            {
                                method: "patch",
                                button: <button onClick={() => { if (table?.state?.enableEditar) { this.editar() } }} className={"hdv-btn-forms hdv-btn-yellow " + (table?.state?.enableEditar ? "" : "hdv-btn-disable")}>Editar</button>
                            },

                            {
                                method: "delete",
                                button: <ConfirmDelete enabled={table?.state?.enableDeletar} action={this.deletar} />
                            }
                        ]}
                    />   
                </div>

                <Table
                    checkbox
                    data={this.state.data}
                    loading={this.state.loading}
                    next={this.state.next}
                    count={this.state.count}
                    previous={this.state.previous}
                    columns={columns}
                    onFetchData={this.getData}
                    onUpdate={(table) => this.setState({ table })}
                />
            </div>
        )
    }
}

export default Grid
