import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Redirect } from 'react-router-dom'
import ButtonsForm from '../../components/ButtonsForm'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Select from '../../components/Select'
import ModalImage from '../../components/ModalImage'
import helper from '../../utils/helper'
import Mapa from '../../components/Mapa'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'
import ModalTipo from './modalTipo'
import ReactDOMServer from 'react-dom/server'
import { divIcon } from 'leaflet'
import ModalCondicoes from './modalCondicoes'

class Form extends Component {
    userInfo = auth.getUserInfo()
    mapRef = null

    state = {
        mapObject: false,
        address: "",
        position: [0, 0],
        markers: [],
        location: false,
        detalhes: { "nome": "", "tipo": "", "latitude": 0, "longitude": 0, "point": "", "cliente": "" },
        formerrors: [],
        redirect: false,
        path: "",
        tab: 0,
        setores: [],
        tipos: [],
        clientes: [],
        item: { "equipamento_id": "", "entrada_tipo": "", "entrada_id": "" },
        optionSelected: { "tipo": {}, "cliente": {}, "equipamento_id": {}, "parametro_id": {}, "comando_id": {} },
        parametros_selecionados: [],
        equipamentos: [],
        parametros_vinculados: [],
        comandos_vinculados: [],
        fotos_vinculados: [],
        cooldown: false,
        foto_file: { foto_file: null, foto_descricao: "" },
        foto_temp: "https://rs485project.s3.amazonaws.com/sem-imagem-10.jpg",
        open_image: false,
        image: "",
        fromTelaStatus: window.location.search.includes("?status=true"),
        statusModalTipo: false,
        statusModalCondicoes: false,
        toAddFotos: window.location.search.includes("?foto=true")
    }

    getClientes = () => {
        const userInfo = auth.getUserInfo()
        const config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let optionsCliente = []

        if (userInfo.tipo === "cliente") {
            optionsCliente.push({ label: userInfo.nome, value: userInfo.cliente })

            const detalhes = Object.assign({}, this.state.detalhes)
            const optionSelected = Object.assign({}, this.state.optionSelected)

            optionSelected['cliente'] = optionsCliente[0]
            detalhes['cliente'] = optionsCliente[0]

            this.setState({ optionSelected, detalhes, clientes: optionsCliente })
        } else {
            axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/', config_me)
                .then((clientes) => {
                    clientes.data?.results?.forEach((a) => {
                        optionsCliente.push({ "value": a.id, "label": a.nome_fantasia })
                    })

                    this.setState({ "clientes": optionsCliente })
                })
        }
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        } else {
            setTimeout(() => {
                if (this.mapRef != null) {
                    this.mapRef.current.setView([-17.408282, -53.117169], 4)

                    let markers = [{
                        latitude: -17.408282,
                        longitude: -53.117169,
                        draggable: true,
                        iconElement: this.createDivIcon({ cor: "#0062b1", icone: "las la-cloud", nome: "" })
                    }]

                    this.setState({ markers })
                }
            }, 500)
        }

        const address = document.querySelector("#address")

        helper.onUserStopsTyping(address, () => {
            if (address?.value?.length > 0) {
                this.searchAddress()
            }
        }, 1200)
    }

    componentWillMount = () => {
        let requestArray = []
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/monitorado-tipo/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/equipamento/', config_me))

        if (this.props.match.params.id) {
            const filter = `?monitorado=${this.props.match.params.id}`

            requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/monitorado-comando/' + filter, config_me))
            requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/monitorado-parametro/' + filter, config_me))
            requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/monitorado-foto/' + filter, config_me))
        }

        this.getClientes()

        axios.all(requestArray).then(
            axios.spread((tipos, equipamentos, comandos_monitorado, parametros_monitorado, fotos_monitorado) => {
                let optionsTipos = []
                let optionsEquipamentos = []
                let equipamentos_includes = []
                let comando_monitorado = []
                let parametro_monitorado = []
                let foto_monitorado = []

                if (tipos.status === 200) {
                    tipos.data.forEach((a) => optionsTipos.push({ "value": a.id, "label": a.nome, "cor": a.cor, "icone": a.icone }))
                }

                if (equipamentos.status === 200) {
                    let equipamentos_sem_duplicacao = equipamentos.data?.results?.filter((equipamento_check) => {
                        if (equipamentos_includes.includes(equipamento_check.id)) {
                            return false
                        } else {
                            equipamentos_includes.push(equipamento_check.id)
                            return true
                        }
                    })
                    equipamentos_sem_duplicacao.forEach((a) => {
                        optionsEquipamentos.push({ "value": a.id, "label": a.imei })
                    })
                }

                if (comandos_monitorado) {
                    if (comandos_monitorado.status === 200) {
                        comando_monitorado = comandos_monitorado.data.results
                    }
                }

                if (parametros_monitorado) {
                    if (parametros_monitorado.status === 200) {
                        parametro_monitorado = parametros_monitorado.data.results
                    }
                }

                if (fotos_monitorado) {
                    if (fotos_monitorado.status === 200) {
                        foto_monitorado = fotos_monitorado.data.results
                    }
                }

                this.setState({ "tipos": optionsTipos, 'equipamentos': optionsEquipamentos, 'comandos_vinculados': comando_monitorado, 'parametros_vinculados': parametro_monitorado, 'fotos_vinculados': foto_monitorado })
            }))
    }

    getData = async () => {
        let requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/monitorado/' + this.props.match.params.id + '/'
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        await axios.get(requestURL, config_me).then((res) => {
            let { position, location } = this.state
            let detalhes = { ...res.data }
            let markers = []
            delete res.data.id
            let optionObj = this.state.optionSelected

            if (res.data.cliente) {
                optionObj["cliente"] = { "value": res.data.cliente.id, "label": res.data.cliente.nome_fantasia }
                detalhes.cliente = { "value": res.data.cliente.id, "label": res.data.cliente.nome_fantasia }
            }

            if (res.data.tipo) {
                optionObj["tipo"] = { "value": res.data.tipo.id, "label": res.data.tipo.nome }
                detalhes.tipo = { "value": res.data.tipo.id, "label": res.data.tipo.nome, "cor": res.data.tipo.cor, "icone": res.data.tipo.icone }
            }

            if (this.mapRef != null) {
                position = [res.data?.latitude, res.data?.longitude]
                location = [res.data?.latitude, res.data?.longitude]
                this.mapRef.current.setView(position, 4)

                markers = [{
                    latitude: res.data.latitude,
                    longitude: res.data.longitude,
                    draggable: true,
                    iconElement: this.createDivIcon(res.data.tipo),
                }]
            }

            let tabInitial = 0
            if (this.state.toAddFotos) {
                const tabs = Array.from(document.querySelectorAll('.react-tabs__tab'))
                const tabDataArray = tabs.map((tab, index) => ({
                    name: tab.innerText,
                    index: index,
                }))
                const getFotos = tabDataArray.filter(item => item.name === 'Fotos').map(item => item.index)[0]
                tabInitial = getFotos
            }

            this.setState({ detalhes, markers, location, position, optionSelected: optionObj, address: res.data.point, tab: tabInitial })
        }).catch(console.error)
    }

    handleChangeSelectCliente = (selectedOption, field) => {
        let obj = this.state.detalhes
        obj[field] = selectedOption

        let optionObj = this.state.optionSelected
        optionObj["cliente"] = { "value": selectedOption.value, "label": selectedOption.label }

        this.setState({ detalhes: obj, selectedOption: optionObj })


        document.querySelector('#validacao-cliente').innerHTML = ''
    }

    handleChange = (e) => {
        let obj = this.state.detalhes
        obj[e.target.id] = e.target.value
        if (document.querySelector(`#validacao-${e.target.name}`))
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ''
        this.setState({ "detalhes": obj })
    }

    handleChangeSelect = (selectedOption, field) => {
        let obj = this.state.detalhes
        obj[field] = selectedOption
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        if (document.querySelector(`#validacao-${field.slice(0, -3)}-logica`))
            document.querySelector(`#validacao-${field.slice(0, -3)}-logica`).innerHTML = ""


        if (field === "tipo") {
            document.querySelector('#validacao-tipo').innerHTML = ''
            this.updateMarker(selectedOption)
        }

        this.setState({ "optionSelected": optionObj, "detalhes": obj })
    }

    saveAction = (novo_cadastro, grid = false) => {
        this.setState({ cooldown: true })

        let userInfo = auth.getUserInfo()
        let requestURL = ''
        let verb = ''
        let bodyFormData = new FormData()

        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/' + this.props.match.params.id + '/'
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/'
            verb = 'post'
        }

        bodyFormData.set("empresa_id", userInfo.empresa)
        bodyFormData.append("nome", this.state.detalhes?.nome)
        bodyFormData.append('point', this.state.address)
        bodyFormData.append("cliente_id", this.state.detalhes?.cliente?.value ?? "")
        bodyFormData.append("tipo_id", this.state.detalhes?.tipo?.value ?? "")

        if (this.state.location) {
            bodyFormData.set('latitude', String(this.state.location[0]).substring(0, 10))
            bodyFormData.set('longitude', String(this.state.location[1]).substring(0, 10))
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => {
            const fromTelaStatus = this.state.fromTelaStatus
            const toAddFotos = this.state.toAddFotos

            if (novo_cadastro === true) {
                this.setState({ redirect: true, path: "/monitorado/novo" })
            } else {
                helper.dispatchEvent("showAviso", {
                    message: `Monitorado ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`,
                    callback: () => this.setState({ redirect: true, path: fromTelaStatus ? `/status/${res.data.id}` : "/monitorado" || toAddFotos ? "/lista-monitorados" : "/monitorado" }),
                    callbackClose: verb === "post" ?
                        () => {
                            window.location.href = `/monitorado/${res.data.id}/editar`
                            setTimeout(() => { window.location.reload() }, 1000)
                        }
                        : null
                })
            }
        }).catch((error) => {
            let erros = Object.entries(error.response.data)

            if (Array.isArray(erros)) {
                for (let i = 0; i < erros.length; i++) {
                    let span = document.querySelector(`#validacao-${erros[i][0]}`)
                    if (span)
                        span.innerHTML = erros[i][1]
                }
            }
        })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    generateOption = (source, value, desc) => {
        let options = source.map((obj, idx) =>
            <option key={idx} value={obj[value]}>{obj[desc]}</option>
        )
        return options
    }

    handleAddress = (e) => {
        this.setState({ "address": e.target.value.trim() })
        document.querySelector("#validacao-point").innerHTML = ""
    }

    handleChangeSelectEquipamentosParametros = (selectedOption, field) => {
        let obj = this.state.item
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        let filter = `?equipamento=${selectedOption.value}`
        let userInfo = auth.getUserInfo()
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/campo-customizado/' + filter
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(requestURL, config_me).then((response) => {
            let parametros_selecionados = []
            let vinculados = this.state.parametros_vinculados?.map(item => item?.campo?.id)

            if (response.data?.results?.length > 0) {
                parametros_selecionados.push({ "value": "all", "label": "Selecionar Todos" })
            }

            response.data?.results?.forEach((value) => {
                parametros_selecionados.push({
                    "value": value.id, "label": value.descricao,
                    "isDisabled": vinculados.includes(value.id)
                })
            })

            this.setState({ "optionSelected": optionObj, "parametro": obj, "parametros_selecionados": parametros_selecionados })
        })

        if (document.querySelector(`#validacao-${field.slice(0, -3)}`))
            document.querySelector(`#validacao-${field.slice(0, -3)}`).innerHTML = ""
    }

    handleChangeSelectParametros = (selectedOption, field) => {
        let obj = this.state.item
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        this.setState({ "optionSelected": optionObj, "item": obj })
    }

    handleChangeSelectEquipamentosComandos = (selectedOption, field) => {
        let obj = this.state.item
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        let filter = `?equipamento=${selectedOption.value}`
        let userInfo = auth.getUserInfo()
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/comando-customizado/' + filter
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(requestURL, config_me).then((response) => {
            let comandos_selecionados = []
            let vinculados = this.state.comandos_vinculados?.map(item => item?.comando?.id)

            if (response.data?.results?.length > 0) {
                comandos_selecionados.push({ "value": "all", "label": "Selecionar Todos" })
            }

            response.data?.results?.forEach((value) => {
                comandos_selecionados.push({
                    "value": value.id, "label": value.descricao,
                    "isDisabled": vinculados.includes(value.id)
                })
            })

            this.setState({ "optionSelected": optionObj, "comando": obj, comandos_selecionados })
        })

        if (document.querySelector(`#validacao-${field.slice(0, -3)}`))
            document.querySelector(`#validacao-${field.slice(0, -3)}`).innerHTML = ""
    }

    handleChangeSelectComandos = (selectedOption, field) => {
        let obj = this.state.item
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        this.setState({ "optionSelected": optionObj, "item": obj })
    }

    openImage = (image) => {
        this.setState({ open_image: true, image: image })
    }

    createRows = (source, campo_principal, campo_secundario, recurso, params = null) => {
        return this.state[source].map((campo, i) => {
            const splitPrincipal = campo_principal.split(".")
            const splitSecundario = campo_secundario.split(".")
            let principalValue
            let secundarioValue

            if (splitPrincipal?.length >= 1) {
                principalValue = eval(`campo?.${splitPrincipal.join("?.")}`)

                if (typeof principalValue !== "undefined") {
                    principalValue = String(principalValue)
                }
            }

            if (splitSecundario?.length >= 1) {
                secundarioValue = eval(`campo?.${splitSecundario.join("?.")}`)

                if (typeof secundarioValue !== "undefined") {
                    secundarioValue = String(secundarioValue)
                }
            }

            if (params) {
                try {
                    let link = params.link.replace("[0]", eval(`campo?.${params.field}`))
                    principalValue = (<Link to={link}>{principalValue}</Link>)
                } catch (err) {
                    console.error(err)
                }
            }

            return (
                <tr key={i}>
                    <td style={{ wordBreak: "break-all", verticalAlign: "middle" }}>
                        {campo_principal == "foto" ?
                            <div onClick={() => this.openImage(campo[campo_principal])} className='image-monitorado' style={{ backgroundImage: `url(${campo[campo_principal]})` }}></div>
                            :
                            principalValue
                        }
                    </td>

                    <td style={{ verticalAlign: "middle" }}>{secundarioValue}</td>
                    <td style={{ textAlign: "center" }} className="clean-action">
                        <button
                            onClick={() => { this.removeItem(campo['id'], recurso) }}
                            className="hdv-btn-forms hdv-btn-red">
                            Remover
                        </button>
                    </td>
                </tr>
            )
        })
    }

    addNovoParametro = (event) => {
        event.preventDefault()
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-parametro/'
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        if (!this.state.parametro?.parametro_id) return

        if (this.state.optionSelected.parametro_id?.value == "all") {
            let parametros = this.state.parametros_selecionados?.filter(c => c?.value != "all" && !(c?.isDisabled))
            let requestArray = []

            if (parametros.length == 0) return

            parametros.forEach(parametro => {
                let bodyFormData = new FormData()
                bodyFormData.append('monitorado_id', this.props.match.params.id)
                bodyFormData.append('campo_id', parametro?.value)
                requestArray.push(axios.post(url, bodyFormData, config_me))
            })

            axios.all(requestArray).then(axios.spread((...results) => {
                let filter = `?monitorado=${this.props.match.params.id}`

                axios.get(url + filter, config_me).then((resp) => {
                    let parametro = { "equipamento_parametro_id": "", "parametro_id": "" }
                    let selecionados = this.state.optionSelected
                    selecionados['equipamento_parametro_id'] = {}
                    selecionados['parametro_id'] = {}
                    this.setState({ "parametro": parametro, "parametros_vinculados": resp.data.results, "optionSelected": selecionados })
                })
            }))
        } else {
            let bodyFormData = new FormData()
            bodyFormData.append('monitorado_id', this.props.match.params.id)
            bodyFormData.append('campo_id', this.state.parametro?.parametro_id)

            axios({
                method: "post",
                url: url,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            }).then((response) => {
                let filter = `?monitorado=${this.props.match.params.id}`

                axios.get(url + filter, config_me).then((resp) => {
                    let parametro = { "equipamento_parametro_id": "", "parametro_id": "" }
                    let selecionados = this.state.optionSelected
                    selecionados['equipamento_parametro_id'] = {}
                    selecionados['parametro_id'] = {}
                    this.setState({ "parametro": parametro, "parametros_vinculados": resp.data.results, "optionSelected": selecionados })
                })
            }).catch((error) => {
                console.error(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })
        }
    }

    addNovoComando = (event) => {
        event.preventDefault()
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-comando/'
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        if (!this.state.comando?.comando_id) return

        if (this.state.optionSelected.comando_id?.value == "all") {
            let comandos = this.state.comandos_selecionados?.filter(c => c?.value != "all" && !(c?.isDisabled))
            let requestArray = []

            if (comandos.length == 0) return

            comandos.forEach(comando => {
                let bodyFormData = new FormData()
                bodyFormData.append('monitorado_id', this.props.match.params.id)
                bodyFormData.append('comando_id', comando?.value)
                requestArray.push(axios.post(url, bodyFormData, config_me))
            })

            axios.all(requestArray).then(axios.spread((...results) => {
                let filter = `?monitorado=${this.props.match.params.id}`

                axios.get(url + filter, config_me).then((resp) => {
                    let comando = { "equipamento_comando_id": "", "comando_id": "" }
                    let selecionados = this.state.optionSelected
                    selecionados['equipamento_comando_id'] = {}
                    selecionados['comando_id'] = {}
                    this.setState({ "comando": comando, "comandos_vinculados": resp.data.results, "optionSelected": selecionados })
                })
            }))
        } else {
            let bodyFormData = new FormData()
            bodyFormData.append('monitorado_id', this.props.match.params.id)
            bodyFormData.append('comando_id', this.state.comando?.comando_id)

            axios({
                method: "post",
                url: url,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            }).then((response) => {
                let filter = `?monitorado=${this.props.match.params.id}`

                axios.get(url + filter, config_me).then((resp) => {
                    let comando = { "equipamento_comando_id": "", "comando_id": "" }
                    let selecionados = this.state.optionSelected
                    selecionados['equipamento_comando_id'] = {}
                    selecionados['comando_id'] = {}
                    this.setState({ "comando": comando, "comandos_vinculados": resp.data.results, "optionSelected": selecionados })
                })
            }).catch((error) => {
                console.error(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })
        }
    }

    removeItem = (id, resource) => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        requestArray.push(axios.delete(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/' + resource + '/' + id + '/', config_me))
        let state = this.state
        let filter = `?monitorado=${this.props.match.params.id}`

        axios.all(requestArray).then(axios.spread((acct, perms) => {
            const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/' + resource + '/' + filter
            let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

            let state_update = ""
            switch (resource) {
                case 'monitorado-comando':
                    state_update = 'comandos_vinculados'
                    break
                case 'monitorado-foto':
                    state_update = 'fotos_vinculados'
                    break
                case 'monitorado-parametro':
                    state_update = 'parametros_vinculados'
                    break
                default:
                    break
            }

            axios.get(requestURL, config_me).then((response) => {
                state[state_update] = (response.status == 200) ? response.data.results : []
                this.setState(state)
            })
        }))
    }

    handleImgPreview = (e) => {
        try {
            let obj = this.state.foto_file

            if (e.target?.files) {
                const sizeInMB = (e.target.files[0]?.size / 1.049e+6).toFixed(2)

                if (sizeInMB >= 5) {
                    helper.dispatchEvent("showAviso", {
                        message: "Imagem muito grande. Por favor, escolha uma imagem menor.",
                        callback: () => this.setState({ cooldown: false })
                    })

                    return
                }

                obj[e.target.id] = e.target.files[0]
                this.setState({ "foto_file": obj, foto_temp: URL.createObjectURL(e.target.files[0]) })
            } else {
                obj[e.target.id] = e.target.value
                this.setState({ "foto_file": obj })
            }
        } catch (err) {
            console.error(err)
            helper.dispatchEvent("showAviso", {
                message: "Erro ao fazer upload.",
                callback: () => this.setState({ cooldown: false })
            })
        }
    }

    addNovoFoto = (event) => {
        let { foto_file } = this.state
        event.preventDefault()

        document.querySelector("#validacao-foto").innerHTML = !foto_file.foto_file ? "Adicione uma imagem" : ""
        document.querySelector("#validacao-descricao").innerHTML = !foto_file.foto_descricao ? "Adicione uma descrição" : ""

        if (!foto_file.foto_descricao || !foto_file.foto_file) return

        if (this.props.match.params.id) {
            let userInfo = auth.getUserInfo()
            let bodyFormData = new FormData()

            bodyFormData.append('monitorado_id', this.props.match.params.id)
            bodyFormData.append('foto', this.state.foto_file.foto_file)
            bodyFormData.append('descricao', this.state.foto_file.foto_descricao)

            axios({
                method: "post",
                url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-foto/',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then((response) => {
                    let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
                    let filter = `?monitorado=${this.props.match.params.id}`

                    axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado-foto/' + filter, config_me)
                        .then((resp) => {
                            let foto = { "foto_descricao": "", "foto_file": undefined }
                            this.setState({
                                "foto_file": foto,
                                "fotos_vinculados": resp.data.results,
                                "foto_temp": "https://rs485project.s3.amazonaws.com/sem-imagem-10.jpg"
                            })
                        })
                })
                .catch((error) => {
                    console.error(error)
                    if (error.response.status === 413) {
                        helper.dispatchEvent("showAviso", {
                            message: "Imagem muito grande. Por favor, escolha uma imagem menor.",
                            callback: () => this.setState({ cooldown: false })
                        })
                    } else {
                        let erros = error.response.data
                        if (Array.isArray(erros)) {
                            for (let i = 0; i < erros.length; i++) {
                                let span = document.querySelector(`#validacao-${erros[i][0]}`)
                                if (span) span.innerHTML = erros[i][1]
                            }
                        }
                    }
                })
        }
    }

    setAddress = (position) => {
        let lat = Number(position[0]).toFixed(6)
        let lng = Number(position[1]).toFixed(6)

        document.querySelector("#validacao-point").innerHTML = ""
        this.setState({ address: `${lat} ${lng}`, location: [lat, lng] })
    }

    searchAddress = async () => {
        try {
            const { address } = this.state
            const url = `https://nominatim.openstreetmap.org/search?q=${address}&format=jsonv2`

            const markers = [...this.state.markers]

            this.setState({ markers: [] })

            await axios.get(url).then(res => {
                if (!Array.isArray(res.data) || res.data?.length == 0) throw new Error()

                const { lat, lon } = res.data?.[0]
                const position = [lat, lon]
                this.mapRef.current.setView(position, 16)

                let marker = [{
                    latitude: parseFloat(lat),
                    longitude: parseFloat(lon),
                    draggable: true,
                    iconElement: markers[0]['iconElement']
                }]

                this.setAddress(position)
                this.setState({ markers: marker })
            })
                .catch((error) => {
                    this.setState({ markers: markers })
                })
        } catch (error) {

        }
    }

    createDivIcon = (monitorado) => {
        return divIcon({
            className: 'default-div-icon',
            iconSize: [32, 64],
            iconAnchor: [16, 64],
            popupAnchor: [0, -64],
            html: ReactDOMServer.renderToString(
                <div className="default-marker-container" style={{ backgroundColor: monitorado.cor }} title={monitorado?.nome}>
                    <i className={monitorado.icone + " la-2x"}></i>
                    <div className="default-marker-gradient"></div>
                    <div style={{ backgroundColor: monitorado.cor }} className="default-marker-triangle"></div>
                </div>
            ),
        })
    }

    getTipos = () => {
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/monitorado-tipo/', config_me)
            .then((tipos) => {
                let optionsTipos = []

                tipos.data.forEach((a) => optionsTipos.push({ "value": a.id, "label": a.nome, "cor": a.cor, "icone": a.icone }))

                this.setState({ tipos: optionsTipos })
            })
    }

    updateMarker = (data) => {
        if (this.state.markers.length > 0) {
            const markers = [...this.state.markers]
            markers[0].iconElement = this.createDivIcon(data)
            this.setState({ markers })
        }
    }

    handleTab = (index) => {
        this.setState({ tab: index })
    }


    render() {
        const selectedTipo = this.state.optionSelected.tipo
        const selectedCliente = this.state.optionSelected.cliente
        const selectedEquipamentoParamentro = this.state.optionSelected.equipamento_parametro_id
        const selectedEquipamentoComando = this.state.optionSelected.equipamento_comando_id
        const selectedParametroSelecionado = this.state.optionSelected.parametro_id
        const selectedComandoSelecionado = this.state.optionSelected.comando_id
        const fromTelaStatus = this.state.fromTelaStatus
        const toAddFotos = this.state.toAddFotos

        if (this.state.redirect === true) {
            return <Redirect to={{
                pathname: '/redirect',
                state: { pathto: this.state.path }
            }}
            />
        }

        return (
            <div>
                <ModalImage
                    open={this.state.open_image}
                    images={[{ foto: this.state.image }]}
                    toggle={() => this.setState({ open_image: false, image: "" })}
                />

                <ModalTipo
                    status={this.state.statusModalTipo}
                    toggleModal={(status) => this.setState({ statusModalTipo: status })}
                    updateTipos={this.getTipos}
                    detalhes={this.state.detalhes}
                    setDetalhes={(data) => this.setState({ detalhes: data })}
                    updateMarker={(data) => this.updateMarker(data)}
                    optionSelected={this.state.optionSelected}
                    setOptionSelected={(options) => this.setState({ optionSelected: options })}
                />

                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            {this.props.match.params.id ? <h4>Editar Monitorado</h4> : <h4>Novo Monitorado</h4>}
                            <div className="gray-background">
                                <i className="fa fa-map-marked-alt fa-2x"></i>
                            </div>
                        </span>
                    </div>
                </div>

                <Tabs className="tabs-style" selectedIndex={this.state.tab} selectedId onSelect={(i) => this.handleTab(i)}>
                    <TabList>
                        <Tab>Detalhes do Monitorado</Tab>
                        {this.userInfo.tipo !== "cliente" ? <Tab disabled={(this.props.match.params.id) ? false : true}>Parâmetros</Tab> : null}
                        <Tab disabled={(this.props.match.params.id) ? false : true}>Condições</Tab>
                        {this.userInfo.tipo !== "cliente" ? <Tab disabled={(this.props.match.params.id) ? false : true}>Comandos</Tab> : null}
                        <Tab disabled={(this.props.match.params.id) ? false : true}>Fotos</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="required" htmlFor="nome">Nome do Monitorado</label>
                                    <input type="text" name="nome" id="nome" value={this.state.detalhes.nome} onChange={this.handleChange} />
                                    <span id="validacao-nome"></span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <label className="required" htmlFor="cliente">Cliente</label>
                                    <Select
                                        value={selectedCliente}
                                        disabled={auth.isClient()}
                                        onChange={(e) => this.handleChangeSelectCliente(e, 'cliente')}
                                        options={this.state.clientes}
                                    />
                                    <span id="validacao-cliente"></span>
                                </div>
                                <div className="col-md-5">
                                    <label className="required" htmlFor="tipo">Tipo</label>
                                    <Select
                                        value={selectedTipo}
                                        onChange={(e) => this.handleChangeSelect(e, 'tipo')}
                                        options={this.state.tipos}
                                    />
                                    <span id="validacao-tipo"></span>
                                </div>
                                <div className="col-md-1" style={{ display: "flex", alignItems: "end", justifyContent: "end", marginBottom: "5px" }}>
                                    <button
                                        className="hdv-btn-forms hdv-btn-green d-flex justify-content-center"
                                        style={{ margin: "0px", width: "100%", marginBottom: "2px" }}
                                        onClick={() => this.setState({ statusModalTipo: true })}>
                                        <i className="fa fa-plus fa-2x"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <label className="required" htmlFor="nome">Selecionar Ponto no Mapa</label>
                                    <input type="text" name="address" id="address"
                                        value={this.state.address}
                                        onChange={this.handleAddress}
                                        onKeyDown={(e) => e.key == "Enter" ? this.searchAddress() : null}
                                    />
                                    <span id="validacao-point"></span>
                                </div>
                            </div>

                            <div className="hdv-mapform">
                                <Mapa
                                    draggable
                                    position={this.state.position}
                                    markers={this.state.markers}
                                    layerControl={true}
                                    onClick={this.setAddress}
                                    zoom={4}
                                    style={{ height: "400px", top: "0" }}
                                    onLoad={(ref) => { this.mapRef = ref }}
                                />
                            </div>
                        </div>
                    </TabPanel>

                    {this.userInfo.tipo !== "cliente" ?
                        <TabPanel>
                            <form className="hdv-form-default">
                                <div className="row">
                                    <div className="col-md-5">
                                        <label className="required" htmlFor="equipamento">Equipamento</label>
                                        <Select
                                            value={selectedEquipamentoParamentro}
                                            onChange={(e) => this.handleChangeSelectEquipamentosParametros(e, 'equipamento_parametro_id')}
                                            options={this.state.equipamentos}
                                        />
                                        <span id="validacao-equipamento"></span>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="required" htmlFor="parametro_id">Parâmetro</label>
                                        <Select
                                            value={selectedParametroSelecionado}
                                            onChange={(e) => this.handleChangeSelectParametros(e, 'parametro_id')}
                                            options={this.state.parametros_selecionados}
                                        />
                                    </div>
                                    <div className="col-md-3 flex-col">
                                        <button
                                            style={{ marginBottom: "7px", width: "100%" }}
                                            className="hdv-btn-forms hdv-btn-blue btn-inline-resolve"
                                            onClick={this.addNovoParametro} >
                                            <i className="fa fa-plus-o"></i>
                                            Vincular Parâmetro
                                        </button>
                                    </div>
                                </div>
                            </form>

                            <h6 className="hdv-subtable-title">Lista de Parâmetros Vinculados</h6>
                            <table className="table afira-table">
                                <thead>
                                    <tr>
                                        <th>Equipamento</th>
                                        <th>Parâmetro</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.createRows("parametros_vinculados", "campo.equipamento.imei", "campo.descricao", "monitorado-parametro", {
                                        link: `/equipamento/[0]/editar`, field: "campo.equipamento.id"
                                    })}
                                </tbody>
                            </table>
                        </TabPanel>
                        : null
                    }

                    <TabPanel>
                        <ModalCondicoes
                            getCondicoes={this.getCondicoes}
                            status={this.state.statusModalCondicoes}
                            toggleModal={(status) => this.setState({ statusModalCondicoes: status })}
                            parametros={this.state.parametros_vinculados.map((parametro) => {
                                let item = {
                                    label: parametro.campo.descricao,
                                    value: parametro.campo.id,
                                    tipo_dado_saida: parametro.campo.tipo_dado_saida,
                                    identificacao: parametro.campo.identificacao,
                                    usuario_mqtt: parametro.campo.equipamento.usuario_mqtt
                                }

                                return item
                            })}
                            monitoradoId={this.props.match.params.id}
                        />
                    </TabPanel>

                    {this.userInfo.tipo !== "cliente" ?
                        <TabPanel>
                            <form className="hdv-form-default">
                                <div className="row">
                                    <div className="col-md-5">
                                        <label className="required" htmlFor="equipamento">Equipamento</label>
                                        <Select
                                            value={selectedEquipamentoComando}
                                            onChange={(e) => this.handleChangeSelectEquipamentosComandos(e, 'equipamento_comando_id')}
                                            options={this.state.equipamentos}
                                        />
                                        <span id="validacao-equipamento"></span>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="required" htmlFor="entrada_tipo">Comando</label>
                                        <Select
                                            value={selectedComandoSelecionado}
                                            onChange={(e) => this.handleChangeSelectComandos(e, 'comando_id')}
                                            options={this.state.comandos_selecionados}
                                        />
                                    </div>
                                    <div className="col-md-3 flex-col">
                                        <button
                                            style={{ marginBottom: "7px", width: "100%" }}
                                            className="hdv-btn-forms hdv-btn-blue btn-inline-resolve"
                                            onClick={this.addNovoComando} >
                                            <i className="fa fa-plus-o"></i>
                                            Vincular Comando
                                        </button>
                                    </div>
                                </div>
                            </form>

                            <h6 className="hdv-subtable-title">Lista de Comandos Vinculados</h6>
                            <table className="table afira-table">
                                <thead>
                                    <tr>
                                        <th>Equipamento</th>
                                        <th>Comando</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.createRows("comandos_vinculados", "comando.equipamento.imei", "comando.descricao", "monitorado-comando", {
                                        link: `/equipamento/[0]/editar`, field: "comando.equipamento.id"
                                    })}
                                </tbody>
                            </table>
                        </TabPanel>
                        : null
                    }
                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-2">
                                    <img className="img-preview-photo" src={this.state.foto_temp} />
                                </div>
                                <div className="col-md-3">
                                    <div className="hdv-file-picker">
                                        <label className="required" htmlFor="foto_file">Foto</label>
                                        <input type="file" accept=".jpg,.jpeg,.png,.webp" id="foto_file" onChange={this.handleImgPreview} />
                                        <label className="m-0" htmlFor="foto_file" style={{ width: "100%" }}>Selecionar arquivo</label>
                                        <span id="validacao-foto"></span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label className="required" htmlFor="foto_descricao">Descrição</label>
                                    <input onChange={this.handleImgPreview} value={this.state.foto_file.foto_descricao} type="text" id="foto_descricao" />
                                    <span id="validacao-descricao"></span>
                                </div>
                                <div className="col-md-3 flex-col">
                                    <button
                                        style={{ marginBottom: "17px", width: "100%" }}
                                        className="hdv-btn-forms hdv-btn-blue btn-inline-resolve"
                                        onClick={this.addNovoFoto} >
                                        <i className="fa fa-plus-o"></i>
                                        Adicionar Foto
                                    </button>
                                </div>
                            </div>
                        </form>

                        <h6 className="hdv-subtable-title">Lista de Fotos Cadastrados</h6>
                        <table className="table afira-table">
                            <thead>
                                <tr>
                                    <th>Foto</th>
                                    <th>Descrição</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.createRows("fotos_vinculados", "foto", "descricao", "monitorado-foto")}
                            </tbody>
                        </table>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <ButtonsForm
                        route="/monitorado"
                        edit={this.props.match.params.edit ? true : false}
                        cooldown={this.state.cooldown}
                        buttons={[
                            {
                                method: "post",
                                button: <button onClick={() => this.saveAction(false, true)} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                            }, {
                                method: "post",
                                button: <button style={{ display: this.props.match.params.id ? "none" : "" }} onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-greenlight">Salvar e Cadastrar um Novo</button>
                            }, {
                                method: "patch",
                                button: <button onClick={() => this.saveAction(false, true)} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                            },
                        ]}
                    />
                    <button
                        onClick={() => this.setState({ redirect: true, path: fromTelaStatus ? `/status/${this.props.match.params.id}` : "/monitorado" || toAddFotos ? "/lista-monitorados" : "/monitorado" })}
                        className="hdv-btn-forms hdv-btn-orange"
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        )
    }
}

export default Form