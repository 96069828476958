import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ModalIcone from "../../components/ModalIcone";
import helper from "../../utils/helper";
import axios from "axios";
import cfg from '../../utils/config'
import auth from "../../utils/auth";

class ModalTipo extends Component {
    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        detalhes: { descricao: null, icone: null, cor: null },
        validacao: { descricao: true },
        modalIcone: false,
        data: [],
        keyEdit: false, 
        cooldown: false
    }

    componentDidMount () {
        this.getData()
    }

    getData = () => {
        const url = cfg.base_api_url + cfg.api_v2 + "/" + this.userInfo.empresa  + "/monitorado/tipo/"
        axios.get(url, this.configMe)
        .then((res) => {
            this.setState({data: res.data.results})
        })
    }

    handleChange = (field, value) => {
        const detalhes = Object.assign({}, this.state.detalhes)
        detalhes[field] = field === "icone" ? `las la-${value}` : value
        this.setState({detalhes})
    
        if(field === "icone") {
            this.props.toggleModal(true)
            this.setState({modalIcone: false})
        }
    }

    add = () => {
        this.setState({cooldown: true})

        const {detalhes, keyEdit} = this.state
        const validacao = Object.assign({}, this.state.validacao)

        validacao["descricao"] = detalhes.descricao === null || detalhes.descricao === "" ? false : true

        this.setState({ validacao })

        if(!validacao.descricao) {
            this.setState({cooldown: false})
            return
        }

        let verb = "POST"
        let url = cfg.base_api_url + cfg.api_v2 + "/" + this.userInfo.empresa  + "/monitorado/tipo/"

        if(keyEdit !== false) {
            verb = "PATCH"
            url = cfg.base_api_url + cfg.api_v2 + "/" + this.userInfo.empresa  + `/monitorado/tipo/${keyEdit}/`
        }

        const data = {
            nome: detalhes.descricao
        }

        if(detalhes.icone !== null) data["icone"] = detalhes.icone
        if(detalhes.cor !== null) data["cor"] = detalhes.cor 

        axios({
            method: verb,
            url: url,
            data: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((res) => {
            this.setState({ 
                detalhes: { descricao: null, icone: null, cor: null },
                keyEdit: false 
            })

            this.getData()
            this.props.updateTipos()

            if(res.data.id === this.props.detalhes.tipo.value) {
                const detalhesProps = Object.assign({}, this.props.detalhes)

                detalhesProps["tipo"] = {
                    cor: res.data.cor,
                    icone: res.data.icone,
                    label: res.data.nome,
                    value: res.data.id
                }

                this.props.setDetalhes(detalhesProps)
                this.props.updateMarker(detalhesProps["tipo"])
            }
        })
        .catch((error) => {
            console.log(error)
        })
        
        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    edit = (keyEdit) => {
        const data = this.state.data.filter((item) => item.id === keyEdit)
        
        if(data.length > 0) {

            const detalhes = {
                descricao: data[0].nome,
                icone: data[0].icone,
                cor: data[0].cor
            }

            this.setState({ keyEdit, detalhes })
        }
    }

    delete = (keyDelete) => {
        this.setState({cooldown: true})
        
        const url = cfg.base_api_url + cfg.api_v2 + "/" + this.userInfo.empresa  + `/monitorado/tipo/${keyDelete}/`
        axios.delete(url, this.configMe)
        .then((res) => {
            this.getData()
            this.props.updateTipos()

            if(keyDelete === this.props.detalhes.tipo.value) {
                const detalhesProps = Object.assign({}, this.props.detalhes)
                const optionSelectedProps = Object.assign({}, this.props.optionSelected)

                detalhesProps["tipo"] = ""
                optionSelectedProps["tipo"] = {}

                this.props.setDetalhes(detalhesProps)
                this.props.setOptionSelected(optionSelectedProps)
                this.props.updateMarker({cor: "#0062b1", icone: "las la-cloud", nome: ""})
            }

            this.setState({cooldown: false})
        })
        .catch((error) => {
            this.setState({cooldown: false})
        })
    }


    render() {
        const { detalhes, validacao, data, keyEdit, cooldown } = this.state

        return(
            <>
                <ModalIcone open={this.state.modalIcone} toggle={() => {
                    this.setState({ modalIcone: !this.state.modalIcone })
                    this.props.toggleModal(true)
                }}>
                    {helper.getIconList().map((icon, key) => <li key={key}><i className={"fa fa-" + icon} onClick={() => this.handleChange("icone", icon)}></i></li>)}
                </ModalIcone>

                <Modal className='modal-md' isOpen={this.props.status} toggle={() => {
                    this.props.toggleModal(false)
                    this.setState({ 
                        detalhes: { descricao: null, icone: null, cor: null }, 
                        validacao: { descricao: true },
                        keyEdit: false 
                    })
                }}>
                    <ModalHeader toggle={() => {
                        this.props.toggleModal(false)
                        this.setState({ 
                            detalhes: { descricao: null, icone: null, cor: null }, 
                            validacao: { descricao: true },
                            keyEdit: false 
                        })
                    }}>
                        Tipos
                    </ModalHeader>
                    <ModalBody>
                        <div className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-5">
                                    <label className="required">Descrição do Tipo</label>
                                    <input 
                                        type="text" 
                                        value={detalhes.descricao ?? ""}
                                        onChange={(e) => this.handleChange("descricao", e.target.value)}
                                    />
                                    { !validacao.descricao ? <span id="validacao-descricao">Campo não pode ser nulo!</span> : null}
                                </div>
                                <div className="col-md-2">
                                    <label className="required">Icone Marker</label>
                                    <div 
                                        style={{height: "44px", width: "100%", backgroundColor: "#efefef", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center"}}
                                        onClick={() => {
                                            this.setState({ modalIcone: true })
                                            this.props.toggleModal(false)
                                        }}
                                    >
                                        <i className={ detalhes.icone === null ? "las la-cloud la-2x" : `${detalhes.icone} la-2x`}></i>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <label className="required">Cor Marker</label>
                                    <input 
                                        type="color" 
                                        style={{height: "44px", width: "100%", borderStyle: "none"}}
                                        value={detalhes.cor ?? "#0062b1"} 
                                        onChange={(e) => this.handleChange("cor", e.target.value)}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <button className="hdv-btn-forms hdv-btn-green" style={{width: "100%", margin: "45px 0px 0px 0px"}} onClick={this.add} disabled={cooldown} >{keyEdit !== false ? "Atualizar" : "Adicionar"}</button>
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <table className="table afira-table" style={{marginTop: "15px"}}>
                            <thead>
                                <tr>
                                    <th>Descrição</th>
                                    <th>Icone</th>
                                    <th>Cor</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((item, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{item.nome}</td>
                                                <td><i className={`${item.icone} la-2x`}></i></td>
                                                <td><div style={{width: "40px", height: "40px", backgroundColor: item.cor, borderRadius: "10px"}}></div></td>
                                                <td>
                                                    <div style={{display: "flex", justifyContent: "center"}}>
                                                        <button className="hdv-btn-forms hdv-btn-yellow" disabled={keyEdit !== false || cooldown} onClick={() => this.edit(item.id)}>Editar</button>
                                                        <button className="hdv-btn-forms hdv-btn-red" disabled={keyEdit !== false || cooldown} onClick={() => this.delete(item.id)}>Excluir</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default ModalTipo