import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Stepper from '../../components/Stepper'
import ButtonsForm from '../../components/ButtonsForm'
import TimePicker from '../../components/TimePicker'
import CardComando from '../../components/CardComando'
import ContatoAlerta from './components/ContatoAlerta'
import Simulacao from './components/Simulacao'
import Select from '../../components/Select'
import { components } from 'react-select'
import helper from '../../utils/helper'
import { compile } from 'mathjs'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'

class Form extends Component {
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
    userInfo = auth.getUserInfo()
    
    state = {
        detalhes: {
            ativo: "1",
            ordem: 0,
            descricao: "",
            tolerancia: "00:00",
            dias_da_semana: { "dom": false, "seg": false, "ter": false, "qua": false, "qui": false, "sex": false, "sab": false },
        },
        dias_personalizados: false,
        dias_pre_definido_cond: "",
        tabIndex: 0,
        acoes: [],
        condicoes: [],
        periodos: [],
        erros: [],
        monitorados: [],
        equipamentos: [],
        automacoes: [],
        condicoes_automacoes: [],
        comandosMonitorado: [],
        parametrosMonitorado: [],
        options: {
            automacoes: [],
            monitorados: [],
            equipamentos: [],
            parametros: [],
            comandos: [],
            clientes: [],
            acoes: [
                { label: "Enviar Notificação", value: "alerta" },
                { label: "Executar Comando", value: "comando" },
                { label: "Requisição HTTP", value: "http" },
            ],
            metodos: [{ label: "POST", value: "post" }, { label: "GET", value: "get" },]
        },
        operadores: [
            { label: "Igual (=)", value: "igual", symbol: "=" },
            { label: "Maior ou Igual (>=)", value: "maior_igual", symbol: ">=" },
            { label: "Menor ou Igual (<=)", value: "menor_igual", symbol: "<=" },
            { label: "Maior (>)", value: "maior", symbol: ">" },
            { label: "Menor (<)", value: "menor", symbol: "<" },
            { label: "Diferente (!=)", value: "diferente", symbol: "!=" },
        ],
    }

    getMonitorados = async () => {
        let { options } = this.state
        options["monitorados"] = []
        let monitorados = []

        await axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/monitorado/', this.configMe).then(res => {
            monitorados = res.data
            let monitoradoOptions = []

            res.data?.results?.forEach(monitorado => {
                monitoradoOptions.push({ value: monitorado.id, label: monitorado.nome })
            })

            options["monitorados"] = monitoradoOptions
        })

        this.setState({ monitorados, options })
    }

    componentDidMount = async () => {
        await this.getEquipamentos()
        this.getMonitorados()
        this.getAutomacoes()

        if (this.props.match.params.id) {
            this.getData()
        }
    }

    timeFormat = (duration) => {
        let hrs = ~~(duration / 3600)
        let mins = ~~((duration % 3600) / 60)
        let secs = ~~duration % 60
        let result = ""

        if (mins < 10) {
            mins = "0" + mins
        }

        if (hrs > 0) {
            result += "" + hrs + ":" + (mins < 10 ? "0" : "")
        }

        result += "" + mins + ":" + (secs < 10 ? "0" : "")
        result += "" + secs

        return result
    }

    buscarParametrosMonitorado = async (monitorado_id) => {
        let { options } = this.state
        let parametrosMonitorado = []
        let parametros = []

        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + `/monitorado-parametro/?monitorado=${monitorado_id}`

        await axios.get(requestURL, this.configMe).then(res => {
            if (Array.isArray(res.data?.results)) {
                parametrosMonitorado = res.data

                res.data?.results?.forEach(param => parametros.push({
                    label: param.campo.descricao,
                    tipo_saida: param.campo.tipo_dado_saida,
                    identificacao: param.campo.identificacao,
                    id_dispositivo: param.campo.equipamento.usuario_mqtt,
                    equipamento: param.campo.equipamento.id,
                    value: param.campo.id
                }))
            }
        }).catch(console.error)

        options["parametros"] = parametros
        this.setState({ options, parametrosMonitorado })
    }

    buscarComandosMonitorado = (monitorado_id) => {
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + `/monitorado-comando/?monitorado=${monitorado_id}`

        axios.get(requestURL, this.configMe).then(res => {
            let { options } = this.state
            let comandosMonitorado = []
            options["comandos"] = []

            res.data?.results?.forEach((c) => {
                options["comandos"].push({
                    label: c.comando.descricao,
                    value: c.comando.id,
                    monitorado: c.monitorado,
                    ...c.comando
                })

                comandosMonitorado.push(c.comando)
            })

            this.setState({ options, comandosMonitorado })
        }).catch(console.error)
    }

    getData = () => {
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/automacao/' + this.props.match.params.id + '/'

        axios.get(requestURL, this.configMe).then((res) => {
            let { options } = this.state
            let detalhes = {}

            try {
                let equipamentos = []

                if (typeof res.data?.equipamentos == "string") {
                    equipamentos = JSON.parse(res.data?.equipamentos)
                } else {
                    equipamentos = res.data?.equipamentos
                }

                options["equipamentos"] = this.state.equipamentos?.map((e) => {
                    if (equipamentos.includes(e.id)) {
                        return new Object({ "label": e.imei, "value": e.id })
                    }
                })?.filter(e => e != undefined)
            } catch (err) {
                options["equipamentos"] = []
            }

            detalhes['ativo'] = (res.data.ativo === true) ? 1 : 0
            detalhes['dias_da_semana'] = JSON.parse(res.data.dias_da_semana)
            detalhes['descricao'] = res.data.descricao
            detalhes['tolerancia'] = this.timeFormat(res.data.tolerancia)
            detalhes['ordem'] = res.data?.ordem ?? 0

            let periodos = (res.data.periodos) ? JSON.parse(res.data.periodos) : []
            let pre_definido = ""
            let dias_personalizados = false

            if (JSON.stringify(res.data.dias_da_semana) === JSON.stringify({ "dom": true, "seg": true, "ter": true, "qua": true, "qui": true, "sex": true, "sab": true })) {
                pre_definido = "todos_os_dias"
            } else if (JSON.stringify(res.data.dias_da_semana) === JSON.stringify({ "dom": true, "seg": false, "ter": false, "qua": false, "qui": false, "sex": false, "sab": true })) {
                pre_definido = "finais_semana"
            } else if (JSON.stringify(res.data.dias_da_semana) === JSON.stringify({ "dom": false, "seg": true, "ter": true, "qua": true, "qui": true, "sex": true, "sab": false })) {
                pre_definido = "seg_sex"
            } else if (JSON.stringify(res.data.dias_da_semana) === JSON.stringify({ "dom": false, "seg": true, "ter": true, "qua": true, "qui": true, "sex": true, "sab": true })) {
                pre_definido = "seg_sab"
            } else {
                dias_personalizados = true
            }

            let condicoes = (res.data.condicao) ? JSON.parse(res.data.condicao) : []
            let condicoes_automacoes = (res.data.condicao_automacao) ? JSON.parse(res.data.condicao_automacao) : []
            let acoes = (res.data.acoes) ? JSON.parse(res.data.acoes) : []

            acoes?.forEach?.(acao => {
                if (typeof acao?.monitorado === "number") {
                    acao.monitorado = this.state.options.monitorados.find(m => m.value === acao.monitorado)
                }

                if (acao?.tipo == "http") {
                    acao.metodo = options.metodos?.find((m) => m.value === acao?.metodo)
                }
            })

            this.setState({
                acoes, periodos, detalhes, condicoes, condicoes_automacoes, options,
                dias_pre_definido_cond: pre_definido, dias_personalizados,
            })
        })
    }

    handleChange = (e) => {
        let { detalhes, erros } = this.state

        if (e?.target?.type === "checkbox") {
            detalhes[e.target.id] = e.target.checked
        } else {
            detalhes[e.target.id] = e.target.value
        }

        if (document.querySelector(`#validacao-${e.target.name}`)) {
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
        }

        erros = erros.filter(err => { return err !== 'descricao' })
        this.setState({ detalhes, erros })
    }

    saveAction = async () => {
        let { detalhes, condicoes, periodos, options } = this.state
        let requestURL, verb
        let bodyFormData = new FormData()

        if (this.state.acoes?.length === 0) {
            helper.dispatchEvent("showAviso", { message: "Adicione pelo menos 1 ação!" })

            return
        }

        this.setState({ cooldown: true })

        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/automacao/' + this.props.match.params.id + '/'
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/automacao/'
            verb = 'post'
        }

        for (let key in detalhes) {
            bodyFormData.append(key, key === "dias_da_semana" ? JSON.stringify(detalhes[key]) : detalhes[key])
        }

        if (periodos.length > 0) {
            bodyFormData.append('periodos', JSON.stringify(periodos))
        }

        if (condicoes.length > 0) {
            bodyFormData.append('condicao', JSON.stringify(this.state.condicoes))
        }

        if (this.state.condicoes_automacoes.length > 0) {
            let condicoes_automacoes = []

            this.state.condicoes_automacoes?.forEach((condicao) => {
                condicoes_automacoes.push({
                    monitorado: condicao['monitorado'],
                    automacao: condicao['automacao'],
                    condicao: condicao['condicao'],
                    valor: condicao['valor'],
                    tipo: condicao['tipo'],
                    execucoes_limite: condicao['execucoes_limite']
                })
            })

            bodyFormData.append('condicao_automacao', JSON.stringify(condicoes_automacoes))
        }

        if (this.state.acoes.length > 0) {
            let acoes = []

            this.state.acoes.forEach((acao) => {
                let common = { tipo: acao?.tipo, negativa: acao?.negativa ?? false }

                if (acao?.tipo === "alerta") {
                    acoes.push({ ...common, contatos: acao?.contatos })
                } else if (acao?.tipo === "comando") {
                    acoes.push({ ...common, comando: acao?.comando, monitorado: acao?.monitorado?.value, })
                } else if (acao?.tipo === "http") {
                    acoes.push({ ...common, metodo: acao?.metodo?.value, endereco: acao?.endereco, })
                }
            })

            bodyFormData.append('acoes', JSON.stringify(acoes))
        }

        let tolerancia = detalhes.tolerancia.split(":")
        let toleranciaCalc = Number(tolerancia[0]) * 60 + Number(tolerancia[1])
        bodyFormData.set("tolerancia", toleranciaCalc)

        let monitoradosId = []

        condicoes?.forEach(condicaoArray => {
            condicaoArray.forEach(c => {
                if (!monitoradosId.includes(c?.condicao?.monitorado?.value)) {
                    monitoradosId.push(c?.condicao?.monitorado?.value)
                }
            })
        })

        if (monitoradosId.length > 0) {
            bodyFormData.append('monitorados', JSON.stringify(monitoradosId))
        }

        if (options.equipamentos.length > 0) {
            const equipamentoIds = options.equipamentos?.map((e) => e.value)
            bodyFormData.append('equipamentos', JSON.stringify(equipamentoIds))
        }

        await axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(async (res) => {
            if (verb === "post") {
                const patchURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/automacao/' + res.data.id + '/'

                await axios({
                    method: "patch",
                    url: patchURL,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
                }).then((res) => {
                    helper.dispatchEvent("showAviso", {
                        message: "Automação criada com sucesso",
                        callback: this.props.history.goBack
                    })
                })
            } else {
                helper.dispatchEvent("showAviso", {
                    message: `Automação ${verb === "post" ? "criada" : "editada"} com sucesso`,
                    callback: this.props.history.goBack
                })
            }

            this.props.toggle(true)
        }).catch(error => {
            let erros = error.response?.data ?? {}

            Object.keys(erros)?.forEach((key) => {
                let span = document.querySelector(`#validacao-${key}`)
                if (span) span.innerHTML = erros[key]?.[0]
            })
        })

        this.setState({ cooldown: false })
    }

    getAutomacoes = async () => {
        await axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/automacao/', this.configMe).then((res) => {
            let { options } = this.state
            let automacoes = []

            res.data?.results?.forEach(automacao => {
                automacoes.push({ label: automacao.descricao, value: automacao.id })
            })

            options["automacoes"] = automacoes

            this.setState({ options, automacoes: res.data })
        })
    }

    getEquipamentos = async () => {
        let { options } = this.state
        let equipamentos = []

        await axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/equipamento/', this.configMe).then((res) => {
            res.data?.results?.forEach(equipamento => {
                equipamentos.push(equipamento)
            })

        }).catch(console.error)

        this.setState({ options, equipamentos })
    }

    handleChangeEquipamento = (values) => {
        let { options } = this.state
        options["equipamentos"] = values
        this.setState({ options })
    }

    addEquipamento = (id) => {
        let { equipamentos, options } = this.state
        let equipamentosOptions = options.equipamentos
        const find = equipamentos?.find((equipamento) => equipamento.id == id)
        const isInOptions = options.equipamentos?.findIndex((equipamento) => equipamento.value == id)

        if (find && (isInOptions == -1)) {
            equipamentosOptions.push({ label: find.imei, value: find.id })
            options["equipamentos"] = equipamentosOptions
            this.setState({ options })
        }
    }

    handleChangeCondicao = (e, campo, cIndex, index) => {
        let { condicoes } = this.state
        condicoes[cIndex][index]["condicao"][campo] = e

        if (campo === "campo") {
            if (condicoes[cIndex][index]["condicao"]["campo"]?.["tipo_saida"] === "booleano") {
                condicoes[cIndex][index]["condicao"]["condicao"] = this.state.operadores.find((op) => op.value === "igual")
                condicoes[cIndex][index]["condicao"]["valor"] = "1"
            } else {
                condicoes[cIndex][index]["condicao"]["valor"] = ""
            }

            this.addEquipamento(e?.equipamento)
        }

        this.setState({ condicoes })
    }

    handleChangeAcao = (value, field, index) => {
        let { acoes } = this.state

        if (field === "tipo") {
            acoes[index]['monitorado'] = ""
            acoes[index]['metodo'] = ""
            acoes[index]['comando'] = ""
        }

        acoes[index][field] = value
        this.setState({ acoes })
    }

    handleChangeCondicaoAutomation = (option, campo, index) => {
        let condicoes_automacoes = this.state.condicoes_automacoes
        let condicao_automacao = condicoes_automacoes[index]

        condicao_automacao[campo] = option.value
        condicoes_automacoes[index] = condicao_automacao

        this.setState({ condicoes_automacoes })
    }

    handleChangeCondicaoValue = (e, field, cIndex, index) => {
        let { condicoes } = this.state
        let condicao = condicoes[cIndex][index]["condicao"]
        condicao[field] = e.target.value
        condicoes[cIndex][index]["condicao"] = condicao
        this.setState({ condicoes })
    }

    filterAutomations = (e) => {
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + `/automacao/?monitorados__contains=${e.value}`

        axios.get(requestURL, this.configMe).then((res) => {
            let { options } = this.state
            let automacoes = []

            res.data?.results?.forEach(automacao => {
                automacoes.push({ label: automacao.descricao, value: automacao.id })
            })

            options["automacoes"] = automacoes

            this.setState({ options })
        })
    }

    formatar = (e, field, cIndex, index) => {
        let value = e.target.value.replace(/\D/g, '')
        value = (value / 100).toFixed(2) + ''
        value = value.replace(" ", ",")
        value = value.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2,$3,")
        value = value.replace(/(\d)(\d{3}),/g, "$1.$2,")

        let condicoes = this.state.condicoes
        let condicao = condicoes[cIndex][index]["condicao"]

        if (e.target.value.includes("-")) {
            value = "-" + value
        }

        condicao[field] = value
        condicoes[cIndex][index]["condicao"] = condicao
        this.setState({ condicoes })
    }

    handleChangePeriodo = (e, index) => {
        let { periodos } = this.state

        if (e.target.type === "checkbox") {
            periodos[index][e.target.name] = e.target.checked
        } else {
            periodos[index][e.target.name] = e.target.value
        }

        this.setState({ periodos: periodos })
    }

    createNewAction = (negativa = false) => {
        let { acoes } = this.state
        if (acoes === false) {
            acoes = []
        }

        acoes.push({ campo: "", condicao: "", valor: "", negativa: negativa })
        document.querySelector('#validacao-acao').innerHTML = ""
        let { erros } = this.state
        erros = erros.filter(err => { return err !== 'acao' })
        this.setState({ acoes, erros })
    }

    createNewCondition = () => {
        let { condicoes, erros } = this.state
        condicoes.push([{
            condicao: { campo: "", condicao: "", valor: "", monitorado: "" },
            simulacao: { campo: "", ultimo_reporte: null, value: "", resultado: null }
        }])

        document.querySelector('#validacao-condicao').innerHTML = ""
        erros = erros.filter(err => { return err !== 'condicao' })

        this.setState({ condicoes, erros })
    }

    createExtraCondition = (cIndex) => {
        let { condicoes } = this.state
        condicoes[cIndex].push({
            condicao: { campo: "", condicao: "", valor: "", monitorado: "" },
            simulacao: { campo: "", ultimo_reporte: null, value: "", resultado: null }
        })

        this.setState({ condicoes })
    }

    createNewConditionAutomation = () => {
        let { condicoes_automacoes, erros } = this.state

        if (condicoes_automacoes === false) {
            condicoes_automacoes = []
        }

        condicoes_automacoes.push({
            monitorado: "", automacao: "", condicao: "igual", valor: "1",
            tipo: "1", execucoes_limite: "1", automacoes: []
        })
        document.querySelector('#validacao-condicao-automation').innerHTML = ""
        erros = erros.filter(err => { return err !== 'condicao' })
        this.setState({ condicoes_automacoes, erros })
    }

    removeAction = (index) => {
        let acoes = this.state.acoes
        acoes.splice(index, 1)
        this.setState({ acoes: acoes })
    }

    removeCondition = (cIndex, index) => {
        let { condicoes } = this.state
        condicoes[cIndex]?.splice(index, 1)

        if (condicoes[cIndex].length === 0) {
            condicoes?.splice(cIndex, 1)
        }

        this.setState({ condicoes })
    }

    removePeriodo = (index) => {
        let periodos = this.state.periodos
        periodos.splice(index, 1)
        this.setState({ periodos: periodos })
    }

    removeConditionAutomation = (index) => {
        let condicoes_automacoes = this.state.condicoes_automacoes
        condicoes_automacoes.splice(index, 1)
        this.setState({ condicoes_automacoes: condicoes_automacoes })
    }

    createNewPeriodo = () => {
        let { erros, periodos } = this.state

        if (periodos === false) {
            periodos = []
        }

        periodos?.push({ horainicio: "00:00", horafim: "00:00", considerar_volume: false })
        erros = erros.filter(err => { return err !== 'periodo' })
        this.setState({ periodos, erros })
        if (document.querySelector('#validacao-periodo'))
            document.querySelector('#validacao-periodo').innerHTML = ""
    }

    selecionaDiasSemanaPreDefinido = (condicao) => {
        let detalhes = this.state.detalhes
        switch (condicao) {
            case 'todos_os_dias':
                detalhes.dias_da_semana = { "dom": true, "seg": true, "ter": true, "qua": true, "qui": true, "sex": true, "sab": true }
                break
            case 'finais_semana':
                detalhes.dias_da_semana = { "dom": true, "seg": false, "ter": false, "qua": false, "qui": false, "sex": false, "sab": true }
                break
            case 'seg_sex':
                detalhes.dias_da_semana = { "dom": false, "seg": true, "ter": true, "qua": true, "qui": true, "sex": true, "sab": false }
                break
            case 'seg_sab':
                detalhes.dias_da_semana = { "dom": false, "seg": true, "ter": true, "qua": true, "qui": true, "sex": true, "sab": true }
                break
            default:
                break
        }
        this.setState({ detalhes, dias_pre_definido_cond: condicao, dias_personalizados: false })
    }

    habilitaDiasPersonalizados = () => {
        let detalhes = this.state.detalhes
        detalhes.dias_da_semana = { "dom": false, "seg": false, "ter": false, "qua": false, "qui": false, "sex": false, "sab": false }

        this.setState({ detalhes, dias_pre_definido_cond: "", dias_personalizados: !this.state.dias_personalizados })
    }

    diasSemanaPersonalizado = (dia) => {
        if (!this.state.dias_personalizados) {
            return
        }

        let diaSelecionado = this.state.detalhes.dias_da_semana[dia]
        let detalhes = this.state.detalhes

        diaSelecionado = !diaSelecionado
        detalhes.dias_da_semana[dia] = diaSelecionado

        this.setState({ detalhes: detalhes })
    }

    handleChangeComandoValue = (field, value, acao, index) => {
        let { acoes } = this.state
        let values

        if (acao?.comando?.values) {
            values = acao?.comando?.values
        } else {
            values = {}
        }

        values[field] = value
        acao.comando.values = values
        acoes[index] = acao
        this.setState({ acoes })
    }

    renderTipoAcao(acao, index) {
        let { options } = this.state

        if (!acao) return null

        if (acao?.tipo === "alerta") {
            return (
                <div className="col-md-3">
                    <label>Contatos: {`${acao?.contatos?.length > 0 ? acao?.contatos?.length : ""}`}</label>
                    <ContatoAlerta
                        contatos={acao?.contatos}
                        onChange={(contatos) => {
                            let { acoes } = this.state
                            acao.contatos = contatos
                            acoes[index] = acao
                            this.setState({ acoes })
                        }}
                    />
                </div>
            )
        } else if (acao?.tipo === "http") {
            return (
                <>
                    <div className="col-md-4">
                        <label>Método: </label>
                        <Select
                            value={acao?.metodo}
                            onChange={(e) => this.handleChangeAcao(e, 'metodo', index)}
                            options={options.metodos}
                        />
                    </div>
                    <div className="col-md-4">
                        <label>Endereço: </label>
                        <input
                            type="text"
                            value={acao?.endereco}
                            onChange={(e) => this.handleChangeAcao(e.target.value, 'endereco', index)}
                        />
                    </div>
                </>
            )
        } else if (acao?.tipo === "comando") {
            let comando

            if (acao?.comando) {
                comando = acao.comando
            } else {
                comando = this.state.comandosMonitorado?.find?.(comando => acao?.comando?.value === comando.id)
            }

            if (comando && typeof comando?.parametros == "object") {
                try {
                    comando.parametros = JSON.stringify(comando.parametros)
                    acao.monitorado = options.monitorados.find(m => m.value === acao?.comando?.monitorado?.id)
                } catch (err) {
                    console.error(err)
                }
            }

            return (
                <>
                    <div className="col-md-4">
                        <label>Monitorado: </label>
                        <Select
                            value={acao?.monitorado}
                            options={options.monitorados}
                            noOptionsMessage={() => "Nenhum monitorado encontrado"}
                            onChange={(e) => {
                                this.handleChangeAcao(e, 'monitorado', index)
                                this.buscarComandosMonitorado(e.value)
                                this.handleChangeAcao("", "comando", index)
                            }}
                        />
                    </div>
                    <div className="col-md-4">
                        <label>Comando: </label>
                        <Select
                            value={acao?.comando}
                            onChange={(e) => this.handleChangeAcao(e, 'comando', index)}
                            options={options.comandos}
                            noOptionsMessage={() => "Nenhum comando encontrado"}
                        />
                    </div>

                    {(Boolean(comando) && Boolean(acao?.comando))
                        ?
                        <div className="col-md-4 mt-2">
                            <CardComando
                                compact
                                width={"100%"}
                                onChange={(field, value) => this.handleChangeComandoValue(field, value, acao, index)}
                                detalhes={{ imei: comando?.equipamento?.imei, id: comando?.equipamento?.id }}
                                comando={{
                                    id: comando?.id,
                                    descricao: comando?.descricao,
                                    parametros: JSON.parse(comando?.parametros),
                                    values: comando?.values
                                }}
                            />
                        </div>
                        :
                        null
                    }
                </>
            )
        }
    }

    handleChangeSimulacao = (value, field, cIndex, index) => {
        let { condicoes } = this.state
        condicoes[cIndex][index]["simulacao"][field] = value

        if (field === "campo") {
            let bodyFormData = new FormData()
            bodyFormData.append('id_dispositivo', value.equipamento)

            axios({
                method: 'POST',
                url: `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/reporte-ultimo-evento-calculado/`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            }).then(res => {
                const id = condicoes[cIndex][index]["simulacao"]?.campo?.identificacao

                condicoes[cIndex][index]["simulacao"]['ultimo_reporte'] = res.data
                if (condicoes[cIndex][index]["simulacao"]['campo']?.tipo_saida === "booleano") {
                    condicoes[cIndex][index]["simulacao"]['value'] = Boolean(res.data?.[id]) ? "1" : "0"
                } else {
                    condicoes[cIndex][index]["simulacao"]['value'] = res.data?.[id]
                }

                this.setState({ condicoes })
            }).catch(console.error)
        }

        this.setState({ condicoes })
    }

    simular = () => {
        let { condicoes } = this.state
        let results = []

        condicoes?.forEach((condicoes) => {
            if (condicoes.length === 1) {
                condicoes?.map((condicao) => {
                    try {
                        let label = condicao?.simulacao?.campo?.label
                        let symbol = condicao?.condicao?.condicao?.symbol
                        let condicaoValue = condicao?.condicao?.valor
                        let simulacaoValue = condicao?.simulacao?.value

                        if (symbol == "=") symbol = "=="

                        if (label && symbol && simulacaoValue) {
                            let result = compile(`${simulacaoValue} ${symbol} ${condicaoValue}`).evaluate()
                            condicao.simulacao.resultado = result
                            results.push(result)
                        } else {
                            results.push(false)
                        }
                    } catch (err) {
                        console.error(err)
                    }
                })
            } else {
                let _results = []

                condicoes?.map((condicao) => {
                    try {
                        let label = condicao?.simulacao?.campo?.label
                        let symbol = condicao?.condicao?.condicao?.symbol
                        let condicaoValue = condicao?.condicao?.valor
                        let simulacaoValue = condicao?.simulacao?.value

                        if (symbol == "=") symbol = "=="

                        if (label && symbol && simulacaoValue) {
                            let result = compile(`${simulacaoValue} ${symbol} ${condicaoValue} `).evaluate()
                            condicao.simulacao.resultado = result
                            _results.push(result)
                        } else {
                            _results.push(false)
                        }
                    } catch (err) {
                        console.error(err)
                    }
                })

                results.push(_results.some(r => r))
            }
        })

        if (results.every(r => r)) {
            helper.dispatchEvent("showAviso", {
                message: "Sucesso na simulação, a automação seria acionada com esses parâmetros",
                callback: null
            })
        } else {
            helper.dispatchEvent("showAviso", {
                message: "Falha na simulação, a automação não seria acionada com esses parâmetros",
                callback: null
            })
        }

        this.setState({ condicoes })
    }

    render() {
        const { options } = this.state
        const { dias_da_semana } = this.state.detalhes

        const MultiValueContainer = (props) => {
            return (<div title={`id: ${props.data.value}`}><components.MultiValueContainer {...props} /></div>)
        }

        return (
            <div style={{ marginBottom: "150px" }}>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        {this.props.match.params.id ? <h4>Editar Automação</h4> : <h4>Nova Automação</h4>}
                        <div className="gray-background">
                            <i className="fa fa-robot fa-2x"></i>
                        </div>
                    </span>
                </div>

                <Stepper
                    activeStep={this.state.tabIndex}
                    steps={["Condições", "Ações", "Extras", "Simulação"]}
                    onChange={(tabIndex) => this.setState({ tabIndex })}
                />

                <Tabs selectedIndex={this.state.tabIndex} onSelect={() => { }}>
                    <TabList className="hdv-noshow-item">
                        <Tab></Tab>
                        <Tab></Tab>
                        <Tab></Tab>
                        <Tab></Tab>
                    </TabList>

                    <TabPanel style={{ minHeight: "400px" }}>
                        <div className="hdv-form-default mb-2">
                            <div className="row">
                                <div className="col-md-1">
                                    <label htmlFor="ativo" className="checkbox-switch mt-0">Ativo:
                                        <input
                                            type="checkbox"
                                            id="ativo"
                                            checked={this.state.detalhes.ativo == "1"}
                                            onChange={(e) => this.handleChange({ target: { value: (e.target.checked) ? "1" : "0", name: "ativo", id: "ativo" } })}
                                        />
                                        <span></span>
                                    </label>
                                </div>

                                <div className="col-md-8">
                                    <label htmlFor="descricao" className="mt-0">Descrição: </label>
                                    <input
                                        id="descricao" type="text" name="descricao"
                                        value={this.state.detalhes.descricao}
                                        onChange={this.handleChange}
                                    />
                                    <span id="validacao-descricao"></span>
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="ordem" className="mt-0">Ordem de Execução: </label>
                                    <input
                                        type="number" name="ordem" id="ordem" min="0"
                                        value={this.state.detalhes.ordem ?? 0}
                                        onChange={this.handleChange}
                                    />
                                    <span id="validacao-ordem"></span>
                                </div>
                            </div>
                        </div>

                        <div className="hdv-form-default">
                            <fieldset className="hdv-fieldset">
                                <legend className="hdv-legend">Condições de acionamento</legend>
                                {this.state.condicoes?.map((condicoes, cIndex) => {
                                    let condicaoArray = []

                                    if (condicoes?.map) {
                                        condicaoArray = condicoes?.map(c => c.condicao)
                                    }

                                    return (
                                        <React.Fragment key={cIndex}>
                                            <div className="row automacao-alerta-condicao">
                                                {condicaoArray?.map((condicao, index) => (
                                                    <div
                                                        key={index}
                                                        className={index === 0 ? "row col-md-12 mx-0 my-0 pb-4" : "row col-md-12 mx-0 my-0 pb-4"}>
                                                        {index !== 0 ? <div className=" text-center or-operation"></div> : null}
                                                        <button onClick={() => this.removeCondition(cIndex, index)} className="hdv-btn-forms hdv-btn-red hdv-automacao-delete-button">
                                                            <i className="fa fa-times fa-2x"></i>
                                                        </button>
                                                        <div className="col-md-3">
                                                            <label>Monitorado: </label>
                                                            <Select
                                                                value={condicao['monitorado']}
                                                                options={options.monitorados}
                                                                onChange={(e) => {
                                                                    this.handleChangeCondicao(e, 'monitorado', cIndex, index)
                                                                    this.buscarParametrosMonitorado(e.value, cIndex, index)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label>Quando o Campo: </label>
                                                            <Select
                                                                value={condicao['campo']}
                                                                options={options.parametros}
                                                                onChange={(e) => {
                                                                    this.handleChangeCondicao(e, 'campo', cIndex, index)
                                                                    this.handleChangeSimulacao(e, 'campo', cIndex, index)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label>For: </label>
                                                            <Select
                                                                value={condicao['condicao']}
                                                                onChange={(e) => this.handleChangeCondicao(e, 'condicao', cIndex, index)}
                                                                options={condicao['campo']?.tipo_saida !== "booleano"
                                                                    ?
                                                                    this.state.operadores
                                                                    :
                                                                    [
                                                                        { label: "Igual (=)", value: "igual", symbol: "=" },
                                                                        { label: "Diferente (!=)", value: "diferente", symbol: "!=" }
                                                                    ]}
                                                            />
                                                        </div>
                                                        {(condicao?.['campo']?.tipo_saida === 'booleano') ?
                                                            <div className="col-md-3">
                                                                <label htmlFor="valor">Status: </label>
                                                                <div>
                                                                    <input
                                                                        type="radio"
                                                                        id={`valor_${cIndex} -${index} `}
                                                                        name={`valor_${cIndex} -${index} `}
                                                                        value="1"
                                                                        checked={(condicao.valor !== "0")}
                                                                        onChange={(e) => this.handleChangeCondicaoValue(e, "valor", cIndex, index)}
                                                                    />
                                                                    <span>Ativado</span>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="radio"
                                                                        id={`valor_${cIndex} -${index} `}
                                                                        name={`valor_${cIndex} -${index} `}
                                                                        value="0"
                                                                        checked={(condicao.valor === "0")}
                                                                        onChange={(e) => this.handleChangeCondicaoValue(e, "valor", cIndex, index)}
                                                                    />
                                                                    <span>Desativado</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="col-md-3">
                                                                <label htmlFor="valor">Valor: </label>
                                                                <input
                                                                    type="text"
                                                                    id={`valor_${cIndex} -${index} `}
                                                                    name={`valor_${cIndex} -${index} `}
                                                                    value={condicao.valor}
                                                                    onChange={(e) => this.formatar(e, "valor", cIndex, index)}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                                <div className="col-md-8"></div>
                                                <div className="col-md-4">
                                                    <button onClick={() => this.createExtraCondition(cIndex)} className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button d-flex align-items-center float-right my-0">
                                                        <i className="fa fa-plus-circle fa-2x"></i> &nbsp; Nova condição do tipo OU
                                                    </button>
                                                </div>
                                            </div>
                                            {cIndex === this.state.condicoes.length - 1 ? null : <div className="and-operation"><span>E</span></div>}
                                        </React.Fragment>
                                    )
                                }
                                )}

                                <div className="row">
                                    <div className="col-md-5">
                                        <button onClick={this.createNewCondition} className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button d-flex align-items-center">
                                            <i className="fa fa-plus-circle fa-2x"></i> &nbsp; Nova condição
                                        </button>
                                    </div>
                                </div>
                                <span id="validacao-condicao"></span>
                            </fieldset>
                            <fieldset className="hdv-fieldset">
                                <legend className="hdv-legend">Automações</legend>
                                {this.state?.condicoes_automacoes?.map?.((condicao, index) => (
                                    <div key={index} className="row automacao-alerta-condicao">
                                        <button onClick={() => this.removeConditionAutomation(index)} className="hdv-btn-forms hdv-btn-red hdv-automacao-delete-button">
                                            <i className="fa fa-times fa-2x"></i>
                                        </button>
                                        <div className="col-md-3">
                                            <label>Monitorado: </label>
                                            <Select
                                                value={condicao['monitorado']}
                                                onChange={(e) => {
                                                    this.filterAutomations(e)
                                                    this.handleChangeCondicaoAutomation({ value: e }, 'monitorado', index)
                                                }}
                                                options={options.monitorados}
                                                noOptionsMessage={() => "Nenhum monitorado encontrado"}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label>Automação: </label>
                                            <Select
                                                value={condicao['automacao']}
                                                onChange={(e) => this.handleChangeCondicaoAutomation({ value: e }, 'automacao', index)}
                                                options={options.automacoes}
                                                noOptionsMessage={() => "Nenhuma automação encontrada"}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label>Tipo: </label>
                                            <div>
                                                <input type="radio" checked={(condicao['tipo'] !== "0")} onChange={(e) => this.handleChangeCondicaoAutomation({ value: e.target.value }, "tipo", index)} value="1" />
                                                <span>Status atual da Automação</span>
                                            </div>
                                            <div>
                                                <input type="radio" checked={(condicao['tipo'] === "0")} onChange={(e) => this.handleChangeCondicaoAutomation({ value: e.target.value }, "tipo", index)} value="0" />
                                                <span>Qntd. de Execuções no dia</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3" style={({ "display": condicao['tipo'] !== "0" ? "block" : "none" })}>
                                            <label>Status: </label>
                                            <div>
                                                <input type="radio" checked={(condicao['valor'] !== "0")} onChange={(e) => this.handleChangeCondicaoAutomation({ value: e.target.value }, "valor", index)} value="1" />
                                                <span>Ativo</span>
                                            </div>
                                            <div>
                                                <input type="radio" checked={(condicao['valor'] === "0")} onChange={(e) => this.handleChangeCondicaoAutomation({ value: e.target.value }, "valor", index)} value="0" />
                                                <span>Desativado</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3" style={({ "display": condicao['tipo'] === "0" ? "block" : "none" })}>
                                            <label>Execuções: </label>
                                            <input type="number" onChange={(e) => this.handleChangeCondicaoAutomation({ value: e.target.value }, "execucoes_limite", index)} value={condicao['execucoes_limite']} />
                                        </div>
                                    </div>
                                ))}
                                <div className="row">
                                    <div className="col-md-5">
                                        <button onClick={this.createNewConditionAutomation} className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button d-flex align-items-center">
                                            <i className="fa fa-plus-circle fa-2x"></i> &nbsp; Nova condição
                                        </button>
                                    </div>
                                </div>
                                <span id="validacao-condicao-automation"></span>
                            </fieldset>
                            <fieldset className="hdv-fieldset">
                                <legend className="hdv-legend mb-3">Gatilhos (Equipamentos que checarão as condições)</legend>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    options={this.state.equipamentos?.map(e => new Object({ "label": e.imei, "value": e.id }))}
                                    value={options.equipamentos}
                                    components={{ MultiValueContainer }}
                                    onChange={(e) => this.handleChangeEquipamento(e)}
                                />
                            </fieldset>
                        </div>
                    </TabPanel>

                    <TabPanel style={{ minHeight: "400px" }}>
                        <div className="hdv-form-default">
                            <fieldset className="hdv-fieldset">
                                <legend className="hdv-legend">Ações Positivas</legend>

                                {this.state.acoes.map((acao, index) => {
                                    if (Boolean(acao?.negativa) == false)
                                        return (
                                            <div key={index} className="row automacao-alerta-condicao">
                                                <button onClick={() => this.removeAction(index)} className="hdv-btn-forms hdv-btn-red hdv-automacao-delete-button">
                                                    <i className="fa fa-times fa-2x"></i>
                                                </button>

                                                <div className="col-md-4">
                                                    <label>Tipo da Ação: </label>
                                                    <Select
                                                        value={options.acoes.find(a => a.value === acao.tipo)}
                                                        onChange={(e) => this.handleChangeAcao(e.value, 'tipo', index)}
                                                        options={options.acoes}
                                                    />
                                                </div>

                                                {this.renderTipoAcao(acao, index)}
                                            </div>
                                        )
                                }
                                )}

                                <div className="row">
                                    <div className="col-md-5">
                                        <button onClick={() => this.createNewAction()} className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button d-flex align-items-center">
                                            <i className="fa fa-plus-circle fa-2x"></i> &nbsp; Nova ação
                                        </button>
                                    </div>
                                </div>
                                <span id="validacao-acao"></span>
                            </fieldset>
                            <fieldset className="hdv-fieldset">
                                <legend className="hdv-legend">Ações Negativas</legend>

                                {this.state.acoes.map((acao, index) => {
                                    if (acao?.negativa)
                                        return (
                                            <div key={index} className="row automacao-alerta-condicao">
                                                <button onClick={() => this.removeAction(index)} className="hdv-btn-forms hdv-btn-red hdv-automacao-delete-button">
                                                    <i className="fa fa-times fa-2x"></i>
                                                </button>

                                                <div className="col-md-4">
                                                    <label>Tipo da Ação: </label>
                                                    <Select
                                                        value={options.acoes.find(a => a.value === acao.tipo)}
                                                        onChange={(e) => this.handleChangeAcao(e.value, 'tipo', index)}
                                                        options={options.acoes}
                                                    />
                                                </div>

                                                {this.renderTipoAcao(acao, index)}
                                            </div>
                                        )
                                })}

                                <div className="row">
                                    <div className="col-md-5">
                                        <button onClick={() => this.createNewAction(true)} className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button d-flex align-items-center">
                                            <i className="fa fa-plus-circle fa-2x"></i> &nbsp; Nova ação
                                        </button>
                                    </div>
                                </div>
                                <span id="validacao-acao"></span>
                            </fieldset>
                        </div>
                    </TabPanel>

                    <TabPanel style={{ minHeight: "400px" }}>
                        <div className="w-100 mb-3">
                            <label htmlFor="tolerancia">Tolerância antes de acionar (minutos/segundos)</label>
                            <TimePicker
                                id="tolerancia"
                                name="tolerancia"
                                value={this.state.detalhes.tolerancia}
                                onChange={(e) => this.handleChange(e)}
                            />
                            <span id="validacao-tolerancia"></span>
                        </div>
                        <fieldset className="hdv-fieldset mt-0">
                            <legend className="hdv-legend">Dias Permitidos</legend>

                            <div className="row">
                                <div className="col-md-3 hdv-form-default hdv-dias-semana-automacao">
                                    <label style={{ opacity: "0.75" }} className="mt-0">Pré Definido</label>
                                    <label>
                                        <input checked={(this.state.dias_pre_definido_cond === "todos_os_dias")} onChange={() => this.selecionaDiasSemanaPreDefinido("todos_os_dias")} type="checkbox" /> Todos os dia
                                    </label>
                                    <label>
                                        <input checked={(this.state.dias_pre_definido_cond === "finais_semana")} onChange={() => this.selecionaDiasSemanaPreDefinido("finais_semana")} type="checkbox" /> Finais de Semana
                                    </label>
                                    <label>
                                        <input checked={(this.state.dias_pre_definido_cond === "seg_sex")} onChange={() => this.selecionaDiasSemanaPreDefinido("seg_sex")} type="checkbox" /> Segunda a Sexta
                                    </label>
                                    <label>
                                        <input checked={(this.state.dias_pre_definido_cond === "seg_sab")} onChange={() => this.selecionaDiasSemanaPreDefinido("seg_sab")} type="checkbox" /> Segunda a Sábado
                                    </label>
                                    <label>
                                        <input checked={this.state.dias_personalizados} onChange={this.habilitaDiasPersonalizados} type="checkbox" /> Personalizado
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <label style={{ opacity: "0.75" }}>Dias da Semana</label>
                                    <div className="dias-semana-grid">
                                        {dias_da_semana && Object.keys(dias_da_semana).map?.(dia => (
                                            <label className="dia-sem" key={dia}>
                                                <input checked={dias_da_semana[dia]} onChange={() => this.diasSemanaPersonalizado(dia)} type="checkbox" />
                                                {dia.charAt(0).toUpperCase() + dia.slice(1)}
                                            </label>
                                        )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="hdv-fieldset">
                            <legend className="hdv-legend">Intervalo de Execução</legend>
                            <div className="hdv-form-default">
                                {(this.state.periodos.length > 0) ?
                                    this.state.periodos.map((periodo, index) => (
                                        <div key={index} className="row intervalo-item">
                                            <button onClick={() => this.removePeriodo(index)} className="hdv-btn-forms hdv-btn-red hdv-automacao-delete-button">
                                                <i className="fa fa-times fa-2x"></i>
                                            </button>
                                            <div className="col-md-4">
                                                <label htmlFor="horainicio">Hora Início</label>
                                                <input
                                                    type="time"
                                                    name="horainicio"
                                                    className="w-100"
                                                    value={this.state.periodos[index].horainicio}
                                                    onChange={(e) => this.handleChangePeriodo(e, index)}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="horafim">Hora Fim </label>
                                                <input
                                                    type="time"
                                                    name="horafim"
                                                    className="w-100"
                                                    value={this.state.periodos[index].horafim}
                                                    onChange={(e) => this.handleChangePeriodo(e, index)}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="considerar_volume">Considerar volume do período</label>
                                                <input
                                                    type="checkbox"
                                                    name="considerar_volume"
                                                    id="considerar_volume"
                                                    checked={this.state.periodos[index]?.considerar_volume}
                                                    onChange={(e) => this.handleChangePeriodo(e, index)}
                                                />
                                            </div>
                                        </div>
                                    ))
                                    :
                                    null
                                }
                                <div className="row">
                                    <div className="col-md-5">
                                        <button onClick={this.createNewPeriodo} className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button d-flex align-items-center">
                                            <i className="fa fa-plus-circle fa-2x"></i> &nbsp; Novo intervalo
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <span id="validacao-periodo"></span>
                    </TabPanel>
                    <TabPanel style={{ minHeight: "400px" }}>
                        <Simulacao
                            state={this.state}
                            handleChange={this.handleChangeSimulacao}
                            simular={this.simular}
                            update={(state, value) => this.setState({ [state]: value })}
                        />
                    </TabPanel>
                </Tabs>
                <div className="hdv-btn-group mt-4">
                    <ButtonsForm
                        route="/automacao"
                        edit={this.props.match.params.edit ? true : false}
                        cooldown={this.state.cooldown}
                        buttons={[
                            {
                                method: "post",
                                button: <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-green float-right m-0">Confirmar e Salvar</button>
                            }, {
                                method: "patch",
                                button: <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-green float-right m-0">Confirmar e Salvar</button>
                            },
                        ]}
                    />
                    <button onClick={this.props.history.goBack} className="hdv-btn-forms hdv-btn-orange ml-2">
                        Cancelar
                    </button>
                </div>
            </div>
        )
    }
}

export default Form
