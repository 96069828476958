import React, { Component } from 'react'
import { Badge } from 'reactstrap'
import cfg from '../../utils/config'
import Table from '../../components/Table'
import ProgressAcessos from '../../components/ProgressAcessos'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import GerenciarAcessos from './gerenciarAcessos'
import { Link } from 'react-router-dom'
import auth from '../../utils/auth'
import axios from 'axios'

class Acesso extends Component {
    state = {
        data: [],
        loading: true,
        tab: 0,
        typeUser: auth.getUserInfo().tipo,
        isCliente: auth.getUserInfo()?.cliente,
        isPai: auth.isPai()
    }

    getData = (state) => {
        this.setState({ loading: true })
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/acesso/?limit=${state.lineCount}` + state.filter + "&meus_acessos=true"

        if (state.url) {
            url = state.url
        }

        axios.get(url, config_me).then((res) => {

            this.setState({
                loading: false,
                data: res.data.results,
                count: res.data.count,
                next: res.data.next,
                previous: res.data.previous,
            })
        })
    }

    handleTab = (index) => {
        this.setState({ tab: index })
    }

    render() {
        const { typeUser, isCliente, isPai } = this.state
        const columns = [
            {
                Header: "Descrição do Acesso",
                Accessor: "descricao"
            },
            {
                Header: "Empresa",
                Accessor: "empresa_nome_fantasia"
            }, {
                Header: 'Equipamento',
                Accessor: 'equipamento.imei',
                Cell: (original) => {
                    if (original?.equipamento?.imei) {
                        return <Link to={`/equipamento/${original.equipamento.id}/editar`}>{original?.equipamento?.imei}</Link>
                    } else {
                        return <span>---</span>
                    }
                }
            }, {
                Header: 'Data de Criação',
                Accessor: 'data_criado',
                filterable: false,
                Cell: (original) => {
                    if (original.data_criado) {
                        let date = new Date(Date.parse(original.data_criado))
                        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
                        date = new Intl.DateTimeFormat('pt-Br', options).format(date).replace(",", "")
                        return <span>{date}</span>
                    } else {
                        return <span>---</span>
                    }
                }
            }, {
                Header: 'Data do Cancelamento',
                Accessor: 'data_cancelamento',
                filterable: false,
                Cell: (original) => {
                    if (original.data_cancelamento) {
                        let date = new Date(Date.parse(original.data_cancelamento))
                        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }

                        return <span>{new Intl.DateTimeFormat('pt-Br', options).format(date)}</span>
                    } else {
                        return <span>---</span>
                    }
                }
            }, {
                Header: 'Ativo',
                Accessor: 'ativo',
                sortable: false,
                Filter: ({ filter, onChange }) => (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : ""}>
                        <option value="">Todos</option>
                        <option value="true">Ativo</option>
                        <option value="false">Desativado</option>
                    </select>
                ),
                Cell: (original) => {
                    return original.ativo ?
                        <Badge href="#" color="success">Sim</Badge>
                        :
                        <Badge href="#" color="secondary">Não</Badge>
                }
            }]

        return (
            <div className="hdv-render-div">
                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Controle de Acessos</h4>
                            <div className="gray-background">
                                <i className="fa fa-key fa-2x"></i>
                            </div>
                        </span>
                    </div>

                    <Tabs className="tabs-style" selectedIndex={this.state.tab} onSelect={(i) => this.handleTab(i)}>
                        <TabList>
                            <Tab>Meus Acessos</Tab>
                            {
                                isPai && !isCliente && typeUser != 'normal' && auth.getUserInfo().pai === null ?
                                    <Tab>Gerenciar Acessos</Tab>
                                    : null
                            }
                        </TabList>
                        <TabPanel>
                            <ProgressAcessos style={{ marginBottom: "11px" }} />

                            <Table
                                notEditFirstColum={true}
                                data={this.state.data}
                                loading={this.state.loading}
                                next={this.state.next}
                                count={this.state.count}
                                previous={this.state.previous}
                                columns={columns}
                                onFetchData={this.getData}
                                onUpdate={(table) => this.setState({ table })}
                            />
                        </TabPanel>

                        {isPai && !isCliente && typeUser != 'normal' ?
                            <TabPanel>
                                <GerenciarAcessos />
                            </TabPanel>
                            : null
                        }
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default Acesso
