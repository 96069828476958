import React, { Component } from 'react'
import { Modal } from 'reactstrap'
import { Link } from 'react-router-dom'

class ModalImage extends Component {
    state = {
        currentImage: 0
    }

    componentDidMount() {
        document.body.addEventListener('click', (e) => {
            if (e.target.className == "image-monitorado-modal" || e.target.className == "fa fa-times") {
                this.props.toggle()
            }
        })
    }

    move = (direction) => {
        let { currentImage } = this.state

        if (direction && (currentImage + 1) < this.props.images.length) {
            currentImage += 1
        } else if (!direction && currentImage > 0) {
            currentImage -= 1
        }

        this.setState({ currentImage })
    }

    generateNavigation = () => {
        const multiple = this.props.images?.length > 1

        return (
            <div>
                <div id="image-modal-wrapper">
                    <div id="image-modal-content">
                        {multiple ? <button className="image-modal-nav" onClick={() => this.move(false)}><i className="fa fa-angle-left"></i></button> : null}
                        <img src={this.props.images[this.state.currentImage]?.foto} />
                        {multiple ? <button className="image-modal-nav" onClick={() => this.move(true)}><i className="fa fa-angle-right"></i></button> : null}
                    </div>
                </div>
                <div className='modal-image-subtitle-container'>
                    <div title={this.props.images[this.state.currentImage]?.descricao || "Descrição"}>{this.props.images[this.state.currentImage]?.descricao || "Descrição"}</div>
                </div>
                <Link to={`/monitorado/${this.props.id}/editar?foto=true`}>
                    <div
                        onClick={() => this.setState({ modal: true, image: original?.fotos })}
                        className="modal-image-button-add"
                    >
                        Adicionar outra imagem
                    </div>
                </Link>
            </div>
        )
    }

    render() {
        return (
            <Modal isOpen={this.props.open} toggle={() => this.props.toggle()} className="image-monitorado-modal">
                {(this.props.images?.[0])
                    ?
                    this.generateNavigation()
                    :
                    <div
                        className="d-flex justify-content-center align-items-center"
                        id="image-modal-content"
                        style={{ background: "#ffffff", borderRadius: "12px" }}>
                        <p>Sem imagens</p>
                    </div>
                }
            </Modal>
        )
    }
}

export default ModalImage