import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap'
import TemplateParametrizacao from './components/TemplateParametrizacao'
import TemplateComando from './components/TemplateComando'
import ButtonsForm from '../../components/ButtonsForm'
import CardParametrizacao from '../../components/CardParametrizacao'
import ProgressAcessos from '../../components/ProgressAcessos'
import CardComando from '../../components/CardComando'
import { Link, Redirect } from 'react-router-dom'
import { components } from 'react-select'
import Select from '../../components/Select'
import helper from '../../utils/helper'
import cfg from '../../utils/config'
import auth from '../../utils/auth'
import axios from 'axios'


class Form extends Component {
    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        detalhes: {
            imei: "", fabricante: null, modelo: null, operadora: null, sim_card: "", status: "",
            tipo_comunicacao: "outro", gateway: null, isGateway: false, gateway_equipamento: "", acesso: "",
            topico_personalizado: false, topico_publica: "", topico_recebe: "", usuario_mqtt: "", senha_mqtt: "",
            campo_gateway: "", tipo_acesso: "", tempo_limite_sem_comunicacao: 0
        },
        comandos: [],
        parametrizacoes: [],
        fabricantes: [],
        modelos: [],
        operadoras: [],
        tipoAcessos: [],
        acessos: [],
        timezones: [],
        redirect: false,
        allowNewParam: true,
        path: "",
        tab: 0,
        modalAcesso: false,
        optionSelected: {
            fabricante: {}, modelo: {}, operadora: {}, tipo_acesso: {},
            timezone: { value: 'America/Sao_Paulo', label: 'America/Sao_Paulo' }
        },
        cooldown: false,
        validacao: false,
        acaoAcesso: "",
        interval: null,
        modalStatus: false,
        device_id: "{device_id}",
        isVirtual: false
    }

    componentWillMount = () => {
        this.getTipoAcessos()
    }

    componentWillUnmount = () => {
        clearInterval(this.state.interval)
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
            this.buscarUltimoReporte()
            this.setState({ interval: setInterval(this.buscarUltimoReporte, 10_000) })
        }

        try {
            this.getInitialData()
            const hash = window.location.hash.replace("#", "")
            if (hash) {
                this.setState({ tab: Number(hash) - 1 })
            }
        } catch (err) {
            console.error(err)
        }

        if (window.location.search != "") {
            this.setState({ tab: 1 })
        }
    }

    getData = async () => {
        const baseUrl = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa

        axios.get(baseUrl + '/equipamento/' + this.props.match.params.id + '/', this.configMe).then((res) => {
            axios.get(baseUrl + '/fabricante/' + res.data.fabricante?.id + '/modelo/', this.configMe).then((modelos) => {
                let equipamento = res.data
                let optionsModelos = []
                let optionObj = this.state.optionSelected
                let detalhes = Object.assign(this.state.detalhes, equipamento)

                if (modelos.status === 200) {
                    modelos.data?.results?.forEach((m) => optionsModelos.push({ "value": m.id, "label": m.modelo }))
                }

                let optionGateway

                optionObj["fabricante"] = { "value": equipamento.fabricante?.id ?? null, "label": equipamento?.fabricante?.nome }
                optionObj["modelo"] = { "value": equipamento.modelo?.id ?? null, "label": equipamento?.modelo?.modelo }
                optionObj["operadora"] = { "value": equipamento.operadora?.id ?? null, "label": equipamento?.operadora?.nome, possui_chip: equipamento?.operadora?.possui_chip }
                optionObj["tipo_acesso"] = { "value": equipamento.acesso?.acesso_tipo ?? null, "label": equipamento?.acesso?.acesso_tipo__descricao }
                equipamento?.validacao?.timezone_equipamento ? optionObj["timezone"] = { "value": equipamento?.validacao?.timezone_equipamento, "label": equipamento?.validacao?.timezone_equipamento } : null

                if (equipamento.gateway_equipamento != null) {
                    optionGateway = this.state.gatewayOptions.filter((value) => {
                        return value.value === equipamento.gateway_equipamento
                    })

                    detalhes["gateway"] = optionGateway[0]
                }

                if (equipamento.gateway) {
                    detalhes['campo_gateway'] = equipamento?.campo_identificacao_gateway_equipamento
                }

                detalhes['isGateway'] = equipamento.gateway
                detalhes["tipo_acesso"] = equipamento?.acesso?.acesso_tipo

                if (equipamento.validacao && 'tempo_limite_sem_comunicacao' in equipamento.validacao) {
                    detalhes["tempo_limite_sem_comunicacao"] = equipamento.validacao.tempo_limite_sem_comunicacao
                } else {
                    detalhes["tempo_limite_sem_comunicacao"] = 0
                }

                this.setState({
                    detalhes: detalhes,
                    modelos: optionsModelos,
                    optionSelected: optionObj,
                    device_id: equipamento.usuario_mqtt,
                    isVirtual: equipamento.modelo.id === 8
                })
            })
        })
    }

    getTipoAcessos = () => {
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + "/acesso/tipo/", config_me).then((res) => {
            let tipoAcessos = []

            res.data.forEach((acesso) => tipoAcessos.push({ label: acesso.acesso_tipo__descricao, value: acesso.acesso_tipo__id }))

            this.setState({ tipoAcessos })
        })
    }

    getInitialData = async () => {
        const base = `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}`
        let requestArray = [
            axios.get(base + '/operadora/', this.configMe),
            axios.get(base + '/fabricante/', this.configMe),
            axios.get(base + '/equipamento/?gateway=true/', this.configMe),
            axios.get(base + '/configuracao-timezone/', this.configMe)
        ]

        await axios.all(requestArray).then(axios.spread((operadoras, fabricantes, gateways, timezones) => {
            let optionsFabricantes = []
            let optionsOperadoras = []
            let gatewayOptions = []
            let timezoneOptions = []

            if (fabricantes.status === 200) {
                fabricantes.data?.results?.forEach((fabricante) => {
                    optionsFabricantes.push({ "value": fabricante.id, "label": fabricante.nome })
                })
            }

            if (operadoras.status === 200) {
                operadoras.data?.results?.forEach((operadora) => {
                    optionsOperadoras.push({ "value": operadora.id, "label": operadora.nome, "possui_chip": operadora.possui_chip })
                })
            }

            if (gateways.status === 200) {
                gateways.data?.results?.forEach((gateway) => {
                    gatewayOptions.push({ label: gateway.imei, value: gateway.id })
                })
            }

            if (timezones.status === 200) {
                timezones.data?.results?.forEach((timezone) => {
                    timezoneOptions.push({ label: timezone.codigo, value: timezone.codigo })
                })
            }

            this.setState({ gatewayOptions, fabricantes: optionsFabricantes, operadoras: optionsOperadoras, timezones: timezoneOptions })
        }))

        if (this.props.match.params.id) {
            this.getComandos()
        }

        setTimeout(() => this.getParametros(), 150)
    }

    getComandos = () => {
        const base = `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}`
        const equipamentoId = String(this.props.match.params.id)

        axios.get(base + '/comando-customizado/?limit=100&equipamento=' + equipamentoId, this.configMe).then((res) => {
            let comandos = []

            if (res.status === 200) {
                res.data.results.forEach((comando) => {
                    if (typeof comando == "string") {
                        try {
                            comando.parametros = JSON.parse(comando.parametros)
                        } catch (err) {
                            console.error(err)
                        }
                    }

                    comandos.push(comando)
                })
            }

            this.setState({ comandos: comandos })
        })
    }

    getParametros = () => {
        if (!this.props.match.params.id) return
        const base = `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}`
        const equipamentoId = String(this.props.match.params.id)

        axios.get(base + '/campo-customizado/?limit=100&equipamento=' + equipamentoId, this.configMe).then((res) => {
            let { acessos, detalhes } = this.state
            let parametrizacoesItens = []
            let allowNewParam = true

            if (res.status === 200) {
                parametrizacoesItens = res.data.results ?? []

                if (detalhes?.acesso) {
                    let { limite } = this.state.detalhes?.acesso
                    if (parametrizacoesItens?.length == limite) {
                        allowNewParam = false
                    } else {
                        allowNewParam = parametrizacoesItens?.length < detalhes?.acesso?.limite
                    }
                }
            }

            this.setState({ parametrizacoes: parametrizacoesItens, allowNewParam })
        })
    }

    handleChangeFabricante = (selectedOption, field) => {
        let obj = this.state.detalhes
        let optionObj = this.state.optionSelected
        obj[field] = selectedOption.value
        optionObj[field] = selectedOption
        obj["modelo_id"] = ""
        optionObj["modelo"] = ""

        axios({
            url: cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/fabricante/' + optionObj.fabricante?.value + '/modelo/',
            method: "GET",
            ...this.configMe
        }).then((res) => {
            let optionsModelos = []
            if (res.status === 200) {
                res.data.results.forEach((a) => optionsModelos.push({ "value": a.id, "label": a.modelo }))
            }

            this.setState({ "optionSelected": optionObj, "detalhes": obj, "modelos": optionsModelos })
            document.querySelector(`#validacao-${field}`).innerHTML = ""
        })
    }

    handleChangeSelect = (selectedOption, field) => {
        let obj = this.state.detalhes
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        document.querySelector(`#validacao-${field}`).innerHTML = ""
        this.setState({ "optionSelected": optionObj, "detalhes": obj })
    }

    validation = () => {
        const { detalhes, isVirtual } = this.state
        let erro = false
        let data = {}
        let arrayData = ['imei', 'fabricante', 'modelo', 'operadora', 'status']

        if (!detalhes.isGateway && detalhes.status === 'ativo') {
            arrayData.push('tipo_acesso')
        } else {
            let field_acesso = document.getElementById(`validacao-tipo_acesso`)

            if (field_acesso) {
                field_acesso.innerHTML = ""
            }
        }

        if (detalhes.isGateway) arrayData.push('campo_gateway')

        if (isVirtual) arrayData = arrayData.filter((parametro) => parametro !== 'operadora')

        arrayData.forEach((valor) => data[valor] = detalhes[valor])

        for (let i in data) {
            let field = document.getElementById(`validacao-${i}`)
            let content = ""

            if (!data[i]) {
                erro = true
                content = "Campo não pode ser nulo!"
            } else {
                content = ""
            }

            if (field) {
                field.innerHTML = content
            }
        }

        return erro
    }

    saveAction = (novoCadastro) => {
        if (this.validation()) {
            if (this.state.tab != 0) {
                this.setState({ modalStatus: true })
            }
            return
        }

        const { detalhes, optionSelected } = this.state
        this.setState({ cooldown: true })
        let requestURL = ""
        let verb = ""

        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/equipamento/' + this.props.match.params.id + '/'
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/equipamento/'
            verb = 'post'
        }

        let bodyFormData = new FormData()
        bodyFormData.append("imei", detalhes.imei?.trim())
        bodyFormData.append("fabricante", optionSelected?.fabricante?.value ?? null)
        bodyFormData.append("modelo", optionSelected?.modelo?.value ?? null)
        bodyFormData.append("operadora", optionSelected?.operadora?.value ?? null)
        bodyFormData.append("timezone", optionSelected?.timezone?.value ?? null)
        bodyFormData.append("sim_card", detalhes.sim_card)
        bodyFormData.append("status", detalhes.status)
        bodyFormData.append("tipo_acesso", detalhes.tipo_acesso)
        bodyFormData.append("tipo_comunicacao", detalhes.tipo_comunicacao)
        bodyFormData.append("gateway", detalhes.isGateway ? "1" : "0")
        bodyFormData.append("topico_personalizado", detalhes.topico_personalizado)
        bodyFormData.append("tempo_limite_sem_comunicacao", detalhes.tempo_limite_sem_comunicacao)

        if (detalhes.isGateway) {
            bodyFormData.append("campo_identificacao_gateway_equipamento", detalhes.campo_gateway)
        }

        if (detalhes.tipo_comunicacao === "via_gateway") {
            bodyFormData.append("gateway_equipamento", detalhes.gateway?.value ?? null)
        } else if (detalhes.tipo_comunicacao === "mqtt") {
            if (detalhes.topico_personalizado) {
                bodyFormData.set("topico_recebe", detalhes.topico_recebe)
                bodyFormData.set("topico_publica", detalhes.topico_publica)
            }
        }

        if (verb === "patch") {
            if (Boolean(detalhes?.acesso?.acesso_tipo) && detalhes?.acesso?.acesso_tipo !== detalhes.tipo_acesso) {
                bodyFormData.append("old_acesso", detalhes?.acesso?.id)
                bodyFormData.set("tipo_acesso", detalhes.tipo_acesso)
            }
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => {
            if (novoCadastro) {
                window.location.reload()
            } else {
                helper.dispatchEvent("showAviso", {
                    message: `Equipamento ${verb === "post" ? "criado" : "editado"} com sucesso.`,
                    callback: () => this.setState({ redirect: true, path: "/equipamento" }),
                    callbackClose: verb === "post" ?
                        () => {
                            window.location.href = `/equipamento/${res.data.id}/editar?protocolo=true`
                            setTimeout(() => { window.location.reload() }, 1000)
                        }
                        :
                        () => {
                            let acessoIdAtual = detalhes.acesso.id
                            let acessoIdRecebido = res.data.acesso.id

                            if (acessoIdAtual !== acessoIdRecebido) {
                                window.location.reload()
                            }
                        }
                })
            }
        }).catch((error) => {
            let erros = Object.entries(error.response.data)

            if (error.response.data.code === 33) {
                let message = error.response.data.message
                let callback = () => this.setState({ redirect: true, path: "/equipamento" })
                helper.dispatchEvent(("showAviso", { message, callback }))
            }

            if (error.response.data.code === 34) {
                let message = error.response.data.message
                let callback = () => this.setState({ redirect: true, path: "/acessos" })
                helper.dispatchEvent(("showAviso", { message, callback }))
            }

            if (Array.isArray(erros)) {
                for (let i = 0; i < erros.length; i++) {
                    if (erros[i][0] === "detail") {
                        helper.dispatchEvent("showAviso", { message: erros[i][1] })
                    } else {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span) span.innerHTML = erros[i][1]
                    }
                }
            }
        })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    handleChange = (e) => {
        let obj = { ...this.state.detalhes }
        let acao

        obj[e.target.id] = e.target.type === "checkbox" ? e.target.checked : e.target.value

        if (e.target.value === "ativo") {
            acao = "somar"
        } else {
            acao = "subtrair"
        }

        this.setState({ "detalhes": obj, "acaoAcesso": acao })
        if (document.querySelector(`#validacao-${e.target.name}`)) {
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
        }
    }

    buscarUltimoReporte = () => {
        if (this.state.validacao) {
            clearInterval(this.state.interval)
            this.setState({ interval: null })
            return
        }

        if (!this.props.match.params?.id || !this.state.detalhes.imei)
            return

        axios({
            method: "GET",
            url: `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/equipamento/${this.state.detalhes.imei}/validacao/`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => {
            if (res.status === 200) {
                this.setState({ "validacao": res.data })
            }
        }).catch(console.error)
    }

    handleChangeGateway = (e) => {
        let { detalhes } = this.state
        detalhes["gateway"] = e
        this.setState({ detalhes })
    }

    handleTab = (index) => this.setState({ tab: index })

    renderComunicacao = () => {
        const detalhes = this.state.detalhes

        if (detalhes.tipo_comunicacao === "mqtt") {
            return (
                <div className="row">
                    <div className="col-md-12">
                        <label className="required" htmlFor="topico_personalizado">Tópicos MQTT</label>
                        <div className="d-flex">
                            <label className="hdv-fixed">
                                <input type="checkbox" disabled={(this.props.match.params.id)} name="topico_personalizado" id="topico_personalizado" checked={detalhes.topico_personalizado} onChange={this.handleChange} /> Tópicos Personalizados
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className="required" htmlFor="topico_publica">Publicar</label>
                        <input disabled={this.props.match.params.id || !detalhes.topico_personalizado} readOnly={!detalhes.topico_personalizado} type="text" name="topico_publica" id="topico_publica" value={detalhes.topico_publica ?? ""} onChange={this.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label className="required" htmlFor="topico_recebe">Receber</label>
                        <input disabled={this.props.match.params.id || !detalhes.topico_personalizado} readOnly={!detalhes.topico_personalizado} type="text" name="topico_recebe" id="topico_recebe" value={detalhes.topico_recebe ?? ""} onChange={this.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label className="required" htmlFor="usuario_mqtt">Usuário</label>
                        <input disabled={true} type="text" name="usuario_mqtt" id="usuario_mqtt" value={detalhes.usuario_mqtt ?? ""} onChange={this.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label className="required" htmlFor="senha_mqtt">Senha</label>
                        <input disabled={true} type="text" name="senha_mqtt" id="senha_mqtt" value={detalhes.senha_mqtt ?? ""} onChange={this.handleChange} />
                    </div>
                </div>
            )
        } else if (detalhes.tipo_comunicacao === "via_gateway") {
            return (
                <div className="row">
                    <div className="col-md-6">
                        <label className="required">
                            Selecione o Gateway
                            <Select
                                value={detalhes.gateway}
                                onChange={this.handleChangeGateway}
                                options={this.state.gatewayOptions}
                            />
                        </label>
                    </div>
                </div>
            )
        }
    }

    onLoadAcessos = (statusAcessos, all) => {
        let { tipoAcessos } = this.state

        for (let i = 0; i < tipoAcessos.length; i++) {
            let acesso = statusAcessos.find((a) => a.acesso_tipo === tipoAcessos[i].value)
            tipoAcessos[i].isDisabled = acesso?.utilizados >= acesso?.tcount
        }

        this.setState({ tipoAcessos, acessos: all })
    }

    closeModal = () => {
        this.setState({ modalStatus: false })
    }

    render() {
        const { detalhes, optionSelected, device_id, parametrizacoes, isVirtual } = this.state
        const selectedFabricante = optionSelected.fabricante
        const selectedModelo = optionSelected.modelo
        const selectedOperadora = optionSelected.operadora
        const selectedTimezone = optionSelected.timezone
        const selctedGateway = ""

        if (this.state.redirect) {
            history.pushState({}, "", location.href)
            return <Redirect to={this.state.path} />
        }

        const { Option } = components
        const IconOption = (props) => {
            const disabled = props.data.isDisabled
            return (
                <Option {...props}>
                    <span className="d-flex align-items-center">
                        <i style={{ fontSize: '16px', color: disabled ? "#cf2b2b" : '#2bb33f', borderRadius: "8px" }} className="fa fa-square mr-1"></i>
                        <b style={{ color: '#222', fontWeight: 'normal', opacity: disabled ? 0.7 : 1 }}>{props.data.label}</b>
                    </span>
                </Option>
            )
        }

        return (
            <div>
                <Modal isOpen={this.state.modalStatus} toggle={() => this.closeModal()}>
                    <ModalHeader>
                        <span>Aviso</span>
                    </ModalHeader>
                    <ModalBody>
                        É preciso preencher todos os campos obrigatórios na aba <strong>Equipamento!</strong>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            className="hdv-btn-forms hdv-btn-blue"
                            onClick={() => {
                                this.closeModal()
                                this.setState({ tab: 0 })
                                setTimeout(() => {
                                    this.validation()
                                }, 1000)
                            }}>
                            Ir para aba equipamento
                        </button>
                    </ModalFooter>
                </Modal>

                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        {this.props.match.params.id ? <h4>Editar Equipamento</h4> : <h4>Novo Equipamento</h4>}
                        <div className="gray-background">
                            <i className="fa fa-hdd fa-2x"></i>
                        </div>
                    </span>
                </div>

                <Tabs className="tabs-style" selectedIndex={this.state.tab} onSelect={(i) => this.handleTab(i)}>
                    <TabList>
                        <Tab>Equipamento</Tab>
                        <Tab disabled={isVirtual}>Protocolo</Tab>
                        {!detalhes.isGateway ?
                            <>
                                <Tab disabled={!Boolean(this.props.match.params.id)}>Parametrização</Tab>
                                <Tab disabled={!Boolean(this.props.match.params.id) || isVirtual}>Comandos</Tab>
                            </>
                            : null
                        }
                    </TabList>

                    <TabPanel>
                        <div className="hdv-form-default" style={{ minHeight: "370px" }}>
                            <div className="row">
                                <div className={detalhes?.acesso ? "col-md-9" : "col-md-12"}>
                                    <label className="required" htmlFor="razao_social">IMEI</label>
                                    <input disabled={(this.props.match.params.id) ? true : false} type="text" name="imei" id="imei" value={detalhes.imei} onChange={this.handleChange} />
                                    <span id="validacao-imei"></span>
                                </div>

                                {detalhes?.acesso ?
                                    <div className="col-md-3">
                                        <label className="required" htmlFor="razao_social">Estado do acesso</label>
                                        <progress value={parametrizacoes?.length} max={detalhes?.acesso?.limite} style={{ width: "100%" }} />
                                        <div>
                                            {`${parametrizacoes?.length} / ${detalhes?.acesso?.limite ?? ""}`}
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <label className="required">Fabricante</label>
                                    <Select
                                        value={selectedFabricante}
                                        onChange={(e) => this.handleChangeFabricante(e, 'fabricante')}
                                        options={this.state.fabricantes}
                                        disabled={isVirtual}
                                    />
                                    <span id="validacao-fabricante"></span>
                                </div>

                                <div className="col-md-6">
                                    <label className="required">Modelo</label>
                                    <Select
                                        value={selectedModelo}
                                        name="modelo"
                                        onChange={(e) => this.handleChangeSelect(e, 'modelo')}
                                        options={this.state.modelos}
                                        disabled={isVirtual}
                                    />
                                    <span id="validacao-modelo"></span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <label className="required">Comunicação</label>
                                    <Select
                                        value={selectedOperadora}
                                        name="operadora"
                                        disabled={isVirtual}
                                        onChange={(e) => this.handleChangeSelect(e, 'operadora')}
                                        options={this.state.operadoras}
                                    />
                                    <span id="validacao-operadora"></span>
                                </div>

                                <div className="col-md-6">
                                    <label className="required" htmlFor="sim_card">Número do SimCard</label>
                                    <input type="text" name="sim_card" id="sim_card" value={detalhes.sim_card} onChange={this.handleChange} disabled={!Boolean(this.state.optionSelected?.operadora?.possui_chip)} />
                                    <span id="validacao-sim_card"></span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <label className='required'>Tempo limite sem comunicação</label>
                                    <input
                                        type="number"
                                        name="tempo_limite_sem_comunicacao"
                                        value={this.state.detalhes.tempo_limite_sem_comunicacao || 0}
                                        min='0'
                                        placeholder='Em minutos'
                                        onChange={(e) => {
                                            let detalhes = { ...this.state.detalhes }
                                            let value = e.target.value?.replace(/-/g, "").replace(/e/g, "")
                                            detalhes.tempo_limite_sem_comunicacao = value
                                            this.setState({ detalhes })
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="required">Timezone</label>
                                    <Select
                                        value={selectedTimezone}
                                        name="timezone"
                                        onChange={(e) => this.handleChangeSelect(e, 'timezone')}
                                        options={this.state.timezones}
                                    />
                                    <span id="validacao-timezone"></span>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-md-6" style={{ paddingBottom: 20 }}>
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <label className="required">Gateway</label>
                                            <input disabled={this.props.match.params.id ? true : false} checked={detalhes.isGateway} id="isGateway" onChange={this.handleChange} type="checkbox" style={{ marginLeft: 2 }} />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="required" htmlFor="status">Status</label>
                                            <div className="d-flex">
                                                <label className="hdv-fixed">
                                                    <input type="radio" name="status" id="status" checked={detalhes.status === 'ativo'} value="ativo" onChange={this.handleChange} /> Ativo
                                                </label>
                                                <label className="hdv-fixed">
                                                    <input type="radio" name="status" id="status" checked={detalhes.status === 'bloqueado'} value="bloqueado" onChange={this.handleChange} /> Bloqueado
                                                </label>
                                                <label style={{ paddingRight: "50px" }} className="hdv-fixed">
                                                    <input type="radio" name="status" id="status" checked={detalhes.status === 'cancelado'} value="cancelado" onChange={this.handleChange} /> Cancelado
                                                </label>
                                            </div>
                                            <span id="validacao-status"></span>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div style={{ display: detalhes.isGateway ? "block" : "none" }}>
                                        <label className="required" htmlFor="campo_gateway">Campo ID do equipamento</label>
                                        <input type="text" name="campo_gateway" id="campo_gateway" value={detalhes.campo_gateway ?? ""} onChange={this.handleChange} disabled={!Boolean(this.state.detalhes.isGateway)} />
                                        <span id="validacao-campo_gateway"></span>
                                    </div>

                                    <div style={{ display: detalhes.isGateway ? "none" : "block" }}>
                                        <label className="required">Tipo do Acesso</label>
                                        <div className='row'>
                                            <div className="col-md-8">
                                                <Select
                                                    components={{ Option: IconOption }}
                                                    value={optionSelected.tipo_acesso}
                                                    name="tipo_acesso"
                                                    disabled={!Boolean(detalhes.status === 'ativo')}
                                                    onChange={(e) => this.handleChangeSelect(e, "tipo_acesso")}
                                                    options={this.state.tipoAcessos}
                                                />
                                            </div>
                                            <div className="col-md-4" style={{ paddingLeft: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <ProgressAcessos
                                                    className="float-right"
                                                    onLoad={this.onLoadAcessos}
                                                    style={{ margin: 0, width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <span id="validacao-tipo_acesso"></span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="required" htmlFor="tipo_comunicacao">Protocolo</label>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <label className="hdv-fixed">
                                                <input type="radio" name="tipo_comunicacao" id="tipo_comunicacao" checked={detalhes.tipo_comunicacao === 'mqtt'} value="mqtt" onChange={this.handleChange} /> MQTT
                                            </label>
                                        </div>
                                        <div style={{ display: detalhes.isGateway ? "none" : "block" }}>
                                            <label className="hdv-fixed">
                                                <input type="radio" name="tipo_comunicacao" id="tipo_comunicacao" checked={detalhes.tipo_comunicacao === 'via_gateway'} value="via_gateway" onChange={this.handleChange} /> Gateway
                                            </label>
                                        </div>
                                        <div>
                                            <label className="hdv-fixed">
                                                <input type="radio" name="tipo_comunicacao" id="tipo_comunicacao" checked={detalhes.tipo_comunicacao === 'http'} value="http" onChange={this.handleChange} /> HTTP
                                            </label>
                                        </div>
                                        <div>
                                            <label className="hdv-fixed">
                                                <input type="radio" name="tipo_comunicacao" id="tipo_comunicacao" checked={detalhes.tipo_comunicacao === 'outro'} value="outro" onChange={this.handleChange} /> Outro
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.renderComunicacao()}
                            <br />

                            <div style={{ display: detalhes.tipo_comunicacao === 'http' || detalhes.tipo_comunicacao === 'mqtt' ? "block" : "none" }}>
                                <label style={{ fontWeight: "bold" }}>Detalhes da comunicação</label>
                                <div className='container-detalhes-comunicacao'>
                                    <div>
                                        {detalhes.tipo_comunicacao === 'http' ?
                                            <>
                                                <p><strong>Url:</strong> https://data.afira.io/device/{device_id}/</p>
                                                <p><strong>Métodos:</strong> <Badge color="success">GET</Badge><Badge color="danger">POST</Badge></p>
                                            </>
                                            :
                                            <>
                                                <p><strong>Servidor:</strong> message.afira.io</p>
                                                <p><strong>Portas:</strong> 1883 e SSL/TLS:8883</p>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>

                    {!detalhes.isGateway ?
                        <>
                            <TabPanel>
                                {(this.props.match.params.id)
                                    ?
                                    <TemplateParametrizacao
                                        detalhes={this.state.detalhes}
                                        allowNewParam={this.state.allowNewParam}
                                        fabricante={this.state.optionSelected?.fabricante}
                                        modelo={this.state.optionSelected?.modelo}
                                        parametrizacoes={this.state.parametrizacoes}
                                        getData={this.getParametros}
                                    />
                                    :
                                    null
                                }
                                <div>
                                    {(!this.props.match.params.id)
                                        ?
                                        <span className="equipamento-info-parametrizacao">
                                            💾 <strong>&nbsp; Salve o equipamento &nbsp;</strong> e aguarde sua comunicação para configurar os valores!
                                        </span>
                                        :
                                        (!this.state.validacao && this.state.parametrizacoes.length === 0)
                                            ?
                                            <span className="equipamento-info-parametrizacao">
                                                ⏳ Aguarde a comunicação do equipamento para configurar os valores!
                                            </span>
                                            :
                                            <div className="box-list-novo-parametrizacao">
                                                <div className={this.state.allowNewParam ? "parametrizacao-btn-novo" : "parametrizacao-btn-novo disabled"}>
                                                    <Link
                                                        to={{ pathname: `/campo-parametrizado/${detalhes.id}/novo`, state: { id: detalhes.id } }}
                                                        style={{ pointerEvents: this.state.allowNewParam ? "all" : "none" }}
                                                    >
                                                        <i className="fa fa-plus-circle"></i> <span>Novo Parâmetro</span>
                                                    </Link>
                                                    <span id="warning">Esse equipamento atingiu o limite de parâmetros. Utilize outro acesso ou contate um administrador!</span>
                                                </div>
                                                {this.state.parametrizacoes?.map((parametrizacao, index) => (
                                                    <CardParametrizacao
                                                        key={index}
                                                        detalhes={detalhes}
                                                        parametrizacao={parametrizacao}
                                                        getParametros={this.getParametros}
                                                    />
                                                ))}
                                            </div>
                                    }
                                </div>
                            </TabPanel>
                            <TabPanel>
                                {(this.props.match.params.id)
                                    ?
                                    <TemplateComando
                                        detalhes={this.state.detalhes}
                                        fabricante={this.state.optionSelected?.fabricante}
                                        modelo={this.state.optionSelected?.modelo}
                                        comandos={this.state.comandos}
                                        getData={this.getComandos}
                                    />
                                    :
                                    null
                                }
                                <div className="box-list-novo-comandos">
                                    {(!this.props.match.params.id || !detalhes.imei) ?
                                        <span className="equipamento-info-parametrizacao">
                                            💾 <strong>&nbsp; Salve o equipamento &nbsp;</strong> para adicionar comandos!
                                        </span>
                                        :
                                        <>
                                            <div className="comandos-btn-novo">
                                                <Link to={{ pathname: `/comando-customizado/${this.props.match.params.id}/novo`, state: { id: detalhes.id } }} >
                                                    <i className="fa fa-plus-circle"></i> <span>Novo Comando</span>
                                                </Link>
                                            </div>

                                            {this.state.comandos?.map((comando, index) => (
                                                <CardComando width="200px" key={index} getComandos={this.getComandos} comando={comando} />
                                            ))}
                                        </>
                                    }
                                </div>
                            </TabPanel>
                        </>
                        : null
                    }
                </Tabs>

                <div className="hdv-btn-group">
                    <ButtonsForm
                        route="/equipamento"
                        edit={this.props.match.params.edit ? true : false}
                        cooldown={this.state.cooldown}
                        buttons={[
                            {
                                method: "post",
                                button: <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                            }, {
                                method: "post",
                                button: <button style={{ display: this.props.match.params.id ? "none" : "" }} onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-greenlight">Salvar e Cadastrar um Novo</button>
                            }, {
                                method: "patch",
                                button: <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                            },
                        ]}
                    />
                    <Link to='/equipamento'>
                        <button className="hdv-btn-forms hdv-btn-orange">
                            Cancelar
                        </button>
                    </Link>
                </div>
            </div>
        )
    }
}

export default Form
