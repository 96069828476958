import React, { Component } from "react"
import { Redirect } from 'react-router-dom'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import helper from '../../utils/helper'
import ButtonsForm from "../../components/ButtonsForm"
import ReactLoading from 'react-loading'

import Configuracao from "./components/Configuracao"
import CamposGrupos from "./components/CamposGrupos"
import Relatorio from "./components/Relatorio"

import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'

class Form extends Component {
    state = {
        detalhes: {
            descricao: null, periodo: null, inicio: "00:00", fim: "23:59", campos: [],
            agruparDados: { label: "Fixo", value: null }, separador: { label: "Padrão", value: 'en-US' },
            periodoTodo: false, dataFormatada: "",
            cliente: { label: "Nenhum", value: null }
        },
        validacao: { descricao: true, periodo: true, inicio: true, fim: true, campos: true },
        relatorioGerado: false,
        msg_erro: "",
        tab: 0,
        edit: this.props.match.params.id ? true : false,
        cooldown: false,
        redirect: false,
        path: "",
        modalErro: false,
        dataRelatorio: [],
        estrutura: { quantidadeColunas: 0, quantidadeLinhasCabecalho: 0, quantidadeColunasCabecalho: 0, colunas: [], grupos: [], escalas: [], colunasCabecalho: [] },
        msg_erro_relatorio: "",
        exportar_disabled: true,
        clientes: [],
        clientesOptions: []
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }

        this.getClientes()
    }

    getClientes = () => {
        const userInfo = auth.getUserInfo()

        if (userInfo.tipo === "cliente") {
            let optionsCliente = [{ label: userInfo.nome, value: userInfo.cliente }]
            this.setState({ clientes: optionsCliente })
        } else {
            let userInfo = auth.getUserInfo()
            let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
            axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/' + `?monitorado_restrito=${userInfo.monitorado_restrito}`, config_me)
                .then((response) => {
                    let optionsCliente = [{ label: "Nenhum", value: null }]

                    response.data.results.forEach((cliente) => {
                        optionsCliente.push({ value: cliente.id, label: cliente.nome_fantasia })
                    })

                    this.setState({ clientes: optionsCliente })
                })
        }
    }

    getData = () => {
        let detalhes = Object.assign({}, this.state.detalhes)
        let userInfo = auth.getUserInfo()

        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/exportacao-dados/${this.props.match.params.id}/`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                const res = response.data

                detalhes["descricao"] = res.descricao
                detalhes["campos"] = res.campos_detalhes
                detalhes["agruparDados"] = res.estrutura?.agruparDados ? res.estrutura.agruparDados : { label: "Fixo", value: null }
                detalhes["periodoTodo"] = res.estrutura?.periodoTodo ? res.estrutura.periodoTodo : false
                detalhes["separador"] = res.estrutura?.separador ? res.estrutura.separador : { label: "Padrão", value: 'en-US' }
                detalhes['cliente'] = res.cliente ? res.cliente_detalhes : { label: "Nenhum", value: null }

                this.setState({
                    detalhes,
                    estrutura: res.estrutura === false ? { quantidadeColunas: 0, quantidadeLinhasCabecalho: 0, quantidadeColunasCabecalho: 0, colunas: [], grupos: [], escalas: [], colunasCabecalho: [] } : { ...this.state.estrutura, ...res.estrutura }
                })
            })
    }

    handleTab = (index) => {
        this.setState({ tab: index })
    }


    validacaoRelatorio = () => {
        const detalhes = Object.assign({}, this.state.detalhes)
        let validacao = Object.assign({}, this.state.validacao)
        let erro = false

        for (let campo in detalhes) {
            if (detalhes[campo] === null && campo != 'descricao') {
                validacao[campo] = false
                erro = true
            } else {
                validacao[campo] = true
            }
        }

        this.setState({ validacao: validacao })

        if (erro) {
            this.setState({ tab: 0 })
        } else {
            this.gerarRelatorio()
        }
    }

    formatarCampos = () => {
        let escalas = this.state.estrutura.escalas
        let consulta_escala = []
        let campos_usuarios = []

        if (escalas.length > 0) {
            escalas.forEach(({ escala, imei, usuario_mqtt }) => {
                if (escala.value != null) {
                    let campos = []
                    let imei_campos = []

                    escalas.forEach((item) => {
                        if (escala.value === item.escala.value && usuario_mqtt === item.usuario_mqtt) {
                            campos.push(item.identificacao)
                            if (item.imei) {
                                imei_campos.push(item.imei)
                            }
                        }
                    })

                    let check_item = consulta_escala.some((item) => usuario_mqtt === item.usuario_mqtt && escala.value === item.escala)

                    if (!check_item) {
                        consulta_escala.push({ escala: escala.value, usuario_mqtt: usuario_mqtt, campos: campos.join(), imei: imei })
                    }
                }

                let campos = []
                let imei_campos = []

                escalas.forEach((item) => {
                    if (usuario_mqtt === item.usuario_mqtt) {
                        campos.push(item.identificacao)

                        if (item.imei) {
                            imei_campos.push(item.imei)
                        }
                    }
                })

                let check_item = campos_usuarios.some((item) => usuario_mqtt === item.usuario_mqtt)

                if (!check_item) {
                    campos_usuarios.push({ usuario_mqtt: usuario_mqtt, campos: campos.join(), imei: imei })
                }
            })
        } else {
            this.state.detalhes.campos.forEach((campo) => {
                let usuario_mqtt = campo.usuario_mqtt
                let campos = []
                let imei_campos = []

                this.state.detalhes.campos.forEach((item) => {
                    if (item.usuario_mqtt === usuario_mqtt) {
                        campos.push(item.identificacao)

                        if (item.imei) {
                            imei_campos.push(item.imei)
                        }
                    }
                })

                let campo_existe = campos_usuarios.some((item) => item.usuario_mqtt === usuario_mqtt)

                if (!campo_existe) {
                    campos_usuarios.push({ usuario_mqtt: usuario_mqtt, campos: campos.join(), imei: campo.imei })
                }
            })
        }

        return { consulta_escala, campos_usuarios }
    }

    gerarRelatorio = async () => {
        const { detalhes } = this.state

        this.setState({ carregando: true, exportar_disabled: true })

        let userInfo = auth.getUserInfo()
        let periodo = detalhes.periodo.split("-")
        let campos = this.formatarCampos()

        let bodyFormData = {
            'data_inicio': periodo[0].replaceAll("/", "-") + " " + detalhes.inicio,
            'data_fim': periodo[1].replaceAll("/", "-") + " " + detalhes.fim,
            "periodo_todo": this.state.detalhes.periodoTodo,
            "periodo": this.state.detalhes.agruparDados.value,
            "consulta_escala": campos.consulta_escala,
            "campos_usuarios": campos.campos_usuarios,
        }

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/exportar-dados/',
            data: JSON.stringify(bodyFormData),
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                let exportar_disabled = false

                if (response.data.campos_usuarios.length === 0) {
                    exportar_disabled = true
                }
                this.setState({ carregando: false, dataRelatorio: response.data, relatorioGerado: true, exportar_disabled: exportar_disabled, msg_erro_relatorio: "" })
            })
            .catch((error) => {
                this.setState({ carregando: false, exportar_disabled: true, relatorioGerado: false, msg_erro_relatorio: error?.response?.data?.error })
            })
    }

    saveAction = () => {
        this.setState({ cooldown: true })

        let { edit, detalhes, validacao, estrutura } = this.state
        let method = "POST"
        let userInfo = auth.getUserInfo()
        let path = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + "/exportacao-dados/"

        if (edit) {
            method = 'PATCH'
            path = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/exportacao-dados/${this.props.match.params.id}/`
            estrutura['periodoTodo'] = detalhes.periodoTodo
            estrutura['agruparDados'] = detalhes.agruparDados
            estrutura['separador'] = detalhes.separador
        }

        validacao['descricao'] = detalhes.descricao === null || detalhes.descricao === "" ? false : true

        this.setState({ validacao })

        if (!validacao['descricao']) {
            this.setState({ cooldown: false })
            return
        }

        let bodyFormData = {
            descricao: detalhes.descricao,
            estrutura: estrutura,
            cliente: detalhes.cliente.value
        }

        axios({
            method: method,
            url: path,
            data: JSON.stringify(bodyFormData),
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((res) => {
                this.setState({ cooldown: false })

                helper.dispatchEvent("showAviso", {
                    message: "Relatorio salvo com sucesso",
                    callback: () => this.setState({ redirect: true, path: "/exportacao-dados" }),
                    callbackClose: method === 'POST' ? () => {
                        window.location.href = `/exportacao-dados/${res.data.id}/editar`
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000)
                    } : null
                })
            })
            .catch((error) => {
                this.setState({ cooldown: false })
                helper.dispatchEvent("showAviso", {
                    message: error.response.data.cliente[0],
                })
            })
    }

    toggleModalErro = (status) => {
        this.setState({ modalErro: status })
    }


    render() {
        const { msg_erro, modalErro, edit, detalhes, cooldown, dataRelatorio, relatorioGerado, estrutura, carregando, exportar_disabled, msg_erro_relatorio, tab, clientes } = this.state

        if (this.state.redirect) return <Redirect to={this.state.path} />

        return (
            <div>
                <Modal isOpen={modalErro} toggle={() => this.toggleModalErro(false)}>
                    <ModalHeader>
                        <span>Aviso</span>
                    </ModalHeader>
                    <ModalBody>
                        <strong>{msg_erro}</strong>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => this.toggleModalErro(false)} className="hdv-btn-forms hdv-btn-red">Fechar</button>
                    </ModalFooter>
                </Modal>

                <div>
                    <div style={{ justifyContent: "space-between" }} className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Exportação de Dados</h4>
                            <div className="gray-background">
                                <i className="fa fa-file-export fa-2x"></i>
                            </div>
                        </span>
                        <div>
                            <ReactLoading className="hdv-report-loading" type="cylon" color={carregando ? "#589bd4" : "transparent"} width={50} />
                        </div>
                    </div>

                    <Tabs className="tabs-style" selectedIndex={this.state.tab} onSelect={(i) => this.handleTab(i)}>
                        <TabList>
                            <Tab>{edit ? "Gerar Relatorio" : "Descrição"}</Tab>
                            <Tab disabled={!edit}>Parâmetros e Grupos</Tab>
                        </TabList>
                        <TabPanel>
                            <Configuracao
                                detalhes={detalhes}
                                setDetalhes={(detalhes) => { this.setState({ detalhes: detalhes }) }}
                                validacao={this.state.validacao}
                                edit={this.state.edit}
                                clientes={clientes}
                            />
                            <Relatorio
                                dataRelatorio={dataRelatorio}
                                relatorioGerado={relatorioGerado}
                                estrutura={estrutura}
                                statusButton={exportar_disabled}
                                msgErro={msg_erro_relatorio}
                                gerarRelatorio={() => this.validacaoRelatorio()}
                                edit={this.state.edit}
                                detalhes={detalhes}
                            />
                        </TabPanel>
                        <TabPanel>
                            <CamposGrupos
                                detalhes={detalhes}
                                setDetalhes={(detalhes) => { this.setState({ detalhes: detalhes }) }}
                                estrutura={estrutura}
                                setEstrutura={(estrutura) => this.setState({ estrutura: estrutura })}
                                validacao={this.state.validacao}
                                edit={this.state.edit}
                                idEdit={this.props.match.params.id}
                                checkUserMqtt={(campos) => this.checkUserMqtt(campos)}
                                clientes={clientes}
                            />
                        </TabPanel>
                    </Tabs>
                </div>

                <div style={{ display: !edit ? "flex" : tab === 1 ? "flex" : "none" }} className="hdv-btn-group">
                    <ButtonsForm
                        route="/exportacao-dados"
                        edit={this.props.match.params.id ? true : false}
                        cooldown={cooldown}
                        buttons={[
                            {
                                method: "post",
                                button: <button onClick={() => this.saveAction()} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                            }, {
                                method: "patch",
                                button: <button onClick={() => this.saveAction()} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                            },
                        ]}
                    />
                    <button disabled={cooldown} onClick={() => this.setState({ redirect: true, path: "/exportacao-dados" })} className="hdv-btn-forms hdv-btn-yellow ">Cancelar</button>
                </div>
            </div>
        )
    }
}

export default Form