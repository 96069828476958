import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Select from '../../components/Select'
import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'


class ChangeMonitorado extends Component {
    state = {
        monitorado: {},
        optionsMonitorado: [],
        redirect: false,
        path: ""
    }

    componentDidMount = () => {
        this.getMonitorados()
    }

    getMonitorados = () => {
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + "/monitorado/"

        axios.get(url, config_me).then(response => {
            let optionsMonitorado = []

            response.data.results.forEach(monitorado => {
                optionsMonitorado.push({ label: monitorado.nome, value: monitorado.id })
            })

            optionsMonitorado = optionsMonitorado?.sort((a, b) => b.label - a.label)

            optionsMonitorado = optionsMonitorado.sort((a, b) => {
                return a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })
            })

            let value = optionsMonitorado.filter(monitorado => monitorado.value == this.props.idMonitorado)

            this.setState({ optionsMonitorado, monitorado: value[0] })
        })
    }

    changeMonitorado = (selectedOption) => {
        this.setState({ monitorado: selectedOption, redirect: true, path: `/status/${selectedOption.value}` })
    }

    render() {
        const { monitorado, optionsMonitorado, redirect, path } = this.state

        if (redirect) {
            history.pushState({}, "", location.href)
            return <Redirect to={{ pathname: '/redirect', state: { pathto: path } }} />
        }

        return (
            <div style={{ width: "200px", margin: "0px 0px 0px 10px" }}>
                <Select
                    value={monitorado}
                    options={optionsMonitorado}
                    onChange={this.changeMonitorado}
                />
            </div>
        )
    }
}

export default ChangeMonitorado