import React, { Component } from 'react'
import Table from '../../components/Table'
import ConfirmDelete from '../../components/ConfirmDelete'
import { Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import cfg from '../../utils/config'
import auth from '../../utils/auth'
import axios from 'axios'

class AutomacaoStatus extends Component {
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
    userInfo = auth.getUserInfo()

    state = {
        data: [],
        loading: true,
        redirectToEdit: false,
        acionamentos: [],
        modal: false,
        limit: 20,
        automacaoId: null,
        lastKey: null,
        end: false,
        isClient: auth.isClient()
    }

    getData = (state) => {
        this.setState({ loading: true, acionamentos: [] })
        let url = `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/automacao/?limit=${state?.lineCount}`

        if (state?.url) {
            url = state.url
        }

        if (this.props.customFilter) {
            url += this.props.customFilter
        }

        axios.get(url, this.configMe).then((res) => {
            let automacoes = res.data?.results ?? []
            let automacoesValidacao = []

            if (this.props.monitorado) {
                this.props.monitorado?.equipamentos?.forEach((equipamento) => {
                    if (equipamento?.validacao) {
                        automacoesValidacao.push(...equipamento?.validacao?.automacoes)
                    }
                })

                automacoes?.forEach(automacao => {
                    let find = automacoesValidacao.find(a => a.automacao_id == automacao.id)

                    if (find) {
                        automacao.execucoes_dia = find.execucoes_dia
                        automacao.esta_ativo = find.esta_ativo
                    }
                })
            }

            this.setState({
                loading: false,
                data: automacoes,
                next: res.data.next,
                count: res.data.count,
                previous: res.data.previous,
            })
        })
    }

    timeFormat = (duration) => {
        if (!duration) return "---"

        let hrs = ~~(duration / 3600)
        let mins = ~~((duration % 3600) / 60)
        let secs = ~~duration % 60
        let ret = ""

        if (mins < 10) mins = "0" + mins

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "")
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "")
        ret += "" + secs

        return ret
    }

    deletar = () => {
        let requestArray = []
        this.setState({ loading: true })

        this.state.table?.state.selected.map((value, index) =>
            requestArray.push(axios.delete(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/automacao/' + value + '/', this.configMe))
        )

        axios.all(requestArray).then(axios.spread((acct, perms) => {
            this.state.table?.fetch()
            this.state.table?.unselect()
        }))
    }

    editar = () => {
        this.setState({
            redirectToEdit: { pathname: "/automacao/" + this.state.table?.state.selected[0] + "/editar" }
        })
        this.state.table?.unselect()
    }

    dateFormat = (timestamp) => {
        if (!timestamp) return "---"
        return new Date(timestamp * 1000).toLocaleDateString("pt-BR", { hour: "numeric", minute: "numeric" })
    }

    getAcionamentos(automacaoId) {
        this.setState({ modal: true })
        let { lastKey, limit, end } = this.state

        if (end) return

        let bodyForm = new FormData()
        bodyForm.append("automacoes", JSON.stringify([automacaoId]))

        if (lastKey != null) {
            bodyForm.append("last_key", JSON.stringify(lastKey))
        }

        axios({
            url: cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/automacao/historico/?limit=${limit}`,
            method: "POST",
            data: bodyForm,
            ...this.configMe
        }).then((res) => {
            let acionamentos = this.state.acionamentos

            if (res.data?.[automacaoId]?.results) {
                this.setState({
                    lastKey: res.data?.[automacaoId]?.lastKey,
                    end: res.data?.[automacaoId]?.lastKey == null
                })

                res.data?.[automacaoId]?.results?.forEach(automacao => {
                    let data = automacao.dados
                    data.timestamp = automacao.timestamp
                    acionamentos.push(data)
                })
            }

            this.setState({ acionamentos })
        }).catch(console.error)
    }

    render() {
        const { redirectToEdit, table, automacaoId, acionamentos, isClient } = this.state

        if (redirectToEdit) {
            this.props.history.push(redirectToEdit)
        }

        const columns = [{
            Header: 'Descrição',
            Accessor: 'descricao',
            sortable: true,
        }, {
            Header: 'Execuções Hoje',
            Acessor: 'execucoes_dia',
            Cell: (original) => <div>{original.execucoes_dia}</div>
        }, {
            Header: 'Ativo',
            Accessor: 'ativo',
            sortable: false,
            Cell: (original) => {
                return original.ativo ?
                    <div style={{ fontSize: "14px" }}>
                        <Badge href="#" color="success">Sim</Badge>
                    </div>
                    :
                    <div style={{ fontSize: "14px" }}>
                        <Badge href="#" color="secondary">Não</Badge>
                    </div>
            }
        }, {
            Header: 'Em execução',
            Accessor: 'esta_ativo',
            sortable: false,
            Cell: (original) => {
                return original.esta_ativo ?
                    <div style={{ fontSize: "14px" }}>
                        <Badge href="#" color="success">Sim</Badge>
                    </div>
                    :
                    <div style={{ fontSize: "14px" }}>
                        <Badge href="#" color="secondary">Não</Badge>
                    </div>
            }
        }, {
            Header: 'Últimos Acionamentos',
            Accessor: 'acionamentos',
            Cell: (original) => (
                <button
                    className="clean-action text-primary clean-btn"
                    style={{ textDecoration: 'none' }}
                    onClick={() => {
                        this.setState({ automacaoId: original?.id, acionamentos: [], lastKey: null, end: false })
                        setTimeout(() => {
                            this.getAcionamentos(original?.id)
                        }, 10)
                    }}>
                    Visualizar
                </button>
            )
        }]

        return (
            <>
                <Modal
                    isOpen={this.state.modal}
                    toggle={() => this.setState({ modal: false })}
                    className="modal-md">
                    <ModalHeader toggle={() => this.setState({ modal: false })}>Acionamentos</ModalHeader>
                    <ModalBody style={{ minHeight: '500px', overflowY: 'auto' }}>
                        <table className="table table-sm afira-table">
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Descrição</th>
                                    <th>Tolerância</th>
                                </tr>
                            </thead>
                            <tbody>
                                {acionamentos.map((acionamento, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{this.dateFormat(acionamento?.timestamp)}</td>
                                            <td>{acionamento?.data?.descricao}</td>
                                            <td>{this.timeFormat(acionamento?.data?.tolerancia)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div
                            className="report-table-pagination mb-0"
                            style={{ display: acionamentos?.length > 0 ? "flex" : "none", margin: "0 auto" }}>
                            <button className="hdv-btn-forms hdv-btn-blue" onClick={() => this.getAcionamentos(automacaoId)}>
                                Carregar Mais
                            </button>
                            <select value={this.state.limit} onChange={(e) => this.setState({ limit: e.target.value })}>
                                <option value="20">20 linhas</option>
                                <option value="50">50 linhas</option>
                                <option value="100">100 linhas</option>
                            </select>
                        </div>
                    </ModalFooter>
                </Modal>

                <div style={{ display: isClient ? "none" : "flex" }} className="hdv-btn-group mt-0">
                    <Link to="/automacao/novo">
                        <button className="hdv-btn-forms hdv-btn-green">Novo</button>
                    </Link>
                    <button onClick={() => { if (table?.state.enableEditar) { this.editar() } }} className={"hdv-btn-forms hdv-btn-yellow " + (table?.state.enableEditar ? "" : "hdv-btn-disable")}>Editar</button>
                    <ConfirmDelete enabled={table?.state.enableDeletar} action={this.deletar} />
                </div>
                <div style={isClient ? { marginTop: "50px" } : null}>
                    <Table
                        checkbox={!isClient}
                        notEditFirstColum={isClient}
                        pathEditFirstColum={"automacao"}
                        data={this.state.data}
                        loading={this.state.loading}
                        count={this.state.count}
                        next={this.state.next}
                        previous={this.state.previous}
                        columns={columns}
                        onFetchData={this.getData}
                        onUpdate={(table) => {
                            this.setState({ table })
                            this.props?.onUpdate(table)
                        }}
                    />
                </div>
            </>
        )
    }
}

export default AutomacaoStatus
