import React, { Component } from "react";
import Select from "../../components/Select";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import axios from "axios";
import auth from "../../utils/auth";
import cfg from "../../utils/config"

class ModalCondicoes extends Component {
    userInfo = auth.getUserInfo()

    state = {
        data: [],
        condicoes: [],
        detalhes: { descricao: null, cor: "#000000" },
        optionsConditions: [
            { label: "Igual (=)", value: "igual", symbol: "=" },
            { label: "Maior ou Igual (>=)", value: "maior_igual", symbol: ">=" },
            { label: "Menor ou Igual (<=)", value: "menor_igual", symbol: "<=" },
            { label: "Maior (>)", value: "maior", symbol: ">" },
            { label: "Menor (<)", value: "menor", symbol: "<" },
            { label: "Diferente (!=)", value: "diferente", symbol: "!=" }
        ],
        optionsBooleano: [
            { label: "Verdadeiro", value: true },
            { label: "Falso", value: false },
        ],
        cooldown: false,
        idEdit: false
    }

    componentDidMount() {
        this.getCondicoes()
    }

    getCondicoes = () => {
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/monitorado/' + this.props.monitoradoId + '/condicao/', config_me)
        .then((response) => {
            this.setState({data: response.data.results})
        })
    }

    handleChange = (field, value) => {
        const detalhes = Object.assign({}, this.state.detalhes)
        detalhes[field] = value

        if(field === "descricao") {
            document.querySelector("#validacao-descricao").innerHTML = ""
        }

        this.setState({ detalhes })
    }

    changeFieldConditions = (field, value, index, tipo_dado_saida = false) => {
        const condicoes = [...this.state.condicoes]
       
        if(field === "valor") {
            if(tipo_dado_saida === "decimal") {
                value = value.replace(/\D/g, '')
                value = (value / 100).toFixed(2) + ''
                value = value.replace(" ", ",")
                value = value.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2,$3,")
                value = value.replace(/(\d)(\d{3}),/g, "$1.$2,")
            } else if (tipo_dado_saida === "inteiro") {
                value = value.replace(/\D/g, '')
            }

        } else if (field === "parametro") {
            condicoes[index]["valor"] = value.tipo_dado_saida === "booleano" ? false : null
            condicoes[index]["condicao"] = null
        }


        condicoes[index][field] = value
    
        document.querySelector(`#validacao-${field}-${index}`).innerHTML = ""

        this.setState({ condicoes })
    }
    
    createNewCondition = () => {
        let condicoes = [...this.state.condicoes]

        condicoes.push({ parametro: null, condicao: null, valor: null })

        this.setState({ condicoes })
    }

    removeCondition = (keyDelete) => {
        let condicoes = [...this.state.condicoes]

        condicoes = condicoes.filter((condicao, cIndex) => cIndex !== keyDelete)

        this.setState({ condicoes })
    }


    add = () => {
        const { detalhes, condicoes, idEdit } = this.state

        if(this.validationFieldsConditions()) {
            return
        }

        if(detalhes.descricao === null || detalhes.descricao === "") {
            document.querySelector("#validacao-descricao").innerHTML = "Campo não pode ser nulo!"
            return
        }

        this.setState({ cooldown: true })

        let userInfo = auth.getUserInfo()
        let requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/' + this.props.monitoradoId + '/condicao/'
        let verb = 'POST'

        if(idEdit) {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/' + this.props.monitoradoId + '/condicao/' + `${idEdit}/`
            verb = 'PATCH'
        }

        const data = {
            nome: detalhes.descricao,
            condicoes: {
                cor: detalhes.cor,
                regras: condicoes
            },
            parametros: condicoes.map((condicao) => condicao.parametro.value)
        }
        
        axios({
            method: verb,
            url: requestURL,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((response) => {
            this.close()
            this.getCondicoes()
        })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    edit = (position, condicaoId) => {
        const data = structuredClone(this.state.data)

        this.props.toggleModal(true)

        this.setState({
            detalhes: { descricao: data[position].nome, cor: data[position].condicoes.cor },
            condicoes: data[position].condicoes.regras,
            idEdit: condicaoId
        })
    }

    delete = (condicaoId) => {
        let userInfo = auth.getUserInfo()
        let requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado/' + this.props.monitoradoId + '/condicao/' + `${condicaoId}/`
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.delete(requestURL, config_me)
        .then(() => {
            this.getCondicoes()
        })
    }

    close = () => {
        this.props.toggleModal(false)
        this.setState({
            condicoes: [],
            detalhes: { descricao: null, cor: "#000000" },
            optionsParametro: [],
            idEdit: false
        })
    }

    validationFieldsConditions = () => {
        const { condicoes } = this.state
        let erro = false

        condicoes.forEach((condicao, cIndex) => {
            for(let i in condicao) {
                if(condicao[i] === null || condicao[i] === ""){
                    document.querySelector(`#validacao-${i}-${cIndex}`).innerHTML = "Campo não pode ser nulo!"
                    erro = true
                } else {
                    document.querySelector(`#validacao-${i}-${cIndex}`).innerHTML = ""
                }
            }
        })

        return erro
    }

    render() {
        const { data, condicoes, detalhes, optionsConditions, cooldown, idEdit, optionsBooleano } = this.state

        return(
            <>
                <Modal style={{minWidth: "70%"}} isOpen={this.props.status} toggle={this.close}>
                    <ModalHeader toggle={this.close}>{idEdit ? "Editar Condição" : "Nova Condição"}</ModalHeader>
                    <ModalBody>
                        <div className="hdv-form-default">

                            <div className="row">
                                <div className="col-md-4">
                                    <label className="required">Descrição</label>
                                    <input 
                                        type="text" 
                                        onChange={(e) => this.handleChange("descricao", e.target.value)} 
                                        value={detalhes.descricao ?? ""}
                                    />
                                    <span id="validacao-descricao"></span>
                                </div>
                                <div className="col-md-1">
                                    <label className="required">Cor</label>
                                    <input 
                                        type="color" 
                                        style={{height: "44px", width: "100%", borderStyle: "none"}}
                                        onChange={(e) => this.handleChange("cor", e.target.value)}
                                        value={detalhes.cor}
                                    />
                                </div>
                                <div className="col-md-7 d-flex align-items-end justify-content-end">
                                    <button className="hdv-btn-forms hdv-btn-green" onClick={this.add} disabled={condicoes.length === 0 || cooldown}>
                                        { idEdit ? "Atualizar" : "Adicionar" }
                                    </button>
                                    <button disabled={cooldown} className="hdv-btn-forms hdv-btn-yellow" onClick={this.close} style={{marginRight: "0px"}}>Cancelar</button>
                                </div>
                            </div>

                            <fieldset className="hdv-fieldset">
                                <legend className="hdv-legend">Condições</legend>
                                {
                                    condicoes.map((condicao, cIndex) => {
                                        let tipo_dado_saida = condicao.parametro?.tipo_dado_saida ?? "decimal" 
                                        let valueBooleano = {}

                                        if(tipo_dado_saida === "booleano") {
                                            let valor = condicoes[cIndex].valor

                                            valueBooleano = optionsBooleano.filter((item) => { if(item.value === valor) return item })[0]
                                        }

                                        return(
                                            <React.Fragment key={cIndex}>
                                                <div className="row automacao-alerta-condicao">
                                                    <div className="row col-md-12 mx-0 my-0 pb-4">
                                                        <button onClick={() => this.removeCondition(cIndex)} className="hdv-btn-forms hdv-btn-red hdv-automacao-delete-button">
                                                            <i className="fa fa-times fa-2x"></i>
                                                        </button>
                                                        <div className="col-md-4">
                                                            <label className="required">Parâmetro:</label>
                                                            <Select
                                                                value={condicoes[cIndex]["parametro"]}
                                                                options={this.props.parametros}
                                                                onChange={(e) => this.changeFieldConditions("parametro", e, cIndex)}
                                                            />
                                                            <span id={`validacao-parametro-${cIndex}`}></span>
                                                        </div>  
                                                        <div className="col-md-4">
                                                            <label className="required">For:</label>
                                                            <Select
                                                                value={condicoes[cIndex]["condicao"]}
                                                                onChange={(e) => this.changeFieldConditions("condicao", e, cIndex)}
                                                                options={
                                                                    optionsConditions.filter((item) => {
                                                                        let parametro = condicoes[cIndex]["parametro"]
                                                                        let symbols = ["=", "!="]
                                                                        
                                                                        if(parametro?.tipo_dado_saida === "booleano" || parametro?.tipo_dado_saida === "string") {
                                                                            if(symbols.includes(item.symbol)) return item
                                                                        } else {
                                                                            return item
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                            <span id={`validacao-condicao-${cIndex}`}></span>
                                                        </div>  
                                                        
                                                        <div className="col-md-4">
                                                            <label className="required">Valor:</label>
                                                            {
                                                                tipo_dado_saida === "booleano" ?
                                                                    <Select 
                                                                        value={valueBooleano}
                                                                        options={optionsBooleano}
                                                                        onChange={(e) => 
                                                                            this.changeFieldConditions("valor", e.value, cIndex, tipo_dado_saida)
                                                                        }
                                                                    />
                                                                :
                                                                    <input 
                                                                        type="text"
                                                                        value={condicoes[cIndex]["valor"] ?? ""}
                                                                        onChange={(e) => 
                                                                            this.changeFieldConditions("valor", e.target.value, cIndex, tipo_dado_saida)
                                                                        }
                                                                    />
                                                            }
                                                            <span id={`validacao-valor-${cIndex}`}></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {cIndex === condicoes.length - 1 ? null : <div className="and-operation" style={{ left: "0px" }}><span>E</span></div>}
                                            </React.Fragment>
                                        )
                                    })
                                }

                                <div className="row">
                                    <div className="col-md-5">
                                        <button onClick={this.createNewCondition} className="hdv-btn-forms hdv-btn-blue hdv-automacao-add-new-button d-flex align-items-center">
                                            <i className="fa fa-plus-circle fa-2x"></i> &nbsp; Nova condição
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </ModalBody>
                </Modal>

                <button className="hdv-btn-forms hdv-btn-blue" onClick={() =>  this.props.toggleModal(true)}>Nova Condição</button>
                
                <h6 className="hdv-subtable-title">Lista de Condições</h6>

                <table className="table afira-table">
                    <thead>
                        <tr>
                            <th>Descrição</th>
                            <th>Cor</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, index) => {
                                return(
                                    <tr>
                                        <td>{item.nome}</td>
                                        <td>
                                            <div style={{ width: "40px", height: "40px", backgroundColor: item.condicoes.cor, borderRadius: "10px" }}></div>
                                        </td>
                                        <td className="clean-action" style={{ width: "40%" }}>
                                            <button onClick={() => this.edit(index, item.id)} className="hdv-btn-forms hdv-btn-yellow">Editar</button>
                                            <button onClick={() => this.delete(item.id)} className="hdv-btn-forms hdv-btn-red">Remover</button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>
        )
    }
}

export default ModalCondicoes