import React, { Component } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import mascaraParametrizacao from '../../components/MascaraParametrizacao'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ReactDOMServer from 'react-dom/server'
import socketSettings from '../../utils/socket/socketSettings'
import ObservacaoGrid from './Observacao/grid'
import { Redirect } from 'react-router-dom'
import auth from '../../utils/auth'
import { divIcon } from 'leaflet'
import Mapa from '../../components/Mapa'
import config from '../../utils/config'
import Alerta from '../Alerta'
import axios from 'axios'

class Dashboard extends Component {
    mapRef = React.createRef()

    state = {
        markersState: { warning: true, off: true, on: true, danger: true },
        filter: { toggle: false, value: "", filtered: [] },
        permissions: [],
        position: [-17.408282, -53.117169],
        markers: [],
        activeMarkers: [],
        atualizados: 0,
        monitorado: null,
        inativos: 0,
        loading: true,
        alertas: 0,
        semcomunicacao: 0,
        tabIndex: 0,
        modal: false,
        mapFilter: null,
        redirect: null,
        enableCluster: true
    }

    componentWillUnmount() {
        socketSettings.disconnectRoom("close.dashboard", "join.dashboard", "update.dashboard")
    }

    componentDidMount = () => {
        this.buscarDadosMapa()
        
        setTimeout(() => {
            const button = document.createElement('button')
            const div = document.createElement('div')

            button.setAttribute('class', 'leaflet-control-custom')

            button.onclick = () => {
                this.setState({ filter: { ...this.state.filter, toggle: !this.state.filter.toggle }, mapFilter: null })
            }

            button.innerHTML = ReactDOMServer.renderToString(
                <i
                    style={{ transform: 'scale(1)' }}
                    className={this.state.filter.toggle ? "fa fa-times fa-2x" : "fa fa-search fa-2x"}>
                </i>
            )

            div.setAttribute('class', 'map-layer-ctrl')

            div.innerHTML = ReactDOMServer.renderToString(
                <>
                    <button id='desagrupar' className='selected'>Desagrupar</button>
                    <button id='agrupar'>Agrupar</button>
                </>
            )

            div.style = 'left: 12px; width: fit-content; top: calc(100% - 40px)'

            document.querySelector('.leaflet-control-container').appendChild(button)
            document.querySelector('.leaflet-control-container').appendChild(div)

            document.getElementById('desagrupar').addEventListener('click', () => this.changeClusterMap(true))
            document.getElementById('agrupar').addEventListener('click', () => this.changeClusterMap(false))
        }, 150)

        let permissions = []
        if (["admin", "master"].includes(auth.getUserInfo()?.['tipo'])) {
            this.setState({ permissions: ["alerta", "observacao"] })
            return
        }

        let endpoints = auth.getUserInfo()?.menu?.find(m => m.item === "base")?.endpoints ?? []

        if (endpoints.includes("/observacao")) permissions.push("observacao")
        if (endpoints.includes("/alerta")) permissions.push("alerta")

        this.setState({ permissions })
    }

    changeClusterMap = (enableCluster) => {
        let desagrupar = document.getElementById('desagrupar')
        let agrupar = document.getElementById('agrupar')
       
        if(enableCluster) {
            desagrupar.classList.add('selected')
            agrupar.classList.remove('selected')
        } else {
            desagrupar.classList.remove('selected')
            agrupar.classList.add('selected')
        }

        this.setState({enableCluster})
    }

    calcDifferenceInDays(timestamp) {
        const ultimoReporte = new Date(timestamp * 1000)
        const diffTime = (new Date().getTime() - ultimoReporte.getTime())
        let days = (diffTime / (1000 * 60 * 60 * 24)).toFixed(2)
        return days
    }

    buscarDadosMapa = async () => {
        let configUser = auth.getUserInfo()
        let url = config.base_api_url + config.api_v2 + "/" + configUser['empresa'] + "/dados-dashboard/"
        let headers = {
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }

        await axios.get(url, headers).then((res) => {
            let markers = []
            let atualizados = 0
            let alertas = 0
            let inativos = 0
            let semcomunicacao = 0

            res.data?.forEach((monitorado) => {
                let status = "off"
                let days = 1

                if (monitorado?.campos?.length > 0) {
                    if (monitorado?.ultimo_evento !== null) {
                        try {
                            days = this.calcDifferenceInDays(monitorado.ultimo_evento.timestamp_dispositivo)
                        } catch (err) {
                            console.error(err)
                        }

                        if (days >= 1) {
                            status = 'danger'
                            semcomunicacao++
                        } else {
                            if (!monitorado.ultimo_evento) {
                                status = 'danger'
                                semcomunicacao++
                            } else {
                                atualizados++
                                status = 'on'
                            }
                        }
                    }
                } else {
                    inativos++
                }

                if (monitorado.tem_alerta) {
                    status = 'warning'
                    alertas++
                }

                let existeMonitorado = markers.filter((marker) => marker.id === monitorado.id)

                if (existeMonitorado.length === 0) {
                    markers.push({
                        id: monitorado.id,
                        latitude: monitorado.latitude,
                        longitude: monitorado.longitude,
                        identificacao: monitorado.nome,
                        icon: monitorado.tipo__icone,
                        defaultColor: monitorado.tipo__default_cor,
                        alertas: monitorado?.alertas ?? [],
                        tem_alerta: monitorado.tem_alerta,
                        status: status,
                        nome: monitorado.nome,
                        campos: monitorado?.campos,
                        condicoes: monitorado.condicoes,
                        iconElement: this.createDivIcon(monitorado, status, monitorado.tipo__icone),
                        click: () => {
                            this.setState({ monitorado })
                            this.toggleModal()
                        }
                    })
                } else {
                    let markerExist = existeMonitorado?.[0]
                    delete markers[markerExist]

                    markers.push(markerExist)
                }
            })

            if (markers.length > 0) {
                this.prepareDataForSocket(markers)
            }

            this.setState({ alertas, semcomunicacao, inativos, atualizados, markers, activeMarkers: markers })
        }).catch(console.error)

        this.setState({ loading: false })
    }

    prepareDataForSocket = (markers) => {
        let devices = []

        markers.forEach((marker, index) => {
            marker.campos.forEach((parametro) => devices.push(parametro.campo__equipamento__usuario_mqtt))
        })

        devices = Array.from(new Set(devices))

        const methodsUpdate = [
            { descriptionMethod: "update_markers", parameters: { markers, sendMarkers: this.receiveMarkersSocket, createDivIcon: this.createDivIcon } }
        ]

        if (devices.length > 0) {
            socketSettings.connectRoom("join.dashboard", "update.dashboard", devices, methodsUpdate)
        }
    }

    receiveMarkersSocket = (markers) => {
        let { markersState } = this.state

        let keys = Object.entries(markersState).map(m => m[1] ? m[0] : null)?.filter(m => m)
        markers = markers.filter(marker => keys.includes(marker.status))

        this.setState({activeMarkers: markers})
    }  

    updateMarkers(key) {
        let { markersState, markers } = this.state
        let activeMarkers = []
        markersState[key] = !markersState[key]

        this.setState({ markersState })

        setTimeout(() => {
            let keys = Object.entries(markersState).map(m => m[1] ? m[0] : null)?.filter(m => m)

            activeMarkers = markers?.filter(marker => keys.includes(marker.status))

            this.setState({ activeMarkers })
        }, 0)
    }

    createDivIcon = (monitorado, status, icon) => {

        const colorStatus = {
            on: "#44BB52",
            warning: "#EED323",
            danger: "#e75c4f",
            off: "#444444"
        }

        return divIcon({
            className: 'default-div-icon',
            iconSize: [32, 64],
            iconAnchor: [16, 64],
            popupAnchor: [0, -64],
            html: ReactDOMServer.renderToString(
                <div className="default-marker-container" style={{ backgroundColor: monitorado.tipo__cor }} title={monitorado?.nome}>
                    <div className="default-marker-status" style={{ backgroundColor: colorStatus[status] }}></div>
                    <i className={icon + " la-2x"}></i>
                    <div className="default-marker-gradient"></div>
                    <div style={{ backgroundColor: monitorado.tipo__cor }} className="default-marker-triangle"></div>
                </div>
            ),
        })
    }

    filterMarkers = (e) => {
        let { markers, filter } = this.state
        if (e.target.value.length !== 0) {
            const filtered = markers.filter(marker => marker.nome?.toLowerCase()?.startsWith(e.target.value?.toLowerCase()))
            filter['filtered'] = filtered
        } else {
            filter['filtered'] = []
        }

        filter['value'] = e.target.value
        this.setState({ filter })
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,
            selected: [],
            tabIndex: 0,
            data: [],
            erros: [],
        })
    }

    renderClientList() {
        let { filter } = this.state
        let markers = filter.filtered.length > 0 ? filter.filtered : this.state.markers
        let elements = []
        const status = {
            'on': '#2ad167',
            'off': '#424242',
            'warning': '#ffb142',
            'danger': '#cf5b4e'
        }

        markers?.forEach((marker, index) => {
            elements.push(
                <li
                    key={index}
                    onClick={() => {
                        let { filter } = this.state
                        filter["toggle"] = false

                        if (this.mapRef != null) {
                            this.mapRef.current.setView([marker?.latitude, marker?.longitude], 9)
                        }

                        this.setState({ filter, position: [marker?.latitude, marker?.longitude] })
                    }}>
                    <span className="badge" style={{ backgroundColor: status[marker.status] }}>&nbsp;</span>
                    <span>{marker?.nome}</span>
                </li>
            )
        })

        return elements
    }

    redirect = (to) => {
        history.pushState({}, "", location.href)
        this.setState({ redirect: to })
    }

    render() {
        const { redirect, markersState, monitorado, permissions } = this.state

        if (redirect) {
            return <Redirect to={redirect} />
        }

        return (
            <div className="hdv-render-div home-style">
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="hdv-observacao-modal">
                    <ModalHeader toggle={this.toggleModal}>{this.state.monitorado?.nome}</ModalHeader>
                    <ModalBody style={{ padding: '12px', minHeight: '450px' }}>
                        <Tabs
                            selectedIndex={this.state.tabIndex}
                            onSelect={tabIndex => this.setState({ tabIndex, selected: [], enableEditar: false, enableDeletar: false })}>
                            <TabList>
                                <Tab>Informações</Tab>
                                {permissions.includes("observacao") ? <Tab>Observações</Tab> : null}
                                {permissions.includes("alerta") ? <Tab>Alertas</Tab> : null}
                            </TabList>
                            <TabPanel>
                                {this.state.monitorado?.campos?.length > 0
                                    ?
                                    <div className="grid-informacoes-dashboard">
                                        {this.state.monitorado?.campos.map((campo, key) => {
                                            let mascara = campo.campo__mascara
                                            let time = "---"
                                            let status = "---"
                                            let value = mascara === "padrao" ? campo?.ultimo_evento?.[campo.campo__identificacao] ?? "" : mascaraParametrizacao[mascara](campo?.ultimo_evento?.[campo.campo__identificacao] ?? "")
                                            let icone = null

                                            if (campo.ultimo_evento && campo.ultimo_evento?.timestamp_dispositivo > 0) {
                                                let date = new Date(campo.ultimo_evento.timestamp_dispositivo * 1000)
                                                let difference = this.calcDifferenceInDays(campo.ultimo_evento.timestamp_dispositivo)

                                                if (difference >= 1) {
                                                    status = "Desatualizado"
                                                    time = (
                                                        <span className="last-report-span" style={{ backgroundColor: "#d6584f" }}>
                                                            {date.toLocaleString("pt-BR").replace(",", " ")}
                                                        </span>
                                                    )
                                                } else if (difference < 1) {
                                                    status = "Atualizado"
                                                    time = (
                                                        <span className="last-report-span" style={{ backgroundColor: "#3c9956" }}>
                                                            {date.toLocaleString("pt-BR").replace(",", " ")}
                                                        </span>
                                                    )
                                                } else {
                                                    time = (
                                                        <span className="last-report-span" style={{ backgroundColor: "#636a74" }}>
                                                            {date.toLocaleString("pt-BR").replace(",", " ")}
                                                        </span>
                                                    )
                                                }
                                            }

                                            try {
                                                icone = JSON.parse(campo?.campo__icone)?.icone_on
                                            } catch (err) {

                                            }

                                            return (
                                                <div key={key} className="grid-informacoes-dashboard-card">
                                                    <div className="px-3 py-2">
                                                        <h5>{campo?.campo__descricao}</h5>
                                                        <p><strong>Equipamento: </strong>
                                                            {campo?.campo__equipamento__imei ?
                                                                <span
                                                                    style={{ textDecoration: "underline", cursor: "pointer" }}
                                                                    onClick={() => this.redirect(`/equipamento/${campo?.campo__equipamento}/editar`)}
                                                                >
                                                                    {campo?.campo__equipamento__imei}
                                                                </span>
                                                                :
                                                                "---"
                                                            }
                                                        </p>
                                                        <p><strong>Valor: </strong>{value + " " + campo?.campo__unidade}</p>
                                                        <p><strong>Status: </strong> {status}</p>
                                                        <p><strong>Último Evento: </strong>{time}</p>
                                                    </div>
                                                    {icone ? <i className={`fa ${icone}`}></i> : null}
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                    :
                                    <p className="pt-5" style={{ fontSize: "15px", opacity: "0.8", textAlign: "center" }}>
                                        Não há parâmetros cadastrados
                                    </p>
                                }
                            </TabPanel>
                            {permissions.includes("observacao")
                                ?
                                <TabPanel>
                                    {monitorado ? <ObservacaoGrid monitorado={monitorado?.id} /> : null}
                                </TabPanel>
                                :
                                null
                            }
                            {permissions.includes("alerta")
                                ?
                                <TabPanel>
                                    <Alerta
                                        compact
                                        customFilter={`&monitorado=${monitorado?.id}`}
                                        data={monitorado?.alertas ?? []}
                                    />
                                </TabPanel>
                                :
                                null
                            }
                        </Tabs>
                    </ModalBody>
                    <ModalFooter className={this.state.tabIndex === 0 ? "" : "hdv-noshow-item"}>
                        <div className="hdv-btn-group">
                            <button
                                onClick={() => this.redirect(`status/${monitorado.id}`)}
                                className="hdv-btn-forms hdv-btn-blue d-flex align-items-center">
                                <i className="fa fa-external-link-alt fa-2x"></i>
                                Status
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>

                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Meus locais</h4>
                            <div className="gray-background">
                                <i className="fa fa-map fa-2x"></i>
                            </div>
                        </span>
                    </div>

                    <div>
                        <div className={`div-sample marker-filter marker-first-info ${!markersState.on ? 'disabled' : ''}`} onClick={() => this.updateMarkers("on")}>
                            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                                <div className="hdv-info-bloco hdv-info-bloco-active">
                                    <div className="hdv-title-bloco">
                                        <i className="fa fa-check-circle hdv-icon-position-homebox"></i>
                                        <span className="hdv-icon-text-homebox">Atualizados</span>
                                    </div>
                                    <div className="hdv-body-bloco">{this.state.atualizados}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`div-sample marker-filter marker-mid-info ${!markersState.warning ? 'disabled' : ''}`} onClick={() => this.updateMarkers("warning")}>
                            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                                <div className="hdv-info-bloco hdv-info-bloco-alert">
                                    <div className="hdv-title-bloco">
                                        <i className="fa fa-exclamation-triangle hdv-icon-position-homebox"></i>
                                        <span className="hdv-icon-text-homebox">Alertas</span>
                                    </div>
                                    <div className="hdv-body-bloco">{this.state.alertas}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`div-sample marker-filter marker-mid-info ${!markersState.danger ? 'disabled' : ''}`} onClick={() => this.updateMarkers("danger")}>
                            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                                <div className="hdv-info-bloco hdv-info-bloco-sem-comunicacao">
                                    <div className="hdv-title-bloco">
                                        <i className="fa fa-wifi hdv-icon-position-homebox"></i>
                                        <span className="hdv-icon-text-homebox">Sem comunicação</span>
                                    </div>
                                    <div className="hdv-body-bloco">{this.state.semcomunicacao}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`div-sample marker-filter marker-last-info ${!markersState.off ? 'disabled' : ''}`} onClick={() => this.updateMarkers("off")}>
                            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                                <div className="hdv-info-bloco hdv-info-bloco-off">
                                    <div className="hdv-title-bloco">
                                        <i className="fa fa-power-off hdv-icon-position-homebox"></i>
                                        <span className="hdv-icon-text-homebox">Inativos</span>
                                    </div>
                                    <div className="hdv-body-bloco">{this.state.inativos}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Mapa
                        position={this.state.position}
                        filter={this.state.mapFilter}
                        layerControl={true}
                        height="calc(100vh - 348px)"
                        markers={this.state.activeMarkers}
                        style={{ minHeight: `calc(100vh - 348px)`, top: "190px" }}
                        onLoad={(ref) => this.mapRef = ref}
                        clusterEnabled={this.state.enableCluster}
                    />

                    {this.state.filter.toggle
                        ?
                        <div className="map-marker-toggle">
                            <div className="marker-toggle-list">
                                <div className="d-flex align-items-center">
                                    <button
                                        style={{ padding: "4px 5px", marginTop: "7px", marginLeft: "8px", borderRadius: "8px" }}
                                        onClick={() => {
                                            let { filter } = this.state
                                            filter["toggle"] = false
                                            this.setState({ filter })
                                        }}>
                                        <i className="fa fa-times fa-2x"></i>
                                    </button>
                                    <input value={this.state.filter.value} type="text" placeholder="Pesquisar" onChange={this.filterMarkers} />
                                </div>
                                <ul className="pb-4">
                                    {this.renderClientList()}
                                </ul>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}

export default Dashboard