import React, { Component } from 'react'
import { Switch } from 'react-router-dom'

import MenuBar from '../../components/MenuBar'
import MenuSystem from '../../components/MenuSystem'

import PrivateRoute from '../../containers/PrivateRoute'
import Dashboard from '../../containers/Dashboard'
import routes from '../../components/RotasSistema'
import Tela404 from '../../components/Tela404'

import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'

import socketSettings from '../../utils/socket/socketSettings'

class Layout extends Component {

	state = {
		opacity: 0,
		logomarca: '',
		module_selected: 'base',
		modulos: [],
		rotas: [],
		checked: { base: true, ambiental: false, agua: false }
	}

	componentDidMount = () => {
		socketSettings.connect("https://skt.afira.io/")

		this.setFavicon(null)
		this.getModuleState()
		let requestArray = []
		let userInfo = auth.getUserInfo()
		let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

		requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo?.empresa + '/configuracao-cores/', config_me))
		requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo?.empresa + '/configuracao-sistema/', config_me))
		requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/me/', config_me))

		axios.all(requestArray).then(axios.spread((cores_cfg, config, me) => {
			let cores = {}
			let logomarca = ''
			let favicon = ''

			if (cores_cfg.status === 200) {
				cores = cores_cfg.data.results?.[0]
			}

			if (config.status === 200) {
				logomarca = config.data.results?.[0]?.logomarca
				favicon = config.data.results?.[0]?.favicon

				if (favicon) {
					this.setFavicon(favicon)
				} else {
					this.setFavicon("/favicon.ico")
				}
			}

			if (cores) {
				let cssVars = document.querySelector(':root')

				cssVars.style.setProperty('--background-logomarca', cores.back_logomarca)
				cssVars.style.setProperty('--background-menu', cores.back_menu_lateral)
				cssVars.style.setProperty('--fonte_menu_lateral', cores.fonte_menu_lateral)
				cssVars.style.setProperty('--fonte_grupo_menu_lateral', cores.fonte_grupo_menu_lateral)
				cssVars.style.setProperty('--fonte_titulo_abas', cores.degrade_top_primeira_cor)
				cssVars.style.setProperty('--filtro_logo', cores.filtro_logo ? 'brightness(0) invert(1)' : '')
			}

			try {
				userInfo.permissions = me.data?.permissions ?? {}
				auth.setUserInfo(userInfo, true)
			} catch (err) {
				console.error(err)
			}

			this.setState({ opacity: 1, logomarca, modulos: me?.data?.menu })
		}))

		this.createRoutes()
	}

	createRoutes = () => {
		const revenda = auth.isPai()
		const data = auth.getUserInfo()
		const permissoes = auth.getUserInfo()?.permissions ?? []
		const rotas_permitidas = []

		routes.forEach((rota) => {
			if (auth.isMasterOrAdmin()) {
				if(rota.route === "/empresa") {
					if(revenda && data.pai !== null || !revenda) {
						return
					}
				}

				rotas_permitidas.push(rota.component)
			} else if (auth.isClient()) {
				if (rota.cliente != false && rota?.route in permissoes) {
					rotas_permitidas.push(rota.component)
				}
			} else if (rota?.route in permissoes) {
				rotas_permitidas.push(rota.component)
			}
		})

		this.setState({ rotas: rotas_permitidas })
	}

	setFavicon = (link = null) => {
		let favicon = document.querySelector('link[rel="icon"]')

		if (favicon !== null) {
			favicon.href = link
		} else {
			favicon = document.createElement("link")
			favicon.rel = "icon"
			favicon.href = link
			document.head.appendChild(favicon)
		}
	}

	getModuleState = () => {
		try {
			const key = "__hidroview__modules"
			if (localStorage.getItem(key)) {
				const checked = JSON.parse(localStorage.getItem(key))?.checked
				this.setState({ checked })
			}
		} catch (err) {
			console.error(err)
		}
	}

	menuToggle = () => {
		document.querySelector(".fixed-content").classList.toggle("fixed-toggle")
		document.querySelector(".fluid").classList.toggle("fluid-toggle")
		window.dispatchEvent(new Event('resize'))
	}

	moduleToggle = (e) => {
		try {
			let moduloChange = e.target.id.split(`modulo-`)
			let modulo = moduloChange[1]
			let checked = Object.assign({}, this.state.checked)
			const key = "__hidroview__modules"

			for (let i in checked) {
				if (i === modulo) {
					checked[modulo] = true
				} else {
					checked[i] = false
				}
			}

			this.setState({ checked })

			if (localStorage.getItem(key)) {
				localStorage.setItem(key, JSON.stringify({ ...JSON.parse(localStorage.getItem(key)), checked }))
			} else {
				localStorage.setItem(key, JSON.stringify({ checked }))
			}
		} catch (err) {
			localStorage.removeItem(key)
			console.error(err)
		}

		setTimeout(() => this.setState({ modulos: this.state.modulos }), 10)
	}

	renderModules = () => {
		let { checked, modulos } = this.state
		let children = []
		
		if (modulos?.length >= 1) {
			modulos.forEach((modulo, key) => {
				if (auth.isMasterOrAdmin() || modulo.endpoints?.length > 0) {
					children.push(
						<span key={key} className={checked[modulo.item] ? "toggler modulo-selecionado" : "toggler"}>
							<i id={'modulo-' + modulo.item} onClick={this.moduleToggle} className={modulo.icone + " fa-2x"}></i>
							<small>{modulo.descricao}</small>
						</span>
					)
				}
			})

			return children
		}

		return children.length > 1 ? children : null
	}

	render() {
		const { rotas } = this.state
		return (
			<div style={{ opacity: this.state.opacity }}>
				<div className="hdv-app-container">
					<div className="fixed">
						<section className="hdv-module-toggle">
							<button
								onClick={this.menuToggle}
								style={{
									position: 'absolute',
									border: 'none',
									top: '10px',
									color: 'var(--fonte_menu_lateral)',
									backgroundColor: 'transparent',
									cursor: "pointer"
								}}>
								<i className="fa fa-bars fa-30px"></i>
							</button>

							{this.renderModules()}
						</section>
						<section className="fixed-content" style={{ backgroundColor: "transparent" }}>
							<div className="hdv-logomarca">
								<img
									crossOrigin="anonymous"
									src={(this.state.logomarca) ? this.state.logomarca : ''}
									alt="Logomarca"
									id="logomarca"
								/>
							</div>
							<div className="hdv-menu-bloco scroll-parent">
								<MenuSystem
									modulo={this.state.checked}
									pathCurrent={this.props.location.pathname}
								/>
							</div>
						</section>
					</div>
					<div className="fluid">
						<MenuBar myMenu={document.getElementsByClassName("fixed")} myBody={document.getElementsByClassName("fluid")} />
						<div className="hdv-body-app">
							<div className="hdv-conteudo">
								<div className="row">
									<Switch>
										<PrivateRoute exact path='/' component={Dashboard} />

										{rotas.map((rota) => rota)}

										<PrivateRoute path="*" component={Tela404} />
									</Switch>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Layout