import React, { Component } from 'react'
import { Badge } from 'reactstrap'
import Table from '../../components/Table'
import ProgressAcessos from '../../components/ProgressAcessos'
import { Link, Redirect } from 'react-router-dom'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import GerenciarModulos from './gerenciarModulos'

class AcessoModulo extends Component {
    state = {
        data: [],
        loading: true,
        tab: 0,
        typeUser: auth.getUserInfo().tipo,
        isCliente: auth.getUserInfo()?.cliente,
        isPai: auth.isPai()
    }

    getData = (state) => {
        this.setState({ loading: true })
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/acesso/modulo/?limit=${state.lineCount}` + state.filter  + "&meus_acessos_modulo=true"

        if (state.url) {
            url = state.url
        }

        axios.get(url, config_me).then((res) => {
            this.setState({
                loading: false,
                data: res.data.results,
                next: res.data.next,
                count: res.data.count,
                previous: res.data.previous,
            })
        })
    }

    handleTab = (index) => {
        this.setState({ tab: index })
    }

    render() {
        const { redirectToEdit, typeUser, isPai, isCliente } = this.state
        if (redirectToEdit) {
            return <Redirect to={redirectToEdit} />
        }

        const columns = [{
            Header: "Empresa",
            Accessor: "empresa.nome_fantasia",
        },{
            Header: 'Tipo de Acesso',
            Accessor: 'acesso_tipo.descricao',
        }, {
            Header: 'Cliente',
            Accessor: 'cliente.nome_fantasia',
            Cell: (original) => {
                if(original?.cliente?.nome_fantasia) {
                    return(
                        <Link to={`/cliente/${original?.cliente?.id}/editar/?modulo=true`}>
                            {original?.cliente?.nome_fantasia}
                        </Link>
                    )
                } else {
                    return <span>---</span>
                }
            }
        }, {
            Header: 'Ativo',
            Accessor: 'ativo',
            sortable: false,
            Cell: (original) => {
                return original.ativo ?
                    <Badge color="success">Sim</Badge>
                    :
                    <Badge color="secondary">Não</Badge>
            }
        }, {
            Header: 'Data do Cancelamento',
            Accessor: 'data_cancelamento',
            sortable: false,
            Cell: (original) => {
                if (original.data_cancelamento) {
                    let date = new Date(Date.parse(original.data_cancelamento))
                    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }

                    return <span>{new Intl.DateTimeFormat('pt-Br', options).format(date)}</span>
                } else {
                    return <span>---</span>
                }
            }
        }]

        return (
            <div className="hdv-render-div">
                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Controle de Acessos à Modulos</h4>
                            <div className="gray-background">
                                <i className="fa fa-key fa-2x"></i>
                            </div>
                        </span>
                    </div>
                    <Tabs className="tabs-style" selectedIndex={this.state.tab} onSelect={(i) => this.handleTab(i)}>
                        <TabList>
                            <Tab>Meus Acessos</Tab>
                            {
                                isPai && !isCliente && typeUser != 'normal' && auth.getUserInfo().pai === null ?
                                    <Tab>Gerenciar Acessos</Tab>
                                : null
                            }
                        </TabList>
                        <TabPanel> 
                            <ProgressAcessos style={{ marginBottom: "11px" }} modulo />

                            <Table
                                columns={columns}
                                notEditFirstColum={true}
                                data={this.state.data}
                                loading={this.state.loading}
                                next={this.state.next}
                                count={this.state.count}
                                previous={this.state.previous}
                                onFetchData={this.getData}
                                onUpdate={(table) => this.setState({ table })}
                            />
                        </TabPanel>

                        {
                            isPai && !isCliente && typeUser != 'normal' ?
                                <TabPanel>
                                    <GerenciarModulos/>
                                </TabPanel>
                            : null
                        }
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default AcessoModulo
