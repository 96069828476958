import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import ModalImage from "../../components/ModalImage"
import Table from '../../components/Table'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'

class ListaMonitorados extends Component {

    state = {
        data: [],
        loading: true,
        modal: false,
        image: null,
        monitoradoId: false,
    }

    getData = async (state) => {
        this.setState({ loading: true })
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/lista-monitorado/?limit=${state?.lineCount}` + state.filter

        if (state.url) {
            url = state.url
        }

        await axios.get(url, config_me).then((res) => {
            let { next, previous, results, count } = res.data

            this.setState({ loading: false, data: results ?? [], next, previous, count })
        }).catch(err => {
            this.setState({ loading: false })
        })
    }

    render() {
        const columns = [{
            Header: 'Foto',
            width: 100,
            Accessor: 'fotos',
            sortable: false,
            Cell: (original) => {
                return (
                    original?.fotos?.length > 0 ?
                        <div
                            onClick={() => this.setState({ modal: true, image: original?.fotos, monitoradoId: original.id })}
                            className='image-monitorado'
                            style={{ backgroundImage: `url(${original?.fotos?.[0]?.foto})` }}
                            title='Ver imagem'
                        >
                        </div>
                        :
                        <Link to={`/monitorado/${original.id}/editar?foto=true`}>
                            <div
                                onClick={() => this.setState({ modal: true, image: original?.fotos })}
                                className='sem-image-monitorado'
                                title='Adicionar foto'
                            >
                                <img src="https://rs485project.s3.amazonaws.com/sem-imagem-10.jpg" />
                            </div>
                        </Link>
                )
            }
        }, {
            Header: 'Nome',
            Accessor: 'nome',
            Cell: (original) => <Link to={`/status/${original.id}`}>{original.nome}</Link>
        }, {
            Header: 'Cliente',
            Accessor: 'cliente.nome_fantasia',
        }, {
            Header: 'Tipo',
            Accessor: 'tipo.nome',
            Cell: (original) => {
                if(original.tipo) {
                    return original.tipo.nome
                } else {
                    return <div>---</div>
                }
            }
        }, {
            Header: 'Última comunicação',
            Accessor: 'ultima_comunicacao',
            /*sortable: false,*/
            Cell: (original) => {
                if (original.ultima_comunicacao) {
                    let dataInUserTimezone = moment.tz(original?.ultima_comunicacao * 1000, 'UTC')
                    let formatted = dataInUserTimezone.tz(auth.getUserInfo().timezone).format('DD/MM/YYYY HH:mm:ss')

                    return formatted

                } else {
                    return <div>---</div>
                }
            }
        }]

        return (
            <>
                <div className="hdv-render-div">
                    <ModalImage
                        open={this.state.modal}
                        images={this.state.image}
                        toggle={() => this.setState({ modal: !this.state.modal })}
                        id={this.state.monitoradoId}
                    />
                    <div>
                        <div className="hdv-default-header mx-3">
                            <span className="screen-menu-desc">
                                <h4>Monitorados</h4>
                                <div className="gray-background">
                                    <i className="fa fa-wifi fa-2x"></i>
                                </div>
                            </span>
                        </div>
                        <br />
                        <div className="hdv-btn-group" style={{ marginTop: "30px" }}>
                            <span></span>
                        </div>
                        <Table
                            notEditFirstColum={true}
                            data={this.state.data}
                            loading={this.state.loading}
                            count={this.state.count}
                            next={this.state.next}
                            previous={this.state.previous}
                            columns={columns}
                            onFetchData={this.getData}
                            onUpdate={(table) => this.setState({ table })}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default ListaMonitorados